import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function Footer() {
  const { t } = useTranslation('translation');

  return (
    <FooterLayout>
      <FooterGridBox>
        <>
          <Copyrights>
            <ImageView
              src="https://publ-upload-prod.s3.ap-northeast-2.amazonaws.com/a92dd9e6-0256-4f7b-86bb-1426d29f8681.png"
              width={60}
            />
            <S_Copy>{t('str_20')}</S_Copy>
          </Copyrights>
        </>
      </FooterGridBox>
    </FooterLayout>
  );
}

const FooterLayout = styled.div`
  background-color: ${({ theme }) => theme.landing_color.black};
  display: grid;
  grid-column-gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-template-columns: repeat(12, [col-start] 1fr);

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const FooterGridBox = styled.div`
  grid-column: 3 col-start / span 8;
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_f};
`;

const Copyrights = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_e};

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xs};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_d};
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

const S_Copy = styled.div`
  color: ${({ theme }) => theme.landing_color.grey600};
  font-size: ${({ theme }) => theme.landing_font_size.sm};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_d};
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xs};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_d};
    white-space: normal;
  }
`;

export default Footer;
