import { useState } from 'react';
import styled from 'styled-components';

import { Icon } from '@common/ui/components';

type TooltipPropTypes = {
  contents: string;
};

function Tooltip({ contents }: TooltipPropTypes) {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  return (
    <TooltipBox>
      <TooltipIconWrapper
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        <Icon iconName="ic_question_mark" size={24} />
      </TooltipIconWrapper>
      <TooltipContentsWrapper isOpen={isTooltipOpen}>{`${contents}`}</TooltipContentsWrapper>
    </TooltipBox>
  );
}

const TooltipBox = styled.div`
  box-sizing: border-box;
  display: inline-block;
  height: 24px;
  position: relative;
  width: 24px;
`;

const TooltipIconWrapper = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const TooltipContentsWrapper = styled.div<{
  isOpen: boolean;
}>`
  background-color: ${({ theme }) => theme.landing_color.white};
  border: ${({ theme }) => `1px solid ${theme.landing_color.grey200}`};
  border-radius: 16px;
  bottom: 20px;
  box-shadow: ${({ theme }) => theme.landing_box_shadow.elevation2};
  box-sizing: border-box;
  color: ${({ theme }) => theme.landing_color.grey900};
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  font-size: ${({ theme }) => theme.landing_font_size.sm};
  font-weight: ${({ theme }) => theme.landing_font_weight.medium};
  left: 0;
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  max-width: 320px;
  overflow-wrap: break-word;
  padding: ${({ theme }) => theme.landing_spacing.spacing_d};
  position: absolute;
  text-align: left;
  white-space: pre-wrap;
  width: max-content;
  word-break: keep-all;
  z-index: 400;
`;

export default Tooltip;
