import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function E00001() {
  return (
    <S_Wrapper>
      <S_ImageWrapper1>
        <ImageView
          src="https://static.publ.site/landing_page/service/section5/img_landing_papp_booking_main_01.png"
          width={300}
        />
      </S_ImageWrapper1>
      <S_ImageWrapper2>
        <ImageView
          src="https://static.publ.site/landing_page/service/section5/img_landing_papp_booking_sub_01.png"
          width={244}
        />
      </S_ImageWrapper2>
      <S_ImageWrapper3>
        <ImageView
          src="https://static.publ.site/landing_page/service/section5/img_landing_papp_booking_sub_02.png"
          width={244}
        />
      </S_ImageWrapper3>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_ImageWrapper1 = styled.div`
  bottom: -68px;
  position: absolute;
  right: calc(50% - 150px);
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    transform: translateX(-30px);
  }

  @media ${device.mobile} {
    transform: translateX(-30px);
  }
`;

const S_ImageWrapper2 = styled.div`
  bottom: 124px;
  opacity: 0;
  position: absolute;
  right: calc(50% - 200px);
  transition: all 0.3s 0.05s ease-in-out;

  ${S_Wrapper}:hover & {
    opacity: 1;
    transform: translateX(20px);
  }

  @media ${device.mobile} {
    right: calc(50% - 160px);
    transform: translateX(20px);
    opacity: 1;
  }
`;

const S_ImageWrapper3 = styled.div`
  bottom: 74px;
  opacity: 0;
  position: absolute;
  right: calc(50% - 230px);
  transition: all 0.2s 0.15s ease-in-out;

  ${S_Wrapper}:hover & {
    opacity: 1;
    transform: translateX(20px);
  }

  @media ${device.mobile} {
    transform: translateX(20px);
    right: calc(50% - 140px);
    opacity: 1;
  }
`;

export default E00001;
