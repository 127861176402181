import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function D00003() {
  return (
    <S_Wrapper>
      <S_ImageWrapper1>
        <ImageView
          src="https://static.publ.site/landing_page/service/section5/img_landing_papp_chat_main_01.png"
          width={356}
        />
      </S_ImageWrapper1>
      <S_ImageWrapper2>
        <ImageView
          src="https://static.publ.site/landing_page/service/section5/img_landing_papp_chat_main_02.png"
          width={265}
        />
      </S_ImageWrapper2>
      <S_ImageWrapper3>
        <ImageView
          src="https://static.publ.site/landing_page/service/section5/img_landing_papp_chat_sub_01.png"
          width={268}
        />
      </S_ImageWrapper3>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_ImageWrapper1 = styled.div`
  bottom: 110px;
  position: absolute;
  right: calc(50% - 178px);
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    transform: translateY(-60px);
  }

  @media ${device.mobile} {
    transform: translateY(-60px);
  }
`;

const S_ImageWrapper2 = styled.div`
  bottom: 24px;
  position: absolute;
  right: 12px;
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    transform: translateY(-60px);
  }

  @media ${device.mobile} {
    transform: translateY(-60px);
  }
`;

const S_ImageWrapper3 = styled.div`
  bottom: -24px;
  opacity: 0;
  position: absolute;
  right: 12px;
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    bottom: 24px;
    opacity: 1;
  }

  @media ${device.mobile} {
    bottom: 24px;
    opacity: 1;
  }
`;

export default D00003;
