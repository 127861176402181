import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function MobileApp() {
  return (
    <S_Wrapper>
      <S_Desktop>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_mobileapp_main_01.png"
            width={376}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_mobileapp_sub_01.png"
            width={262}
          />
        </S_ImageWrapper2>
      </S_Desktop>
      <S_Mobile>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_mobileapp_main_01.png"
            width={300}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_mobileapp_sub_01.png"
            width={220}
          />
        </S_ImageWrapper2>
      </S_Mobile>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_Desktop = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

const S_Mobile = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    display: none;
  }
`;

const S_ImageWrapper1 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: -200px;
    position: absolute;
    right: 0;
    transition: all 0.35s ease-in-out;

    ${S_Wrapper}:hover & {
      transform: translateY(-32px);
    }
  }

  @media ${device.mobile} {
    bottom: -180px;
    left: 40px;
    position: absolute;
  }
`;

const S_ImageWrapper2 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 12px;
    opacity: 0;
    position: absolute;
    right: 54px;
    transition: all 0.4s 0.05s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: translateX(-140px);
    }
  }

  @media ${device.mobile} {
    bottom: 0;
    left: -30px;
    position: absolute;
  }
`;

export default MobileApp;
