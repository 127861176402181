import { useTranslation } from 'react-i18next';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import styled, { css } from 'styled-components';

import { Footer, GuidanceSection } from '@common/ui/components';

import {
  Section1,
  Section10,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
  Section9
} from './sections';

function Service() {
  const { t } = useTranslation('translation');

  return (
    <S_Main>
      <ParallaxProvider>
        <Section1Background>
          <S_ParallaxText1 speed={-20} easing="easeInOut">
            {t('str_21')}
          </S_ParallaxText1>
          <S_ParallaxText1Mobile speed={0} easing="easeInOut">
            {t('str_21')}
          </S_ParallaxText1Mobile>
          <S_Section1Wrapper>
            <Section1 />
          </S_Section1Wrapper>
        </Section1Background>
        <Section10Background>
          <S_Section10Wrapper>
            <Section10 />
          </S_Section10Wrapper>
        </Section10Background>
        <Section9Background>
          <S_ParallaxText9
            speed={0}
            scale={[1, 1.8]}
            startScroll={900}
            endScroll={3000}
            easing="easeInOut"
          >
            {t('str_26')}
          </S_ParallaxText9>
          <S_ParallaxText9Mobile speed={0} easing="easeInOut">
            {t('str_26')}
          </S_ParallaxText9Mobile>
          <S_Section9Wrapper>
            <Section9 />
          </S_Section9Wrapper>
        </Section9Background>
        <Section3Background>
          <S_ParallaxText3 speed={-20} easing="easeInOut">
            {t('str_30')}
          </S_ParallaxText3>
          <S_ParallaxText3Mobile speed={0} easing="easeInOut">
            {t('str_30')}
          </S_ParallaxText3Mobile>
          <S_Section3Wrapper>
            <Section3 />
          </S_Section3Wrapper>
        </Section3Background>
        <Section4Background>
          <S_ParallaxText4 speed={-20} easing="easeInOut">
            {t('str_39')}
          </S_ParallaxText4>
          <S_ParallaxText4Mobile speed={0} easing="easeInOut">
            {t('str_39')}
          </S_ParallaxText4Mobile>
          <S_Section4Wrapper>
            <Section4 />
          </S_Section4Wrapper>
        </Section4Background>
        <Section5Background>
          <S_ParallaxText5 speed={-20} easing="easeInOut">
            {t('str_43')}
          </S_ParallaxText5>
          <S_ParallaxText5Mobile speed={0} easing="easeInOut">
            {t('str_43')}
          </S_ParallaxText5Mobile>
          <S_Section5Wrapper>
            <Section5 />
          </S_Section5Wrapper>
        </Section5Background>
        <Section6Background>
          <S_ParallaxText6 speed={-20} easing="easeInOut">
            {t('str_70')}
          </S_ParallaxText6>
          <S_ParallaxText6Mobile speed={-20} easing="easeInOut">
            {t('str_70')}
          </S_ParallaxText6Mobile>
          <S_Section6Wrapper>
            <Section6 />
          </S_Section6Wrapper>
        </Section6Background>
        <Section7Background>
          <S_Section7Wrapper>
            <Section7 />
          </S_Section7Wrapper>
        </Section7Background>
        <Section8Background>
          <S_Section8Wrapper>
            <Section8 />
          </S_Section8Wrapper>
        </Section8Background>
        <GuidanceSection />
      </ParallaxProvider>
      <Footer />
    </S_Main>
  );
}

const backgroundStyle = css`
  align-items: center;
  background-color: ${({ theme }) => theme.landing_color.white};
  display: flex;
  flex-direction: column;
  position: relative;

  /* mobile */
  @media (max-width: 767px) {
    overflow: hidden;
    clip-path: inset(0 0 0 0);
  }
`;

const wrapperStyle = css`
  max-width: 1440px;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_k};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: 200px;
  position: relative;
  width: 100%;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_l};
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_l};
  }

  /* mobile */
  @media (max-width: 767px) {
    padding-bottom: 56px;
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-top: 56px;
  }
`;

const S_Main = styled.div`
  background-color: ${({ theme }) => theme.landing_color.white};
`;

const Section1Background = styled.div`
  ${backgroundStyle}

  background: #004cef;
  background: linear-gradient(135deg, #004cef 0%, #6599ff 100%);

  /* mobile */
  @media (max-width: 767px) {
    overflow: visible;
    height: 100vh;
    height: fill-available;
    justify-content: center;
  }
`;

const S_Section1Wrapper = styled.div`
  ${wrapperStyle}

  padding-bottom: 0;
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: 0;
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_j};
  }
`;

const Section3Background = styled.div`
  ${backgroundStyle}

  background: ${({ theme }) => theme.landing_color.black};
`;

const S_Section3Wrapper = styled.div`
  ${wrapperStyle}

  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_n};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_m};
`;

const Section4Background = styled.div`
  ${backgroundStyle}

  background: #53f8c3;
  background: linear-gradient(135deg, #53f8c3 0%, #21fffb 100%);
`;

const S_Section4Wrapper = styled.div`
  ${wrapperStyle}
`;

const Section5Background = styled.div`
  ${backgroundStyle}

  background-color: ${({ theme }) => theme.landing_color.grey10};
`;

const S_Section5Wrapper = styled.div`
  ${wrapperStyle}

  align-items: center;
  display: flex;
  justify-content: center;
`;

const Section6Background = styled.div`
  ${backgroundStyle}
`;

const S_Section6Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Section7Background = styled.div`
  ${backgroundStyle}

  background: #eff0f4;
`;

const S_Section7Wrapper = styled.div`
  ${wrapperStyle}

  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Section8Background = styled.div`
  ${backgroundStyle}

  background-color: ${({ theme }) => theme.landing_color.grey10};
`;

const S_Section8Wrapper = styled.div`
  ${wrapperStyle}

  align-items: center;
  display: flex;
  justify-content: center;
  max-width: unset;
  padding-left: unset;
  padding-right: unset;
  width: 100%;

  /* mobile + tablet */
  @media (max-width: 1023px) {
    padding-left: unset;
    padding-right: unset;
  }
`;

const Section9Background = styled.div`
  ${backgroundStyle}

  background-color: ${({ theme }) => theme.landing_color.grey10};
  clip-path: inset(0 0 0 0);
  overflow: hidden;
`;

const S_Section9Wrapper = styled.div`
  ${wrapperStyle}

  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_m};

  /* mobile */
  @media (max-width: 767px) {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  }
`;

const Section10Background = styled.div`
  ${backgroundStyle}

  background-color: ${({ theme }) => theme.landing_color.grey30};
`;

const S_Section10Wrapper = styled.div`
  ${wrapperStyle}

  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};
`;

const userSelectModeStyle = css`
  user-select: none;
`;

const parallaxTextStyle = css`
  display: flex;
  font-size: 260px;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 30%;
  white-space: nowrap;

  ${userSelectModeStyle}

  /* mobile */
    @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl11};
    position: fixed;
    display: none;
    width: 100%;
  }
`;

const MobileBackgroundTextStyle = css`
  display: flex;
  top: 250px;
`;

const S_ParallaxText1 = styled(Parallax)`
  ${parallaxTextStyle}

  color: #004def;
  opacity: 0.5;
`;

const S_ParallaxText1Mobile = styled(S_ParallaxText1)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}

    font-size: 5rem;
  }
`;

const S_ParallaxText9 = styled(Parallax)`
  ${parallaxTextStyle}

  color: #dcdcdc;
  opacity: 0.5;
  position: fixed;
`;

const S_ParallaxText9Mobile = styled(S_ParallaxText9)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}

    font-size: 7rem;
  }
`;

const S_ParallaxText3 = styled(Parallax)`
  ${parallaxTextStyle}

  color: #1c1c1c;
`;

const S_ParallaxText3Mobile = styled(S_ParallaxText3)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}

    font-size: 6.1rem;
  }
`;

const S_ParallaxText4 = styled(Parallax)`
  ${parallaxTextStyle}

  color: #000;
  opacity: 0.07;
`;

const S_ParallaxText4Mobile = styled(S_ParallaxText4)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}
  }
`;

const S_ParallaxText5 = styled(Parallax)`
  ${parallaxTextStyle}

  color: #000;
  opacity: 0.04;
  top: 200px;
`;

const S_ParallaxText5Mobile = styled(S_ParallaxText5)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}

    font-size: ${({ theme }) => theme.landing_font_size.xl10};
  }
`;

const S_ParallaxText6 = styled(Parallax)`
  ${parallaxTextStyle}

  color: #fff;
  opacity: 0.08;
  top: 200px;
`;

const S_ParallaxText6Mobile = styled(S_ParallaxText6)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}

    position: absolute;
    font-size: 4.75rem;
  }
`;

export default Service;
