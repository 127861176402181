import styled from 'styled-components';

import { Icon } from '@common/ui/components';

import Tooltip from './Tooltip';

type TableBodyRowItemPropTypes = {
  title: string;
  description: string;
  tip: string;
  superscript?: number;
  columns: (string | boolean)[];
};

function TableBodyRowItem({
  title,
  description,
  tip,
  superscript,
  columns
}: TableBodyRowItemPropTypes) {
  const columnStartBase = [4, 6, 8];

  return (
    <TableBodyRowItemWrapper>
      <RowGrid>
        <FeatureTitleCol>
          <FeatureTitleBox>
            <Title>
              {title}
              {superscript && <sup>{superscript}</sup>}
            </Title>
            {tip && (
              <TooltipWrapper>
                <Tooltip contents={tip} />
              </TooltipWrapper>
            )}
          </FeatureTitleBox>
          {description && <FeatureDescription>{description}</FeatureDescription>}
        </FeatureTitleCol>
        {columns.map((column, index) => (
          <FeatureContentBox key={index} columnStart={columnStartBase[index]}>
            <Content>
              {typeof column === 'string' ? column : <Icon iconName="ic_circle_check" size={40} />}
            </Content>
          </FeatureContentBox>
        ))}
      </RowGrid>
    </TableBodyRowItemWrapper>
  );
}

const TableBodyRowItemWrapper = styled.li`
  height: 110px;
  margin: 0;
  padding: 0;
`;

const RowGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, [col-start] 1fr);
  height: 100%;
`;

const FeatureTitleCol = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 col-start / span 3;
  justify-content: center;
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_f};
`;

const FeatureContentBox = styled.div<{
  columnStart: number;
}>`
  align-items: center;
  display: flex;
  grid-column: ${({ columnStart }) => columnStart} col-start / span 2;
  justify-content: center;
`;

const FeatureTitleBox = styled.p`
  white-space: pre-wrap;
  word-break: keep-all;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.landing_font_size.xl};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  line-height: ${({ theme }) => theme.landing_line_height.normal};

  sup {
    font-size: ${({ theme }) => theme.landing_font_size.xs};
  }
`;

const TooltipWrapper = styled.span`
  display: inline-flex;
  height: 29px;
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_a};
  vertical-align: middle;
`;

const FeatureDescription = styled.div`
  color: ${({ theme }) => theme.landing_color.grey500};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_c};
`;

const Content = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.landing_color.grey500};
  display: flex;
  font-size: ${({ theme }) => theme.landing_font_size.xl};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;
`;

export default TableBodyRowItem;
