import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function C00012() {
  return (
    <S_Wrapper>
      <S_Desktop>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_videocall_main_01.png"
            width={480}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_videocall_sub_01.png"
            width={260}
          />
        </S_ImageWrapper2>
        <S_ImageWrapper3>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_videocall_sub_02.png"
            width={276}
          />
        </S_ImageWrapper3>
      </S_Desktop>
      <S_Mobile>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_videocall_main_01.png"
            width={400}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_videocall_sub_01.png"
            width={203}
          />
        </S_ImageWrapper2>
        <S_ImageWrapper3>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_videocall_sub_02.png"
            width={218}
          />
        </S_ImageWrapper3>
      </S_Mobile>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_Desktop = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

const S_Mobile = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    display: none;
  }
`;

const S_ImageWrapper1 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: -70px;
    left: 24px;
    position: absolute;
    transition: all 0.35s ease-in-out;

    ${S_Wrapper}:hover & {
      transform: translate(20px, -10px);
    }
  }

  @media ${device.mobile} {
    bottom: -5px;
    left: -110px;
    position: absolute;
  }
`;

const S_ImageWrapper2 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 120px;
    opacity: 0;
    position: absolute;
    right: 24px;
    transition: all 0.3s 0.05s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: translateY(-60px);
    }
  }

  @media ${device.mobile} {
    bottom: 200px;
    left: 140px;
    position: absolute;
  }
`;

const S_ImageWrapper3 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 40px;
    opacity: 0;
    position: absolute;
    right: 72px;
    transition: all 0.2s 0.15s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: translateY(-60px);
    }
  }

  @media ${device.mobile} {
    bottom: 140px;
    left: 123px;
    position: absolute;
  }
`;

export default C00012;
