import styled, { css } from 'styled-components';

import type { BorderTheme, ColorTheme, EffectTheme } from '../../../types';
import type { CSSProperties } from 'react';

type PaddingSpacingType = 'none' | keyof typeof paddingSpacing;
type ColorType = keyof ColorTheme['landing_color'];
type BorderRadiusType = keyof BorderTheme['landing_border_radius'];
type BoxShadowType = keyof EffectTheme['landing_box_shadow'];

export type GridItemStyleType = {
  gridColumnStart?: string;
  gridColumnEnd?: string;
};

export type SheetProps = {
  width?: string;
  height?: string;
  backgroundColorTheme?: ColorType;
  boxShadow?: 'none' | BoxShadowType;
  overrideBackgroundColorKey?: `#${string}`;
  borderMode?: 'none' | 'use';
  borderWidth?: 1 | 2;
  borderColorTheme?: ColorType;
  overrideBorderColorKey?: `#${string}`;
  shapeType?: 'rectangle' | 'round';
  radius?: BorderRadiusType;
  paddingTop?: PaddingSpacingType;
  paddingRight?: PaddingSpacingType;
  paddingBottom?: PaddingSpacingType;
  paddingLeft?: PaddingSpacingType;
  gridItemStyle?: GridItemStyleType;
  overrideCSS?: CSSProperties;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export type StyleProps = {
  $width?: string;
  $height?: string;
  $backgroundColorTheme?: ColorType;
  $boxShadow?: 'none' | BoxShadowType;
  overrideBackgroundColorKey?: `#${string}`;
  borderMode?: 'none' | 'use';
  $borderWidth?: 1 | 2;
  $borderColorTheme?: ColorType;
  overrideBorderColorKey?: `#${string}`;
  shapeType?: 'rectangle' | 'round';
  $radius?: BorderRadiusType;
  $paddingTop?: PaddingSpacingType;
  $paddingRight?: PaddingSpacingType;
  $paddingBottom?: PaddingSpacingType;
  $paddingLeft?: PaddingSpacingType;
  gridItemColumnStart?: GridItemStyleType['gridColumnStart'];
  gridItemColumnEnd?: GridItemStyleType['gridColumnEnd'];
  hasOnClick?: boolean;
};

const paddingSpacing = {
  spacing_a: 'spacing_a',
  spacing_b: 'spacing_b',
  spacing_c: 'spacing_c',
  spacing_d: 'spacing_d',
  spacing_e: 'spacing_e',
  spacing_f: 'spacing_f',
  spacing_g: 'spacing_g',
  spacing_h: 'spacing_h',
  spacing_i: 'spacing_i',
  spacing_j: 'spacing_j',
  spacing_k: 'spacing_k',
  spacing_l: 'spacing_l',
  spacing_m: 'spacing_m',
  spacing_n: 'spacing_n'
} as const;

function Sheet({
  width,
  height,
  backgroundColorTheme = 'grey30',
  overrideBackgroundColorKey,
  boxShadow = 'none',
  borderMode = 'none',
  borderWidth = 1,
  borderColorTheme = 'grey100',
  overrideBorderColorKey,
  shapeType = 'round',
  radius = 'xl3',
  paddingTop = 'spacing_e',
  paddingRight = 'spacing_e',
  paddingBottom = 'spacing_e',
  paddingLeft = 'spacing_e',
  gridItemStyle,
  overrideCSS,
  children,
  onClick
}: SheetProps) {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  function SheetVariation() {
    return (
      <S_Sheet
        $width={width}
        $height={height}
        $backgroundColorTheme={backgroundColorTheme}
        $boxShadow={boxShadow}
        overrideBackgroundColorKey={overrideBackgroundColorKey}
        borderMode={borderMode}
        $borderWidth={borderWidth}
        $borderColorTheme={borderColorTheme}
        overrideBorderColorKey={overrideBorderColorKey}
        shapeType={shapeType}
        $radius={radius}
        $paddingTop={paddingTop}
        $paddingRight={paddingRight}
        $paddingBottom={paddingBottom}
        $paddingLeft={paddingLeft}
        gridItemColumnStart={gridItemStyle && gridItemStyle.gridColumnStart}
        gridItemColumnEnd={gridItemStyle && gridItemStyle.gridColumnEnd}
        style={overrideCSS}
        onClick={handleClick}
        hasOnClick={!!onClick}
      >
        {children}
      </S_Sheet>
    );
  }
  return SheetVariation();
}

const radiusStyle = css<StyleProps>`
  border-radius: ${({ theme, $radius }) => $radius && theme.landing_border_radius[$radius]};
`;

const paddingTopStyle = css<StyleProps>`
  padding-top: ${({ theme, $paddingTop }) =>
    $paddingTop &&
    ($paddingTop === 'none' ? 0 : theme.landing_spacing[paddingSpacing[$paddingTop]])};
`;

const paddingRightStyle = css<StyleProps>`
  padding-right: ${({ theme, $paddingRight }) =>
    $paddingRight &&
    ($paddingRight === 'none' ? 0 : theme.landing_spacing[paddingSpacing[$paddingRight]])};
`;

const paddingBottomStyle = css<StyleProps>`
  padding-bottom: ${({ theme, $paddingBottom }) =>
    $paddingBottom &&
    ($paddingBottom === 'none' ? 0 : theme.landing_spacing[paddingSpacing[$paddingBottom]])};
`;

const paddingLeftStyle = css<StyleProps>`
  padding-left: ${({ theme, $paddingLeft }) =>
    $paddingLeft &&
    ($paddingLeft === 'none' ? 0 : theme.landing_spacing[paddingSpacing[$paddingLeft]])};
`;

const boxShadowStyle = css<StyleProps>`
  box-shadow: ${({ theme, $boxShadow }) =>
    $boxShadow && $boxShadow !== 'none' && theme.landing_box_shadow[$boxShadow]};
`;

const gridStyle = css<StyleProps>`
  grid-column-end: ${({ gridItemColumnEnd }) => gridItemColumnEnd};
  grid-column-start: ${({ gridItemColumnStart }) => gridItemColumnStart};
`;

const overrideStyle = css<StyleProps>`
  background-color: ${({ overrideBackgroundColorKey }) =>
    overrideBackgroundColorKey && overrideBackgroundColorKey};
  border-color: ${({ overrideBorderColorKey }) => overrideBorderColorKey && overrideBorderColorKey};
`;

const S_Sheet = styled.div<StyleProps>`
  background-color: ${({ theme, $backgroundColorTheme }) =>
    $backgroundColorTheme && theme.landing_color[$backgroundColorTheme]};
  border-color: ${({ theme, $borderColorTheme }) =>
    $borderColorTheme && theme.landing_color[$borderColorTheme]};
  border-style: ${({ borderMode }) => (borderMode === 'use' ? 'solid' : 'none')};
  border-width: ${({ $borderWidth }) => `${$borderWidth}px`};
  box-sizing: border-box;
  cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : 'default')};
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};

  ${({ shapeType }) => shapeType === 'round' && radiusStyle};

  ${({ $paddingTop }) => $paddingTop !== 'none' && paddingTopStyle};
  ${({ $paddingRight }) => $paddingRight !== 'none' && paddingRightStyle};
  ${({ $paddingBottom }) => $paddingBottom !== 'none' && paddingBottomStyle};
  ${({ $paddingLeft }) => $paddingLeft !== 'none' && paddingLeftStyle};

  ${({ $boxShadow }) => $boxShadow !== 'none' && boxShadowStyle};

  ${gridStyle}
  ${overrideStyle}
`;

export default Sheet;
