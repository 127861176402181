import { createGlobalStyle } from 'styled-components';

import '@common/assets/fonts/pretendard-jp.css';

const GlobalStyle = createGlobalStyle`
html {
  height: 100%;
  overflow-y: auto;
  overscroll-behavior-y: none;
  scroll-padding-top: 90px;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 14px;
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: 12px;
    scroll-padding-top: 54px;
  }
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: "Pretendard JP", -apple-system, BlinkMacSystemFont, "Basier Square", "Noto Sans KR", "Segoe UI", "Apple SD Gothic Neo", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

div,
span,
strong,
textarea,
button {
  font-family: "Pretendard JP", -apple-system, BlinkMacSystemFont, "Basier Square", "Noto Sans KR", "Segoe UI", "Apple SD Gothic Neo", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.landing_font_weight.medium};
  letter-spacing: -0.03em;
  line-height: 1.5;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
}

span {
  display: inline;
  line-height: 1;
}

div::-webkit-scrollbar,
span::-webkit-scrollbar,
textarea::-webkit-scrollbar,
ul::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  overflow-wrap: break-word;
  user-select: none;
  white-space: pre-wrap;
  word-break: keep-all;

  /* mobile */
  @media (max-width: 767px) {
     white-space: normal !important;
  }
}

h1 {
  font-size: ${({ theme }) => theme.landing_font_size.xl10};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  line-height: ${({ theme }) => theme.landing_line_height.tight};
}

h2 {
  font-size: ${({ theme }) => theme.landing_font_size.xl9};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  line-height: ${({ theme }) => theme.landing_line_height.tight};
}

h3 {
  font-size: ${({ theme }) => theme.landing_font_size.xl4};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
}

h4 {
  font-size: ${({ theme }) => theme.landing_font_size.xl3};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
}

p {
  font-size: ${({ theme }) => theme.landing_font_size.xl};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
}


strong {
  display: inline;
  line-height: 1;
}

input {
  font-family: "Pretendard JP", -apple-system, BlinkMacSystemFont, "Basier Square", "Noto Sans KR", "Segoe UI", "Apple SD Gothic Neo", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.landing_font_weight.medium};
  letter-spacing: -0.03em;
}

a,
[type='checkbox'],
[type='radio'] {
  color: inherit;
  -webkit-tap-highlight-color: #1a1a1a4d;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  cursor: pointer;
  font-family: inherit;
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: ${({ theme }) => theme.landing_color.grey900} !important;
  transition: background-color 9999s ease-in-out 0s !important;
}

`;

export default GlobalStyle;
