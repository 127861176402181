import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-scroll-parallax';
import styled, { css } from 'styled-components';

import { device } from '@common/ui/styles/media';

type Props = {
  isSeparate?: boolean;
};

function Section6_2({ isSeparate }: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_Background isSeparate={isSeparate}>
      {!isSeparate && (
        <>
          <S_ParallaxText speed={-20} easing="easeInOut">
            {t('str_69')}
          </S_ParallaxText>
          <S_ParallaxTextMobile speed={0} easing="easeInOut">
            {t('str_69')}
          </S_ParallaxTextMobile>
        </>
      )}
      <S_Wrapper>
        <S_Caption>{t('str_73')}</S_Caption>
        <S_ContentBox>
          <S_Image1Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_individual_sales_main_02.png"
              width="responsive"
            />
          </S_Image1Wrapper>
          <S_Image2Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_individual_sales_main_04.png"
              width="responsive"
            />
          </S_Image2Wrapper>
          <S_Image3_1Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_individual_sales_main_01.png"
              width="responsive"
            />
          </S_Image3_1Wrapper>
          <S_Image3Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_individual_sales_main_01_1.png"
              width="responsive"
            />
          </S_Image3Wrapper>
          <S_Image4Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_individual_sales_main_03.png"
              width="responsive"
            />
          </S_Image4Wrapper>
          <S_Image5Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_individual_sales_sub_01.png"
              width="responsive"
            />
          </S_Image5Wrapper>
          <S_MobileImageWrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_individual_sales_main_mobile.png"
              width="responsive"
            />
          </S_MobileImageWrapper>
        </S_ContentBox>
        <S_TextBox>
          <S_Title>{t('str_74')}</S_Title>
          <S_Description>{t('str_75')}</S_Description>
        </S_TextBox>
      </S_Wrapper>
    </S_Background>
  );
}

const parallaxTextStyle = css`
  display: flex;
  font-size: 260px;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 30%;
  user-select: none;
  white-space: nowrap;

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl11};
    position: fixed;
    display: none;
    width: 100%;
  }
`;

const MobileBackgroundTextStyle = css`
  display: flex;
  font-size: 6.1rem;
  top: 250px;
`;

const S_ParallaxText = styled(Parallax)`
  ${parallaxTextStyle}

  color: #000;
  opacity: 0.05;
`;

const S_ParallaxTextMobile = styled(S_ParallaxText)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}
  }
`;

const BackgroundColorStyle = css`
  background: #ff407d;
`;

const S_Background = styled.div<Props>`
  align-items: center;
  clip-path: inset(0 0 0 0);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  padding: ${({ theme }) => theme.landing_spacing.spacing_j};
  position: relative;

  @media ${device.tablet} {
    height: unset;
    min-height: unset;
  }

  @media ${device.mobile} {
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_h};
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }

  ${({ isSeparate }) => !isSeparate && BackgroundColorStyle};
`;

const S_Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 1440px;
  position: relative;
  width: 100%;

  @media ${device.mobile} {
    max-width: 767px;
  }
`;

const imageStyle = css`
  border-radius: 32px;
  box-shadow: 0 60px 70px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: absolute;
  transition: all 0.35s ease-in-out;

  @media ${device.mobile} {
    display: none;
  }
`;

const S_Image1Wrapper = styled.div`
  ${imageStyle}

  bottom: calc(50% - 200px / 2 - 40px);
  left: calc(50% - 266px - 94px);
  width: 266px;
`;

const S_Image2Wrapper = styled.div`
  ${imageStyle}

  bottom: calc(50% - 200px - 100px);
  left: calc(50% - 266px + 50px);
  width: 266px;
`;

const S_Image3Wrapper = styled.div`
  ${imageStyle}

  left: calc(50% - 372px / 2 - 50px);
  top: calc(50% - 200px - 100px);
  width: 372px;
`;

const S_Image3_1Wrapper = styled.div`
  ${imageStyle}

  left: calc(50% - 372px / 2 - 50px);
  opacity: 0.3;
  top: calc(50% - 200px - 100px);
  width: 372px;
`;

const S_Image4Wrapper = styled.div`
  ${imageStyle}

  right: calc(50% - 250px - 80px);
  top: calc(50% - 190px + 20px);
  width: 250px;
`;

const S_Image5Wrapper = styled.div`
  ${imageStyle}

  right: calc(50% - 160px - 30px);
  top: calc(50% - 50px - 280px);
  width: 160px;
`;

const S_MobileImageWrapper = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    display: none;
  }
`;

const S_ContentBox = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &:hover ${S_Image1Wrapper} {
    transform: translate(-140px, 20px);
  }
  &:hover ${S_Image2Wrapper} {
    transform: translate(10px, 80px);
  }
  &:hover ${S_Image3Wrapper} {
    transform: scale(140%) translate(0, 60px);
    opacity: 0;
  }
  &:hover ${S_Image3_1Wrapper} {
    transform: scale(140%) translate(0, 60px);
    opacity: 1;
  }
  &:hover ${S_Image4Wrapper} {
    transform: translate(100px, -10px);
  }
  &:hover ${S_Image5Wrapper} {
    transform: rotate(10deg) translate(50px, -50px);
  }

  @media ${device.tablet} {
    position: relative;
    width: 100%;
    height: 600px;
    margin-top: ${({ theme }) => theme.landing_spacing.spacing_h};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  }

  @media ${device.mobile} {
    height: auto;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const S_Caption = styled.p`
  color: rgb(227, 49, 107);
  font-size: ${({ theme }) => theme.landing_font_size.xl8};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  @media ${device.mobile} {
    display: none;
  }
`;

const S_TextBox = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  position: relative;

  @media ${device.tablet} {
    color: ${({ theme }) => theme.landing_color.white};
    align-self: flex-start;
  }

  @media ${device.mobile} {
    max-width: 75%;
  }
`;

const S_Title = styled.h2`
  font-size: ${({ theme }) => theme.landing_font_size.xl10};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl8};
    white-space: pre-wrap !important;
  }
`;

const S_Description = styled.p`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
`;

export default Section6_2;
