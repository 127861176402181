import { PLANS } from '../types';

export const plans = [
  {
    type: PLANS.AGENCY_ESSENTIAL,
    fee: {
      monthly: 'str_186',
      annual: 'str_187'
    },
    buttonText: 'str_191',
    color: '#ffe941',
    description: 'str_189',
    contents: '',
    tags: [],
    caption: 'str_190'
  },
  {
    type: PLANS.AGENCY_BUSINESS,
    fee: {
      monthly: 'str_193',
      annual: 'str_194'
    },
    buttonText: 'str_198',
    color: '#a4ff41',
    description: 'str_196',
    contents: '',
    tags: [],
    caption: 'str_197'
  },
  {
    type: PLANS.ACADEMY,
    fee: {
      monthly: 'str_200',
      annual: 'str_201'
    },
    buttonText: 'str_204',
    color: '#60ffce',
    description: 'str_202',
    contents: '',
    tags: [],
    caption: 'str_205'
  }
];
