import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, MainButton } from '@common/ui/components';
import { device } from '@common/ui/styles/media';

import { plan } from './plan';

function EnterprisePlanSection() {
  const { t } = useTranslation('translation');

  return (
    <EnterPrisePlanSectionLayout>
      <SectionTitle>{t('str_206')}</SectionTitle>
      <CardBox>
        <InfoBox>
          <Tagline>{t(plan.type)}</Tagline>
          <MainTextWrapper>
            <MainText>{t(plan.fee.monthly)}</MainText>
            <MainSubText>{t('str_210')}</MainSubText>
          </MainTextWrapper>
          <Description>{t(plan.description)}</Description>
          <S_CheckList>
            {plan.checkList.map((data, index) => (
              <S_CheckListItem key={index}>
                <S_Icon iconName="ic_check" colorKey="black" size={28} /> {t(data)}
              </S_CheckListItem>
            ))}
          </S_CheckList>
          <BottomContentsBox>
            <ButtonWrapper>
              <MainButton
                size="large"
                responsiveMode="use"
                backgroundColorKey="black"
                href="mailto:contact@publ.biz"
              >
                {t(plan.buttonText)}
              </MainButton>
            </ButtonWrapper>
          </BottomContentsBox>
        </InfoBox>
        <ImageWrapper>
          <Image src="https://static.publ.site/landing_page/pricing/img_landing_pricing_enterprise.png" />
        </ImageWrapper>
      </CardBox>
    </EnterPrisePlanSectionLayout>
  );
}

const EnterPrisePlanSectionLayout = styled.div`
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_k};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};
  width: 100%;

  @media ${device.mobile} {
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_j};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

const SectionTitle = styled.div`
  box-sizing: border-box;
  color: ${({ theme }) => theme.landing_color.white};
  font-size: 3.5rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
  word-break: keep-all;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl6};
  }
`;

const CardBox = styled.div`
  background-image: linear-gradient(118deg, #a8daff, #d381ff 100%);
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: hidden;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_i};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_f};

  @media ${device.mobile} {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

const InfoBox = styled.div`
  flex: 1;
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};

  @media ${device.mobile} {
    padding-bottom: 0;
    padding-right: 0;
  }
`;

const Tagline = styled.span`
  display: block;
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};

  @media ${device.mobile} {
    text-align: center;
  }
`;

const MainTextWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.landing_spacing.spacing_a};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};

  @media ${device.mobile} {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
`;

const MainText = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.landing_font_size.xl7};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  white-space: nowrap;

  @media ${device.mobile} {
    text-align: center;
  }
`;

const MainSubText = styled.span`
  font-size: ${({ theme }) => theme.landing_font_size.xl5};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  white-space: nowrap;
  word-break: keep-all;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.landing_font_size.xl};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-break: keep-all;
`;

const S_CheckList = styled.ul`
  align-self: flex-start;
  display: grid;
  grid-row-gap: ${({ theme }) => theme.landing_spacing.spacing_c};
  list-style: none;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-left: 0;
  text-align: left;
`;

const S_CheckListItem = styled.li`
  align-items: center;
  color: ${({ theme }) => theme.landing_color.black};
  display: flex;
  font-size: ${({ theme }) => theme.landing_font_size.xl};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl2};
  }
`;

const S_Icon = styled(Icon)`
  margin-right: ${({ theme }) => theme.landing_spacing.spacing_b};
  min-width: fit-content;
  padding-bottom: 2px;
`;

const BottomContentsBox = styled.div`
  @media ${device.mobile} {
    display: flex;
    justify-content: center;
  }
`;

const ButtonWrapper = styled.div`
  flex: 1;
  max-width: 270px;

  @media ${device.mobile} {
    max-width: unset;
  }
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  max-width: 570px;
  min-width: 380px;

  @media ${device.mobile} {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;

  @media ${device.mobile} {
    display: none;
  }
`;

export default EnterprisePlanSection;
