import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

import { BlockSheet } from './components';

function Section7() {
  const { t } = useTranslation('translation');

  return (
    <S_ContentBox>
      <S_Title>
        {t('str_85')}
        <S_TextEmphasis>{t('str_86')}</S_TextEmphasis>
        {t('str_87')}
      </S_Title>
      <S_Grid>
        <BlockSheet
          gridColumnStart="auto"
          gridColumnEnd="span 7"
          borderMode="use"
          overrideBackgroundColor="#FF516C"
        >
          <S_Sheet1ContentBox>
            <S_Sheet1Title>{t('str_88')}</S_Sheet1Title>
          </S_Sheet1ContentBox>
        </BlockSheet>
        <BlockSheet
          textColorTheme="grey900"
          gridColumnStart="auto"
          gridColumnEnd="span 5"
          borderMode="use"
          overrideBackgroundColor="#FF8354"
        >
          <S_Sheet2ContentBox>
            <S_SheetTitle>{t('str_89')}</S_SheetTitle>
            <S_Sheet2Movement>
              <ImageView
                src="https://static.publ.site/landing_page/service/section7/ic_landing_payment_regularly.png"
                width="responsive"
              />
              <ImageView
                src="https://static.publ.site/landing_page/service/section7/ic_landing_payment_regularly_m.png"
                width="responsive"
              />
            </S_Sheet2Movement>
          </S_Sheet2ContentBox>
        </BlockSheet>
        <BlockSheet
          gridColumnStart="auto"
          gridColumnEnd="span 5"
          borderMode="use"
          overrideBackgroundColor="#566FFF"
        >
          <S_Sheet3ContentBox>
            <S_SheetTitle>{t('str_90')}</S_SheetTitle>
            <S_Sheet3Movement>
              <S_line />
              <S_line />
              <S_line />
              <S_line />
            </S_Sheet3Movement>
          </S_Sheet3ContentBox>
        </BlockSheet>
        <BlockSheet
          textColorTheme="grey900"
          gridColumnStart="auto"
          gridColumnEnd="span 7"
          borderMode="use"
          overrideBackgroundColor="#46D6AD"
        >
          <S_Sheet4ContentBox>
            <S_SheetTitle>{t('str_91')}</S_SheetTitle>
            <S_Sheet4Movement>
              <ImageView
                src="https://static.publ.site/landing_page/service/section7/ic_landing_payment_coupon.png"
                width="responsive"
              />
              <ImageView
                src="https://static.publ.site/landing_page/service/section7/ic_landing_payment_coupon_m.png"
                width="responsive"
              />
            </S_Sheet4Movement>
          </S_Sheet4ContentBox>
        </BlockSheet>
      </S_Grid>
    </S_ContentBox>
  );
}

const S_ContentBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const S_Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
  text-align: center;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

const S_TextEmphasis = styled.strong`
  color: #3351ff;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-180deg);

  }
`;

const sheetContentBoxStyle = css`
  align-items: center;
  color: ${({ theme }) => theme.landing_color.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
`;

const S_Sheet1ContentBox = styled.div`
  ${sheetContentBoxStyle}
`;

const S_Sheet2ContentBox = styled.div`
  ${sheetContentBoxStyle}

  & div {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    animation: ${rotate} 1.5s infinite ease-in-out;
  }

  &:hover div {
    opacity: 1;
  }
`;

const S_Sheet3ContentBox = styled.div`
  ${sheetContentBoxStyle}

  & div {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &:hover div {
    opacity: 1;
  }
`;

const S_Sheet4ContentBox = styled.div`
  ${sheetContentBoxStyle}

  & div {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover div {
    opacity: 1;
    transform: scale(105%);
  }
`;

const S_Sheet2Movement = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 70%;

  & div:nth-of-type(2) {
    display: none;
  }

  /* mobile */
  @media (max-width: 767px) {
    width: 200px;

    & div:nth-of-type(1) {
      display: none;
    }

    & div:nth-of-type(2) {
      display: unset;
    }
  }
`;

const fade1 = keyframes`
  from  {
    opacity: 0;
  }

  25%  {
    opacity: 1;
  }
  
  50%  {
    opacity: 1;
  }

  75%  {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
`;

const fade2 = keyframes`
  from  {
    opacity: 0;
  }

  25%  {
    opacity: 0;
  }
  
  50%  {
    opacity: 1;
  }

  75%  {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
`;

const fade3 = keyframes`
  from  {
    opacity: 0;
  }

  25%  {
    opacity: 0;
  }
  
  50%  {
    opacity: 0;
  }

  75%  {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
`;

const fade4 = keyframes`
  from  {
    opacity: 0;
  }

  25%  {
    opacity: 0;
  }
  
  50%  {
    opacity: 0;
  }

  75%  {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const S_Sheet3Movement = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 50%;

  & div:nth-child(1) {
    animation: ${fade1} 1.5s infinite ease-in-out alternate;
    transform: rotate(90deg);
  }

  & div:nth-child(2) {
    animation: ${fade2} 1.5s infinite ease-in-out alternate;
    transform: rotate(45deg);
  }

  & div:nth-child(3) {
    animation: ${fade3} 1.5s infinite ease-in-out alternate;
    transform: rotate(0deg);
  }

  & div:nth-child(4) {
    animation: ${fade4} 1.5s infinite ease-in-out alternate;
    transform: rotate(-45deg);
  }
`;

const S_line = styled.div`
  background-color: ${({ theme }) => theme.landing_color.white};
  border-radius: ${({ theme }) => theme.landing_border_radius.full};
  height: 22px;
  position: absolute;
  transition: width 0.35s ease-in-out;
  width: 120%;

  /* mobile */
  @media (max-width: 767px) {
    width: 200px;
    height: 15px;
  }
`;

const S_Sheet4Movement = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 45%;

  & div:nth-of-type(2) {
    display: none;
  }

  /* mobile */
  @media (max-width: 767px) {
    width: 200px;

    & div:nth-of-type(1) {
      display: none;
    }

    & div:nth-of-type(2) {
      display: unset;
    }
  }
`;

const S_SheetTitle = styled.h3`
  font-size: ${({ theme }) => theme.landing_font_size.xl9};
  position: relative;
`;

const S_Sheet1Title = styled.h3`
  font-size: ${({ theme }) => theme.landing_font_size.xl9};
  position: relative;

  &::after {
    background-color: ${({ theme }) => theme.landing_color.white};
    border-radius: ${({ theme }) => theme.landing_border_radius.full};
    bottom: -20px;
    content: '';
    display: block;
    height: 22px;
    left: -10%;
    position: absolute;
    transition: width 0.2s ease-in-out;
    width: 0%;

    /* mobile */
    @media (max-width: 767px) {
      height: 15px;
      bottom: -10px;
    }
  }

  ${S_Sheet1ContentBox}:hover &::after {
    width: 120%;
  }
`;

const S_Grid = styled.div`
  display: grid;
  flex: none;
  gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-auto-rows: minmax(0, 1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, minmax(400px, 1fr));
  height: min-content;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  position: relative;
  width: 90%;

  /* mobile */
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, minmax(240px, 1fr));
    grid-column-gap: 0;
    margin-bottom: 0;
    width: 100%;
  }
`;

export default Section7;
