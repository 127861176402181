import {
  fontWeight,
  desktopFontSize,
  desktopLineHeight,
  desktopEditorFontSize,
  desktopEditorLineHeight,
  mobileFontSize,
  mobileLineHeight,
  mobileEditorFontSize,
  mobileEditorLineHeight,
  boxShadow,
  spacing,
  customTheme
} from 'pds-dev-kit-web';

import { ColorTheme, TypographyTheme, SpacingTheme, EffectTheme, BorderTheme } from '@common/types';

import type { DefaultTheme } from 'styled-components';

export const landing_color: ColorTheme['landing_color'] = {
  black: '#000000',
  grey950: '#151515',
  grey900: '#1e1e20',
  grey600: '#404042',
  grey500: '#68686b',
  grey400: '#aaaab1',
  grey200: '#D3D3D6',
  grey100: '#e8eaed',
  grey80: '#E9E9EC',
  grey70: '#eaebef',
  grey50: '#f5f6f8',
  grey30: '#f5f5f8',
  grey10: '#fbfbfd',
  white: '#ffffff',
  red500: '#ff463a',
  red30: '#FFE4E4',
  red10: '#FFF1F1',
  pink500: '#FB3A63',
  pink300: '#FD5B7D',
  lightpink500: '#FF708C',
  orange500: '#ff8200',
  orange50: '#FBE0C5',
  orange30: '#FAEDE0',
  green700: '#00b884',
  green500: '#1eebb4',
  green300: '#6effd1',
  green100: '#91F9C8',
  green50: '#CCF5E9',
  green30: '#E5F9F3',
  green10: '#EEFFFA',
  lightgreen500: '#32d64c',
  blue700: '#0040bb',
  blue500: '#455eef',
  blue300: '#838bff',
  blue50: '#e9ecfd',
  blue30: '#E3E7FF',
  blue10: '#F6F6FF',
  skyblue500: '#458BEF',
  skyblue300: '#45B9EF',
  skyblue100: '#B2E7FF',
  navy500: '#313349',
  navy100: '#6E77A9',
  pastelblue500: '#77B2EC',
  pastelpink500: '#ff6399',
  pastelorange500: '#ffa638',
  pastelgreen500: '#64DA73',
  pastelpurple500: '#b36de9'
};

export const landing_opacity: ColorTheme['landing_opacity'] = {
  opacity100: 'FF',
  opacity95: 'F2',
  opacity90: 'E6',
  opacity85: 'D9',
  opacity80: 'CC',
  opacity75: 'BF',
  opacity70: 'B3',
  opacity65: 'A6',
  opacity60: '99',
  opacity55: '8C',
  opacity50: '80',
  opacity45: '73',
  opacity40: '66',
  opacity35: '59',
  opacity30: '4D',
  opacity25: '40',
  opacity20: '33',
  opacity15: '26',
  opacity10: '1A',
  opacity05: '0D',
  opacity00: '00'
};

export const landing_font_weight: TypographyTheme['landing_font_weight'] = {
  black: '900',
  bold: '700',
  medium: '500'
};

export const landing_font_size: TypographyTheme['landing_font_size'] = {
  xl11: '8rem' /* 128px */,
  xl10: '6rem' /* 96px */,
  xl9: '4.5rem' /* 72px */,
  xl8: '3.75rem' /* 60px */,
  xl7: '3rem' /* 48px */,
  xl6: '2.5rem' /* 40px */,
  xl5: '2.25rem' /* 36px */,
  xl4: '2rem' /* 32px */,
  xl3: '1.875rem' /* 30px */,
  xl2: '1.5rem' /* 24px */,
  xl: '1.25rem' /* 20px */,
  lg: '1.125rem' /* 18px */,
  base: '1rem' /* 16px */,
  sm: '0.875rem' /* 14px */,
  xs: '0.75rem' /* 12px */
};

export const landing_line_height: TypographyTheme['landing_line_height'] = {
  loose: '2',
  relaxed: '1.625',
  normal: '1.5',
  snug: '1.375',
  tight: '1.25',
  none: '1'
};

export const landing_spacing: SpacingTheme['landing_spacing'] = {
  spacing_a: '4px',
  spacing_b: '8px',
  spacing_c: '12px',
  spacing_d: '16px',
  spacing_e: '24px',
  spacing_f: '32px',
  spacing_g: '48px',
  spacing_h: '64px',
  spacing_i: '88px',
  spacing_j: '120px',
  spacing_k: '160px',
  spacing_l: '240px',
  spacing_m: '288px',
  spacing_n: '320px'
};

export const landing_box_shadow: EffectTheme['landing_box_shadow'] = {
  elevation0: '0 0 0 0 #0003',
  elevation1: '0 2px 8px 0 #0003',
  elevation2: '0 3px 12px 0 #0003',
  elevation3: '0 4px 16px 0 #0003',
  elevation4: '0 5px 20px 0 #0003',
  elevation5: '0 6px 24px 0 #0003'
};

export const landing_border_radius: BorderTheme['landing_border_radius'] = {
  none: '0px',
  xs: '0.125rem' /* 2px */,
  sm: '0.25rem' /* 4px */,
  md: '0.375rem' /* 6px */,
  lg: '0.5rem' /* 8px */,
  xl: '0.75rem' /* 12px */,
  xl2: '1rem' /* 16px */,
  xl3: '1.5rem' /* 24px */,
  xl4: '2.5rem' /* 40px */,
  xl5: '3.5rem' /* 56px */,
  full: '9999px'
};

const theme: DefaultTheme = {
  landing_border_radius,
  landing_font_size,
  landing_font_weight,
  landing_line_height,
  landing_box_shadow,
  landing_color,
  landing_opacity,
  landing_spacing,

  spacing,
  boxShadow,
  fontWeight,
  desktopFontSize,
  desktopLineHeight,
  desktopEditorFontSize,
  desktopEditorLineHeight,
  mobileFontSize,
  mobileLineHeight,
  mobileEditorFontSize,
  mobileEditorLineHeight,
  ...customTheme('LIGHT')
};

export default theme;
