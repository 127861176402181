import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

function Section5Asset() {
  return (
    <S_Wrapper>
      <S_ImageWrapper1>
        <ImageView
          src="https://cdn.dev.publishingkit.net/0387213b-d9c2-43b1-ac71-bfdd9afe3b5c"
          width={700}
        />
      </S_ImageWrapper1>
      <S_ImageWrapper2>
        <ImageView
          src="https://cdn.dev.publishingkit.net/3b5db081-f44a-4c8d-b63e-b199892059fd"
          width={200}
        />
      </S_ImageWrapper2>
      <S_ImageWrapper3>
        <ImageView
          src="https://cdn.dev.publishingkit.net/06eb4c5f-6d2a-488c-8ab3-7fa1cd792cc7"
          width={120}
        />
      </S_ImageWrapper3>
      <S_ImageWrapper4>
        <ImageView
          src="https://cdn.dev.publishingkit.net/c8835fbd-c5ff-4fd7-acea-fd6e1d09f3c5"
          width={100}
        />
      </S_ImageWrapper4>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_ImageWrapper1 = styled.div`
  bottom: -80px;
  position: absolute;
  right: -60px;
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    bottom: -60px;
    right: -40px;
  }
`;

const S_ImageWrapper2 = styled.div`
  bottom: 80px;
  position: absolute;
  right: 570px;
  transform: rotate(5deg);
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    bottom: 85px;
    right: 565px;
  }
`;

const S_ImageWrapper3 = styled.div`
  opacity: 0;
  position: absolute;
  right: 145px;
  top: 145px;
  transform: rotate(-10deg);
  transition: all 0.3s 0.1s ease-in-out;

  ${S_Wrapper}:hover & {
    opacity: 1;
    right: 150px;
    top: 150px;
  }
`;

const S_ImageWrapper4 = styled.div`
  opacity: 0;
  position: absolute;
  right: 100px;
  top: 100px;
  transform: rotate(5deg);
  transition: all 0.2s 0.2s ease-in-out;

  ${S_Wrapper}:hover & {
    opacity: 1;
    right: 105px;
    top: 115px;
  }
`;

export default Section5Asset;
