import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { formatStringInAllCapsToPascalCase } from '@common/utils';

import { PLANS } from '../../types';

function TableHeader() {
  const { t } = useTranslation('translation');
  const rows = [PLANS.STARTER, PLANS.ESSENTIAL, PLANS.BUSINESS];

  return (
    <TableHeaderBox>
      <TableHeaderGrid>
        {rows.map((row, index) => (
          <TableHeaderCol key={row} columnStart={index === 0 ? 4 : index === 1 ? 6 : 8}>
            <TitleWrapper>
              <Title>{formatStringInAllCapsToPascalCase(t(row))}</Title>
            </TitleWrapper>
          </TableHeaderCol>
        ))}
      </TableHeaderGrid>
    </TableHeaderBox>
  );
}

const TableHeaderBox = styled.div`
  background-color: ${({ theme }) => theme.landing_color.grey30};
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_e};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableHeaderGrid = styled.div`
  display: grid;
  grid-column: 4 col-start / span 12;
  grid-template-columns: repeat(9, [col-start] 1fr);
  width: 100%;
`;

const TableHeaderCol = styled.div<{
  columnStart: number;
}>`
  grid-column: ${({ columnStart }) => columnStart} col-start / span 2;
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
`;

export default TableHeader;
