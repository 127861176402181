import { useCallback, useState, useRef, memo } from 'react';
import styled from 'styled-components';

import { Icon } from '@common/ui/components';
import { IconProps } from '@common/ui/components/Icon/Icon';
import { device } from '@common/ui/styles/media';

type Props = {
  title?: string | React.ReactNode;
  contents: React.ReactNode;
  iconSize?: IconProps['size'];
};

function Accordion({ title, contents, iconSize }: Props) {
  const [isCollapse, setIsCollapse] = useState(false);

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = useCallback(
    (event) => {
      event.stopPropagation();

      if (parentRef.current === null || childRef.current === null) {
        return;
      }

      if (isCollapse) {
        parentRef.current.style.height = '0';
      } else {
        parentRef.current.style.height = `${childRef.current.clientHeight}px`;
      }

      setIsCollapse(!isCollapse);
    },
    [isCollapse]
  );

  return (
    <AccordionBox isCollapse={isCollapse} onClick={handleButtonClick}>
      <Header>
        <Title>{title}</Title>
        <IconWrapper>
          <Icon iconName={isCollapse ? 'ic_minus' : 'ic_plus'} size={iconSize} />
        </IconWrapper>
      </Header>
      <ContentsWrapper ref={parentRef}>
        <Contents ref={childRef}>{contents}</Contents>
      </ContentsWrapper>
    </AccordionBox>
  );
}

const AccordionBox = styled.div<{
  isCollapse: boolean;
}>`
  background-color: ${({ theme }) => theme.landing_color.white};
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${({ theme, isCollapse }) =>
    isCollapse ? theme.landing_spacing.spacing_g : '0px'};
  position: relative;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};

    @media ${device.mobile} {
      margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
    }
  }

  @media ${device.mobile} {
    padding-bottom: ${({ theme, isCollapse }) =>
      isCollapse ? theme.landing_spacing.spacing_f : '0px'};
  }
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: 40px;
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_g};

  @media ${device.mobile} {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_f};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_f};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_f};
  }
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_d};
  white-space: pre-wrap;
  word-break: keep-all;
`;

const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
`;

const ContentsWrapper = styled.div`
  height: 0;
  overflow: hidden;
  overflow-y: auto;
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  transition: height 0.1s ease;
  width: inherit;

  @media ${device.mobile} {
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_f};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_f};
  }
`;

const Contents = styled.div`
  font-size: ${({ theme }) => theme.landing_font_size.xl};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};

  p {
    color: ${({ theme }) => theme.landing_color.grey500};
  }

  a {
    all: revert;
  }
`;

export default memo(Accordion);
