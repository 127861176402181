export type Partner = {
  caption: string;
  title: string;
  desc: string;
  logoSrc?: string;
  logoWidth?: number;
  email?: string;
  href?: string;
};

export const partners: Partner[] = [
  {
    caption: 'SOLUTION PARTNER',
    title: '11018',
    desc: '한국 대중음악의 역사를 기록하고 콘텐츠를 제작하는 11018은, publ 서비스를 활용하여 음악 관련 비즈니스를 원하는 사업자와 함께 음악 콘텐츠 제작 및 서비스 운영을 돕습니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_01.png',
    logoWidth: 131,
    email: 'pada11018@gmail.com',
    href: 'http://11018.xyz/'
  },
  {
    caption: 'SOLUTION PARTNER',
    title: '더스타 파트너',
    desc: '국내외 유명 아티스트의 해외 매니지먼트 사업을 수행하는 더스타파트너는, publ 서비스로 해외 비즈니스를 원하는 사업자와 콘텐츠 제작 및 서비스 운영을 돕습니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_02.png',
    logoWidth: 155,
    email: 'jmha@thestarpartner.co.kr',
    href: 'https://www.thestarpartner.com/'
  },
  {
    caption: 'SOLUTION PARTNER',
    title: '브레이커스',
    desc: '디지털 마케팅 에이전시인 브레이커스는, publ 서비스로 비즈니스를 시작하는 사업자의 마케팅을 돕습니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_08.png',
    logoWidth: 180,
    email: 'official@breakers.co.kr',
    href: 'https://www.breakers.co.kr/'
  },
  {
    caption: 'SOLUTION PARTNER',
    title: '필라멘트 미디어',
    desc: '디지털 마케팅 에이전시인 필라멘트미디어는, publ 서비스로 비즈니스를 시작하는 사업자에게 서비스 운영 및 마케팅을 돕습니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_04.png',
    logoWidth: 67,
    email: 'mceksoo@gmail.com',
    href: ''
  },
  {
    caption: 'SOLUTION PARTNER',
    title: '윗유',
    desc: '숏폼 크리에이티브 & 미디어 마케팅 전문 기업 윗유는, TikTok 및 Meta 의 공식 파트너이자 숏폼 크리에이터 이코노미를 만들고 있습니다. 관련 경험을 바탕으로 publ 사업자의 성공을 돕습니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_05.png',
    logoWidth: 111,
    email: 'mcn@witu.co.kr',
    href: 'https://witu.co.kr/'
  },
  {
    caption: 'SOLUTION ・ TECHNICAL PARTNER',
    title: 'KAVE',
    desc: '전세계 K-POP 팬을 대상으로 온라인 공연을 제공하는 케이브콘은 publ 서비스를 활용하여 국내외 아티스트의 온라인 콘텐츠 제작 및 사업 운영을 돕습니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_06.png',
    logoWidth: 184,
    email: 'kaveadmin@kavecon.com',
    href: 'https://www.kavecon.com/'
  },
  {
    caption: 'SOLUTION PARTNER',
    title: '추계예대',
    desc: '문화예술산업을 선도하는 융복합 예술인 양성을 목표로 하는 추계예대는, 교육기관의 예술 콘텐츠를 일반 대중과 함께 공유할 수 있는 유통 플랫폼과 지원사업 등의 활동으로 지역사회에 이바지하고자 합니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_07.png',
    logoWidth: 170,
    email: '',
    href: 'https://www.chugye.ac.kr/'
  },
  {
    caption: 'SOLUTION PARTNER',
    title: '씨어터',
    desc: '뉴미디어 콘텐츠 제작과 관련 기술을 보유한 씨어터는, publ 서비스로 교육, 엔터테인먼트 등 전반 영역에 걸쳐 콘텐츠 제작 및 서비스 운영을 돕습니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_10.png',
    logoWidth: 139,
    email: 'jyoh@seeutter.com',
    href: 'https://seeutter.com/'
  },
  {
    caption: 'SOLUTION PARTNER',
    title: '패스트라이프',
    desc: '패스트라이프는 디지털 사업 전문기업으로서 publ 서비스를 이용한 콘텐츠 사업 기획, 서비스 및 요금상품 기획, 서비스 온보딩, 운영 대행, 마케팅 등 통합 비즈니스 컨시어지 서비스를 제공합니다.',
    logoSrc:
      'https://static.publ.site/landing_page/partners/section3/img_landing_partner_logo_09.png',
    logoWidth: 213,
    email: 'diefun@fastlife.kr',
    href: 'https://www.fastlife.kr/'
  }
];
