import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Feature, features } from '../../features';
import ScrollSpy from '../ScrollSpy';

import { TableBodyRowItem } from './TableBodyRowItem';

function TableBody() {
  const { t } = useTranslation('translation');

  const featuresArray: Feature[] = Object.entries(features).map(([key, feature]: [string, any]) => {
    return { id: key, ...feature };
  });

  return (
    <TableBodyLayout>
      <TableBodyGrid scrollThrottle={80} useBoxMethod updateHistoryStack={false}>
        {featuresArray.map((feature) => {
          return (
            <FeatureSheet key={feature.id} id={feature.id}>
              <FeatureList>
                {feature.table.map((row) => (
                  <TableBodyRowItem
                    key={row.name}
                    title={t(row.name)}
                    description={t(row.description)}
                    tip={t(row.tip)}
                    superscript={row.footnoteNumber || undefined}
                    columns={[
                      typeof row.col1 === 'string' ? t(row.col1) : row.col1,
                      typeof row.col2 === 'string' ? t(row.col2) : row.col2,
                      typeof row.col3 === 'string' ? t(row.col3) : row.col3
                    ]}
                  />
                ))}
              </FeatureList>

              <FootnoteList>
                {feature.footnotes.map((footnote, index) => (
                  <Footnote key={index}>{`${index + 1} ${t(footnote)}`}</Footnote>
                ))}
              </FootnoteList>
            </FeatureSheet>
          );
        })}
      </TableBodyGrid>
    </TableBodyLayout>
  );
}

const TableBodyLayout = styled.div`
  grid-column: 4 col-start / span 12;
`;

const TableBodyGrid = styled(ScrollSpy)`
  grid-template-columns: repeat(12, [col-start] 1fr);
`;

const FeatureSheet = styled.div`
  background-color: ${({ theme }) => theme.landing_color.white};
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
`;

const FeatureList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const FootnoteList = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  text-align: end;
`;

const Footnote = styled.div`
  color: ${({ theme }) => theme.landing_color.grey400};
  font-size: 0.5rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  white-space: pre-wrap;
  word-break: keep-all;
`;

export default TableBody;
