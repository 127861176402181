import { Investor } from './types';

export const investor: Investor[] = [
  {
    imageSrc:
      'https://static.publ.site/landing_page/service/section10/img_investor_logo_softbank.png'
  },
  {
    imageSrc: 'https://static.publ.site/landing_page/service/section10/img_investor_logo_behigh.png'
  },
  {
    imageSrc: 'https://static.publ.site/landing_page/service/section10/img_investor_logo_bass.png'
  },
  {
    imageSrc:
      'https://static.publ.site/landing_page/service/section10/img_investor_logo_intervaluepartners.png'
  },
  {
    imageSrc:
      'https://static.publ.site/landing_page/service/section10/img_investor_logo_nvestor.png'
  },
  {
    imageSrc: 'https://static.publ.site/landing_page/service/section10/img_investor_logo_kvic.png'
  },
  {
    imageSrc:
      'https://static.publ.site/landing_page/service/section10/img_investor_logo_enlightventures.png'
  },
  {
    imageSrc: 'https://static.publ.site/landing_page/service/section10/img_investor_logo_lotte.png'
  },
  {
    imageSrc: 'https://static.publ.site/landing_page/service/section10/img_investor_logo_logan.png'
  }
];
