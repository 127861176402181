import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  ReactNode,
  CSSProperties,
  FC,
  forwardRef,
  Children,
  MutableRefObject,
  RefAttributes
} from 'react';
import styled, { css } from 'styled-components';

type MarqueeProps = {
  /**
   * @description Inline style for the container div
   * @type {CSSProperties}
   * @default {}
   */
  style?: CSSProperties;
  /**
   * @description Class name to style the container div
   * @type {string}
   * @default ""
   */
  className?: string;
  /**
   * @description Whether to automatically fill blank space in the marquee with copies of the children or not
   * @type {boolean}
   * @default false
   */
  autoFill?: boolean;
  /**
   * @description Whether to play or pause the marquee
   * @type {boolean}
   * @default true
   */
  play?: boolean;
  /**
   * @description Whether to pause the marquee when hovered
   * @type {boolean}
   * @default false
   */
  pauseOnHover?: boolean;
  /**
   * @description Whether to pause the marquee when clicked
   * @type {boolean}
   * @default false
   */
  pauseOnClick?: boolean;
  /**
   * @description The direction the marquee is sliding
   * @type {"left" | "right" | "up" | "down"}
   * @default "left"
   */
  direction?: 'left' | 'right' | 'up' | 'down';
  /**
   * @description Speed calculated as pixels/second
   * @type {number}
   * @default 50
   */
  speed?: number;
  /**
   * @description Duration to delay the animation after render, in seconds
   * @type {number}
   * @default 0
   */
  delay?: number;
  /**
   * @description The number of times the marquee should loop, 0 is equivalent to infinite
   * @type {number}
   * @default 0
   */
  loop?: number;
  /**
   * @description Whether to show the gradient or not
   * @type {boolean}
   * @default false
   */
  gradient?: boolean;
  /**
   * @description The rgb color of the gradient as an array of length 3
   * @type {Array<number>} of length 3
   * @default [255, 255, 255]
   */
  gradientColor?: [number, number, number];
  /**
   * @description The width of the gradient on either side
   * @type {number | string}
   * @default 200
   */
  gradientWidth?: number | string;
  /**
   * @description A callback for when the marquee finishes scrolling and stops. Only calls if loop is non-zero.
   * @type {() => void}
   * @default null
   */
  onFinish?: () => void;
  /**
   * @description A callback for when the marquee finishes a loop. Does not call if maximum loops are reached (use onFinish instead).
   * @type {() => void}
   * @default null
   */
  onCycleComplete?: () => void;
  /**
   * @description The children rendered inside the marquee
   * @type {ReactNode}
   * @default null
   */
  children?: ReactNode;
} & RefAttributes<HTMLDivElement>;

const Marquee: FC<MarqueeProps> = forwardRef(function Marquee(
  {
    style = {},
    className = '',
    autoFill = false,
    play = true,
    pauseOnHover = false,
    pauseOnClick = false,
    direction = 'left',
    speed = 50,
    delay = 0,
    loop = 0,
    gradient = false,
    gradientColor = [255, 255, 255],
    gradientWidth = 200,
    onFinish,
    onCycleComplete,
    children
  },
  ref
) {
  // React Hooks
  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const containerRef = (ref as MutableRefObject<HTMLDivElement>) || rootRef;
  const marqueeRef = useRef<HTMLDivElement>(null);

  // Calculate width of container and marquee and set multiplier
  const calculateWidth = useCallback(() => {
    if (marqueeRef.current && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const marqueeRect = marqueeRef.current.getBoundingClientRect();
      let containerWidth = containerRect.width;
      let marqueeWidth = marqueeRect.width;

      // Swap width and height if direction is up or down
      if (direction === 'up' || direction === 'down') {
        containerWidth = containerRect.height;
        marqueeWidth = marqueeRect.height;
      }

      if (autoFill && containerWidth && marqueeWidth) {
        setMultiplier(marqueeWidth < containerWidth ? Math.ceil(containerWidth / marqueeWidth) : 1);
      } else {
        setMultiplier(1);
      }

      setContainerWidth(containerWidth);
      setMarqueeWidth(marqueeWidth);
    }
  }, [autoFill, containerRef, direction]);

  // Calculate width and multiplier on mount and on window resize
  useEffect(() => {
    if (!isMounted) return;

    calculateWidth();
    if (marqueeRef.current && containerRef.current) {
      const resizeObserver = new ResizeObserver(() => calculateWidth());
      resizeObserver.observe(containerRef.current);
      resizeObserver.observe(marqueeRef.current);
      return () => {
        if (!resizeObserver) return;
        resizeObserver.disconnect();
      };
    }
  }, [calculateWidth, containerRef, isMounted]);

  // Recalculate width when children change
  useEffect(() => {
    calculateWidth();
  }, [calculateWidth, children]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Animation duration
  const duration = useMemo(() => {
    if (autoFill) {
      return (marqueeWidth * multiplier) / speed;
    }
    return marqueeWidth < containerWidth ? containerWidth / speed : marqueeWidth / speed;
  }, [autoFill, containerWidth, marqueeWidth, multiplier, speed]);

  // Gradient color in an unfinished rgba format
  const rgbaGradientColor = `rgba(${gradientColor[0]}, ${gradientColor[1]}, ${gradientColor[2]}`;

  const containerStyle = useMemo(
    () => ({
      ...style,
      ['--pause-on-hover' as string]: !play || pauseOnHover ? 'paused' : 'running',
      ['--pause-on-click' as string]:
        !play || (pauseOnHover && !pauseOnClick) || pauseOnClick ? 'paused' : 'running',
      ['--width' as string]: direction === 'up' || direction === 'down' ? `100vh` : '100%',
      ['--transform' as string]:
        direction === 'up' ? 'rotate(-90deg)' : direction === 'down' ? 'rotate(90deg)' : 'none'
    }),
    [style, play, pauseOnHover, pauseOnClick, direction]
  );

  const gradientStyle = useMemo(
    () => ({
      ['--gradient-color' as string]: `${rgbaGradientColor}, 1), ${rgbaGradientColor}, 0)`,
      ['--gradient-width' as string]:
        typeof gradientWidth === 'number' ? `${gradientWidth}px` : gradientWidth
    }),
    [rgbaGradientColor, gradientWidth]
  );

  const marqueeStyle = useMemo(
    () => ({
      ['--play' as string]: play ? 'running' : 'paused',
      ['--direction' as string]: direction === 'left' ? 'normal' : 'reverse',
      ['--duration' as string]: `${duration}s`,
      ['--delay' as string]: `${delay}s`,
      ['--iteration-count' as string]: loop ? `${loop}` : 'infinite',
      ['--min-width' as string]: autoFill ? `auto` : '100%'
    }),
    [play, direction, duration, delay, loop, autoFill]
  );

  const childStyle = useMemo(
    () => ({
      ['--transform' as string]:
        direction === 'up' ? 'rotate(90deg)' : direction === 'down' ? 'rotate(-90deg)' : 'none'
    }),
    [direction]
  );

  // Render {multiplier} number of children
  const multiplyChildren = useCallback(
    (multiplier: number) => {
      return [...Array(Number.isFinite(multiplier) && multiplier >= 0 ? multiplier : 0)].map(
        (_, i) => (
          <Fragment key={i}>
            {Children.map(children, (child) => {
              return (
                <div style={childStyle} className="child">
                  {child}
                </div>
              );
            })}
          </Fragment>
        )
      );
    },
    [childStyle, children]
  );

  return !isMounted ? null : (
    <S_MarqueeContainer ref={containerRef} style={containerStyle} className={` ${className}`}>
      {gradient && <S_Overlay style={gradientStyle} />}
      <S_Marquee
        style={marqueeStyle}
        onAnimationIteration={onCycleComplete}
        onAnimationEnd={onFinish}
      >
        <S_ChildContainer ref={marqueeRef}>
          {Children.map(children, (child) => {
            return <S_Child style={childStyle}>{child}</S_Child>;
          })}
        </S_ChildContainer>
        {multiplyChildren(multiplier - 1)}
      </S_Marquee>
      <S_Marquee style={marqueeStyle}>{multiplyChildren(multiplier)}</S_Marquee>
    </S_MarqueeContainer>
  );
});

export default Marquee;

const S_MarqueeContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  overflow-x: hidden !important;
  position: relative;
  transform: var(--transform);
  width: var(--width);

  &:hover div {
    animation-play-state: var(--pause-on-hover);
  }

  &:active div {
    animation-play-state: var(--pause-on-click);
  }
`;

const gradient = css`
  background: linear-gradient(to right, var(--gradient-color));
`;

const S_Overlay = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;

  &::before,
  &::after {
    ${gradient}

    content: '';
    height: 100%;
    position: absolute;
    width: var(--gradient-width);
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

const S_Marquee = styled.div`
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
  animation-play-state: var(--play);
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  min-width: var(--min-width);
  z-index: 1;

  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const S_ChildContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  min-width: auto;
`;

const S_Child = styled.div`
  transform: var(--transform);
`;
