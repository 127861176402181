export type Feature = {
  title: string;
  id: string;
  footnotes: string[];
  table: {
    name: string;
    description: string;
    tip: string;
    footnoteNumber: number;
    col1: boolean | string;
    col2: boolean | string;
    col3: boolean | string;
  }[];
};

export const features = {
  'main-feature': {
    title: 'str_220',
    footnotes: ['str_251'],
    table: [
      {
        name: 'str_228',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: 'str_229',
        col2: 'str_230',
        col3: 'str_231'
      },
      {
        name: 'str_232',
        description: '',
        tip: 'str_233',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_234',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: 'str_235',
        col2: 'str_236',
        col3: 'str_237'
      },
      {
        name: 'str_238',
        description: '',
        tip: 'str_239',
        footnoteNumber: 0,
        col1: '-',
        col2: 'str_240',
        col3: 'str_241'
      },
      {
        name: 'str_242',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_243',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: 'str_244',
        col2: 'str_245',
        col3: 'str_246'
      },
      {
        name: 'str_247',
        description: '',
        tip: 'str_248',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_249',
        description: '',
        tip: 'str_250',
        footnoteNumber: 1,
        col1: '-',
        col2: '-',
        col3: true
      }
    ]
  },
  'community-feature': {
    title: 'str_221',
    footnotes: [],
    table: [
      {
        name: 'str_252',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_253',
        description: 'str_254',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_255',
        description: 'str_256',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_257',
        description: '',
        tip: 'str_258',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_259',
        description: '',
        tip: 'str_260',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_261',
        description: 'str_262',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      }
    ]
  },
  'video-feature': {
    title: 'str_222',
    footnotes: ['str_292', 'str_293', 'str_294'],
    table: [
      {
        name: 'str_263',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_264',
        description: '',
        tip: 'str_265',
        footnoteNumber: 1,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_266',
        description: '',
        tip: 'str_267',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_268',
        description: '',
        tip: 'str_269',
        footnoteNumber: 2,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_270',
        description: '',
        tip: 'str_271',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_272',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_273',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_274',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: 'str_275',
        col2: 'str_276',
        col3: 'str_277'
      },
      {
        name: 'str_278',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: 'str_279',
        col2: 'str_280',
        col3: 'str_281'
      },
      {
        name: 'str_282',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_283',
        description: 'str_284',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_285',
        description: '',
        tip: '',
        footnoteNumber: 3,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_286',
        description: '',
        tip: 'str_287',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_288',
        description: '',
        tip: 'str_289',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_290',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_291',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      }
    ]
  },
  'messenger-feature': {
    title: 'str_223',
    footnotes: ['str_302', 'str_303'],
    table: [
      {
        name: 'str_295',
        description: '',
        tip: '',
        footnoteNumber: 1,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_296',
        description: 'str_297',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_298',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_299',
        description: '',
        tip: '',
        footnoteNumber: 2,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_300',
        description: '',
        tip: 'str_301',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      }
    ]
  },
  'video-call-feature': {
    title: 'str_224',
    footnotes: ['str_322'],
    table: [
      {
        name: 'str_304',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_305',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_306',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_307',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_308',
        description: 'str_309',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_310',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_311',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_312',
        description: '',
        tip: '',
        footnoteNumber: 1,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_313',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: 'str_314',
        col2: 'str_315',
        col3: 'str_316'
      },
      {
        name: 'str_317',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_318',
        description: '',
        tip: 'str_319',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_320',
        description: '',
        tip: 'str_321',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      }
    ]
  },
  'monetization-feature': {
    title: 'str_225',
    footnotes: ['str_348', 'str_349', 'str_350', 'str_351'],
    table: [
      {
        name: 'str_323',
        description: '',
        tip: 'str_324',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_325',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_326',
        description: '',
        tip: 'str_327',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_328',
        description: '',
        tip: 'str_329',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_330',
        description: '',
        tip: 'str_331',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_332',
        description: '',
        tip: 'str_333',
        footnoteNumber: 0,
        col1: '-',
        col2: '-',
        col3: true
      },
      {
        name: 'str_334',
        description: '',
        tip: 'str_335',
        footnoteNumber: 1,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_336',
        description: '',
        tip: 'str_337',
        footnoteNumber: 2,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_338',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_339',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_340',
        description: '',
        tip: '',
        footnoteNumber: 3,
        col1: 'str_341',
        col2: 'str_342',
        col3: 'str_343'
      },
      {
        name: 'str_344',
        description: '',
        tip: '',
        footnoteNumber: 4,
        col1: 'str_345',
        col2: 'str_346',
        col3: 'str_347'
      }
    ]
  },
  'channel-management-feature': {
    title: 'str_226',
    footnotes: ['str_392', 'str_393'],
    table: [
      {
        name: 'str_353',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_354',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: '-',
        col2: 'str_355',
        col3: 'str_356'
      },
      {
        name: 'str_357',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: '-',
        col2: true,
        col3: true
      },
      {
        name: 'str_358',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: 'str_359',
        col2: 'str_360',
        col3: 'str_361'
      },
      {
        name: 'str_362',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: 'str_363',
        col2: 'str_364',
        col3: 'str_365'
      },
      {
        name: 'str_366',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: '-',
        col2: '-',
        col3: true
      },
      {
        name: 'str_367',
        description: '',
        tip: 'str_368',
        footnoteNumber: 0,
        col1: '-',
        col2: '-',
        col3: true
      },
      {
        name: 'str_369',
        description: '',
        tip: 'str_370',
        footnoteNumber: 2,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_371',
        description: '',
        tip: 'str_372',
        footnoteNumber: 0,
        col1: 'str_373',
        col2: 'str_374',
        col3: 'str_375'
      },
      {
        name: 'str_376',
        description: '',
        tip: 'str_377',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_378',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_379',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_380',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_381',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_382',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_383',
        description: '',
        tip: 'str_384',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_385',
        description: '',
        tip: 'str_386',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_387',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_388',
        description: '',
        tip: 'str_389',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_390',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_391',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      }
    ]
  },
  'support-feature': {
    title: 'str_227',
    footnotes: [],
    table: [
      {
        name: 'str_394',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_395',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_396',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_397',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },

      {
        name: 'str_398',
        description: '',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      },
      {
        name: 'str_399',
        description: 'str_400',
        tip: '',
        footnoteNumber: 0,
        col1: true,
        col2: true,
        col3: true
      }
    ]
  }
};
