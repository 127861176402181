import { PLANS } from '../types';

export const plans = [
  {
    type: PLANS.TRIAL,
    fee: {
      monthly: 'str_138',
      annual: 'str_138'
    },
    buttonText: 'str_141',
    description: 'str_139',
    contents: '',
    tags: [],
    caption: 'str_137',
    checkList: [],
    annotation: ''
  },
  {
    type: PLANS.STARTER,
    fee: {
      monthly: 'str_144',
      annual: 'str_145'
    },
    buttonText: 'str_153',
    description: '',
    contents: '',
    tags: ['#홈페이지', '#수익창출 가능'],
    caption: 'str_143',
    checkList: ['str_147', 'str_148', 'str_149', 'str_150', 'str_151', 'str_152'],
    annotation: ''
  },
  {
    type: PLANS.ESSENTIAL,
    fee: {
      monthly: 'str_156',
      annual: 'str_157'
    },
    buttonText: 'str_165',
    description: '',
    contents: '',
    tags: ['#도메인 연결', '#더 많은 스토리지'],
    caption: 'str_155',
    checkList: ['str_159', 'str_160', 'str_161', 'str_162', 'str_163', 'str_164'],
    annotation: ''
  },
  {
    type: PLANS.BUSINESS,
    fee: {
      monthly: 'str_168',
      annual: 'str_169'
    },
    buttonText: 'str_178',
    description: '',
    contents: '',
    tags: ['#대용량 스토리지', '#고급 관리도구', '#앱 신청 가능'],
    caption: 'str_167',
    checkList: ['str_171', 'str_172', 'str_173', 'str_174', 'str_175', 'str_176', 'str_177'],
    annotation: 'str_179'
  }
];
