import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

import { investor } from './data/investor';

function Section10() {
  const { t } = useTranslation('translation');

  return (
    <>
      <S_Title>{t('str_131')}</S_Title>
      <S_ContentBox>
        <S_GridItem1>
          {investor.map((data, index) => (
            <Image key={index} src={data.imageSrc} />
          ))}
        </S_GridItem1>
      </S_ContentBox>
    </>
  );
}

const S_ContentBox = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-template-columns: repeat(12, 1fr);
  position: relative;

  @media ${device.mobile} {
    grid-template-columns: repeat(4, 1fr);
    gap: ${({ theme }) => theme.landing_spacing.spacing_f};
  }
`;

const S_Title = styled.h2`
  font-size: ${({ theme }) => theme.landing_font_size.xl4};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  margin-top: ${({ theme }) => theme.landing_spacing.spacing_g};
  text-align: center;

  @media ${device.mobile} {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
    font-size: ${({ theme }) => theme.landing_font_size.xl4};
  }
`;

const S_GridItem1 = styled.div`
  column-gap: ${({ theme }) => theme.landing_spacing.spacing_h};
  display: flex;
  flex-wrap: wrap;
  grid-column: 2 / 12;
  grid-row: 1 / 1;
  justify-content: center;
  row-gap: ${({ theme }) => theme.landing_spacing.spacing_e};

  @media ${device.tablet} {
    column-gap: ${({ theme }) => theme.landing_spacing.spacing_g};
  }

  @media ${device.mobile} {
    grid-column: 1 / 5;
    column-gap: ${({ theme }) => theme.landing_spacing.spacing_f};
    row-gap: ${({ theme }) => theme.landing_spacing.spacing_d};
  }
`;

const Image = styled.img`
  height: 56px;
  min-height: 56px;

  @media ${device.mobile} {
    height: 24px;
    min-height: 24px;
  }
`;

export default Section10;
