import type IconType from '../IconType';

function ArrowRightLong({ color, ...rest }: IconType) {
  return (
    <svg width="31px" height="18px" viewBox="0 0 31 18" {...rest}>
      <g
        id="그리드"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Asset"
          transform="translate(-6982.000000, -6891.000000)"
          stroke={color}
          strokeWidth="2"
        >
          <g id="ic_landing_arrow_w" transform="translate(6983.000000, 6892.500000)">
            <path
              d="M22,0 L28.7615221,6.911 C29.0794926,7.236 29.0794926,7.764 28.7615221,8.089 L22,15"
              id="Stroke-3"
              transform="translate(25.500000, 7.500000) rotate(-360.000000) translate(-25.500000, -7.500000) "
            />
            <line x1="-3.86538024e-12" y1="7.5" x2="28" y2="7.49926398" id="Line" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ArrowRightLong;
