const size = {
  mobile: '767px',
  tablet: '1023px',
  laptop: '1440px'
};

export const device = {
  mobile: `screen and (max-width: ${size.mobile})`,
  tablet: `screen and (max-width: ${size.tablet})`,
  laptop: `screen and (max-width: ${size.laptop})`
};
