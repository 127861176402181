import styled from 'styled-components';

import { MainButton } from '@common/ui/components';

function Section3() {
  return (
    <S_ContentWrapper>
      <S_Title>파트너 프로그램 신청 문의</S_Title>
      <S_Desc>{`publ 서비스를 이용하여 다른 사업자의 비즈니스를 수행하시나요?
파트너 프로그램에는 다른 사업자를 위한 publ 전용 계약 조건과 기능, 지원이 포함됩니다.`}</S_Desc>
      <MainButton
        size="large"
        backgroundColorKey="black"
        textColorKey="white"
        href="mailto:contact@publ.biz"
      >
        문의하기
      </MainButton>
    </S_ContentWrapper>
  );
}

const S_ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const S_Title = styled.h2`
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  text-align: center;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }
`;

const S_Desc = styled.p`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;
`;

export default Section3;
