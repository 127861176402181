import { useTheme } from 'styled-components';

import { ColorTheme } from '@common/types';
import { LineIconNameKeys, FillIconNameKeys, IconType } from '@common/types/icon';

import fillIcons from '../../../assets/icons/fill';
import lineIcons from '../../../assets/icons/line';

type ColorType = keyof ColorTheme['landing_color'];

export type IconProps = {
  iconName?: IconType;
  size?: 12 | 16 | 20 | 24 | 28 | 32 | 40 | 48 | 56 | 64 | 72;
  colorKey?: ColorType;
  overrideColor?: `#${string}`;
} & Record<string, any>;

function Icon({
  iconName = 'ic_arrow_right_long',
  size,
  colorKey = 'grey500',
  overrideColor,
  ...props
}: IconProps) {
  const theme = useTheme();

  const DefaultIcon = lineIcons.ic_arrow_right_long;
  const SelectedIcon =
    lineIcons[iconName as LineIconNameKeys] || fillIcons[iconName as FillIconNameKeys];

  return SelectedIcon ? (
    <SelectedIcon
      color={overrideColor ? overrideColor : theme.landing_color[colorKey]}
      size={size}
      style={{ minWidth: size, minHeight: size }}
      {...props}
    />
  ) : (
    <DefaultIcon
      color={overrideColor ? overrideColor : theme.landing_color[colorKey]}
      size={size}
      style={{ minWidth: size, minHeight: size }}
      {...props}
    />
  );
}

export default Icon;
