import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CONSOLE_PUBL_BIZ_LINK, REQUEST_REDIRECT_TO_CONSOLE } from '@common/constants/hardcoded';
import { device } from '@common/ui/styles/media';

import Accordion from './Accordion';

function FAQSection() {
  const isInIFrame = window.location !== window.parent.location;

  const handlePAButton = () => {
    window.parent.postMessage({ message: REQUEST_REDIRECT_TO_CONSOLE }, '*');
  };
  const { t } = useTranslation('translation');

  const faqs = [
    {
      title: 'str_403',
      contents: (
        <p>
          {t('str_405')}
          {isInIFrame ? (
            <S_PAButton onClick={handlePAButton}>시작하기</S_PAButton>
          ) : (
            <a href={CONSOLE_PUBL_BIZ_LINK} target="_parent" rel="noopener noreferrer">
              {t('str_406')}
            </a>
          )}
          {t('str_407')}
        </p>
      )
    },
    {
      title: 'str_408',
      contents: (
        <p>
          {t('str_410')}
          <a href="mailto:support@publ.biz" target="_blank" rel="noopener noreferrer">
            {t('str_411')}
          </a>
          {t('str_412')}
        </p>
      )
    },
    {
      title: 'str_413',
      contents: (
        <p>
          {t('str_415')}
          <a href="mailto:support@publ.biz" target="_blank" rel="noopener noreferrer">
            {t('str_416')}
          </a>
          {t('str_417')}
        </p>
      )
    },
    {
      title: 'str_418',
      contents: <p>{t('str_419')}</p>
    }
  ];

  return (
    <FAQSectionLayout>
      <>
        <SectionTitleBox>
          <SectionTitle>{t('str_401')}</SectionTitle>
          <SectionDescription>{t('str_402')}</SectionDescription>
        </SectionTitleBox>
        <AccordionList>
          {faqs.map((faq, index) => (
            <Accordion key={index} title={t(faq.title)} contents={faq.contents} iconSize={40} />
          ))}
        </AccordionList>
      </>
    </FAQSectionLayout>
  );
}

const FAQSectionLayout = styled.div`
  align-items: flex-start;
  display: flex;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_k};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_i};

  @media ${device.mobile} {
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_h};
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
    flex-direction: column;
    align-items: center;
  }
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${device.mobile} {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  }
`;

const SectionTitle = styled.span`
  font-size: 3.5rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};

  @media ${device.mobile} {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
`;

const SectionDescription = styled.span`
  color: ${({ theme }) => theme.landing_color.grey600};
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_c};

  @media ${device.mobile} {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
`;

const AccordionList = styled.div`
  flex: 3;
`;

const S_PAButton = styled.div`
  color: blue;
  cursor: pointer;
  display: inline-block;
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  text-decoration: underline;
`;

export default FAQSection;
