import styled from 'styled-components';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

function FlexTable() {
  return (
    <FlexTableContainer>
      <TableHeader />
      <TableBody />
    </FlexTableContainer>
  );
}

const FlexTableContainer = styled.div`
  display: flex;
  flex-direction: column;

  .active-scroll-spy {
    color: ${({ theme }) => theme.landing_color.grey900};
  }
`;

export default FlexTable;
