import { Route, Switch } from 'react-router';

import {
  monetizeMethodCommerceSectionPath,
  monetizeMethodContentsSectionPath,
  monetizeMethodMembershipSectionPath,
  monetizeMethodTitleSectionPath
} from '@common/routers';

import { Section6_1, Section6_2, Section6_3, Section6_4 } from './sections';

function SeparateSection6() {
  return (
    <Switch>
      <Route exact path={monetizeMethodTitleSectionPath}>
        <Section6_1 isSeparate />
      </Route>
      <Route exact path={monetizeMethodContentsSectionPath}>
        <Section6_2 isSeparate />
      </Route>
      <Route exact path={monetizeMethodMembershipSectionPath}>
        <Section6_3 isSeparate />
      </Route>
      <Route exact path={monetizeMethodCommerceSectionPath}>
        <Section6_4 isSeparate />
      </Route>
    </Switch>
  );
}

export default SeparateSection6;
