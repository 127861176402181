import { ImageView, Spacing } from 'pds-dev-kit-web';
import styled, { css } from 'styled-components';

import { TextType } from '@common/types/text';
import { MainButton } from '@common/ui/components';
import { device } from '@common/ui/styles/media';

import type { ColorTheme, EffectTheme } from '@common/types';

type ColorType = keyof ColorTheme['landing_color'];
type BoxShadowType = keyof EffectTheme['landing_box_shadow'];

export type PartnerCardProps = {
  captionText?: TextType;
  titleText: TextType;
  descText: TextType;
  textColorTheme?: 'white' | 'black' | 'grey900';
  backgroundColorKey?: ColorType;
  overrideBackgroundColor?: `#${string}`;
  boxShadow?: 'none' | BoxShadowType;
  borderMode?: 'none' | 'use';
  borderWidth?: 1 | 2;
  borderColorKey?: ColorType;
  overrideBorderColor?: `#${string}`;
  gridColumnStart?: string;
  gridColumnEnd?: string;
  gridRowStart?: string;
  gridRowEnd?: string;
  href?: string;
  email?: string;
  logoSrc?: string;
  logoWidth?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export type StyleProps = {
  textColorTheme?: 'white' | 'black' | 'grey900';
  $backgroundColorKey?: ColorType;
  overrideBackgroundColor?: `#${string}`;
  $boxShadow?: 'none' | BoxShadowType;
  borderMode?: 'none' | 'use';
  $borderWidth?: 1 | 2;
  $borderColorKey?: ColorType;
  overrideBorderColor?: `#${string}`;
  $gridColumnStart?: string;
  $gridColumnEnd?: string;
  $gridRowStart?: string;
  $gridRowEnd?: string;
  hasOnClick?: boolean;
};

function PartnerCard({
  captionText,
  titleText,
  descText,
  textColorTheme = 'black',
  backgroundColorKey = 'grey30',
  overrideBackgroundColor,
  boxShadow = 'none',
  borderMode = 'none',
  borderWidth = 1,
  borderColorKey = 'grey100',
  overrideBorderColor,
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  gridRowEnd,
  href,
  email,
  logoSrc,
  logoWidth,
  onClick
}: PartnerCardProps) {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  function PartnerCardVariation() {
    return (
      <S_PartnerCard
        $backgroundColorKey={backgroundColorKey}
        $boxShadow={boxShadow}
        overrideBackgroundColor={overrideBackgroundColor}
        borderMode={borderMode}
        $borderWidth={borderWidth}
        $borderColorKey={borderColorKey}
        overrideBorderColor={overrideBorderColor}
        $gridColumnStart={gridColumnStart}
        $gridColumnEnd={gridColumnEnd}
        $gridRowStart={gridRowStart}
        $gridRowEnd={gridRowEnd}
        onClick={handleClick}
        hasOnClick={!!onClick}
      >
        <S_TextBox textColorTheme={textColorTheme}>
          <S_Caption>{`${captionText}`}</S_Caption>
          <S_ContentBox>
            <ImageView src={logoSrc} shapeType="rectangle" width={logoWidth} />
          </S_ContentBox>
          <S_Title>{`${titleText}`}</S_Title>
          <S_Desc>{`${descText}`}</S_Desc>
        </S_TextBox>
        {(email || href) && (
          <S_ButtonBox>
            {email && (
              <>
                <S_MainButton size="large" backgroundColorKey="black" href={`mailto:${email}`}>
                  문의하기
                </S_MainButton>
                <Spacing size="spacing_d" spacingType="width" />
              </>
            )}
            {href && (
              <S_MainButton
                size="large"
                textColorKey="black"
                overrideBackgroundColor="#ffffff00"
                overrideCSS={{ paddingLeft: '12px', paddingRight: '12px' }}
                href={href}
                target="_blank"
              >
                홈페이지
              </S_MainButton>
            )}
          </S_ButtonBox>
        )}
      </S_PartnerCard>
    );
  }
  return PartnerCardVariation();
}

const S_TextBox = styled.div<StyleProps>`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  text-align: left;

  ${({ theme, textColorTheme }) =>
    textColorTheme &&
    {
      white: `color: ${theme.landing_color.white};`,
      black: `color: ${theme.landing_color.black};`,
      grey900: `color: ${theme.landing_color.grey900};`
    }[textColorTheme]};

  @media ${device.mobile} {
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  }
`;

const S_Caption = styled.p`
  color: ${({ theme }) => theme.landing_color.grey400};
  font-size: ${({ theme }) => theme.landing_font_size.lg};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_d};

  @media ${device.mobile} {
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  }
`;

const S_Title = styled.h3`
  font-size: ${({ theme }) => theme.landing_font_size.xl7};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};

  @media ${device.mobile} {
    display: none;
  }
`;

const S_Desc = styled.p`
  font-weight: ${({ theme }) => theme.landing_font_weight.medium};
`;

const S_ContentBox = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.landing_spacing.spacing_g};
  top: ${({ theme }) => theme.landing_spacing.spacing_g};

  @media ${device.mobile} {
    position: relative;
    right: unset;
    top: unset;
    display: flex;
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
    justify-content: center;
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const S_MainButton = styled(MainButton)`
  @media ${device.mobile} {
    max-width: 100%;
    width: 100%;
  }
`;

const boxShadowStyle = css<StyleProps>`
  box-shadow: ${({ theme, $boxShadow }) =>
    $boxShadow && $boxShadow !== 'none' && theme.landing_box_shadow[$boxShadow]};
`;

const gridStyle = css<StyleProps>`
  grid-column-end: ${({ $gridColumnEnd }) => $gridColumnEnd};
  grid-column-start: ${({ $gridColumnStart }) => $gridColumnStart};
  grid-row-end: ${({ $gridRowEnd }) => $gridRowEnd};
  grid-row-start: ${({ $gridRowStart }) => $gridRowStart};
`;

const overrideStyle = css<StyleProps>`
  background-color: ${({ overrideBackgroundColor }) =>
    overrideBackgroundColor && overrideBackgroundColor};
  border-color: ${({ overrideBorderColor }) => overrideBorderColor && overrideBorderColor};
`;
const mediaQueryStyle = css<StyleProps>`
  /* mobile */
  @media (max-width: 767px) {
    grid-row-end: span 1;
  }
`;

export const S_PartnerCard = styled.div<StyleProps>`
  background-color: ${({ theme, $backgroundColorKey }) =>
    $backgroundColorKey && theme.landing_color[$backgroundColorKey]};
  border-color: ${({ theme, $borderColorKey }) =>
    $borderColorKey && theme.landing_color[$borderColorKey]};
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  border-style: ${({ borderMode }) => (borderMode === 'use' ? 'solid' : 'none')};
  border-width: ${({ $borderWidth }) => `${$borderWidth}px`};
  box-sizing: border-box;
  cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: ${({ theme }) => theme.landing_spacing.spacing_g};
  position: relative;
  width: 100%;

  ${({ $boxShadow }) => $boxShadow !== 'none' && boxShadowStyle};

  ${gridStyle}
  ${overrideStyle}
  ${mediaQueryStyle}
`;

export default PartnerCard;
