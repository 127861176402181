import { Route, Switch } from 'react-router-dom';

import {
  footerSectionPath,
  guidanceSectionPath,
  pricingPath,
  pricingSectionsPath,
  servicePath,
  serviceSectionsPath
} from '@common/routers';
import { Footer, GuidanceSection } from '@common/ui/components';
import PricingSections from '@screens/Pricing/PricingSections';
import ServiceSections from '@screens/Service/ServiceSections';

import './common/i18n';
import { Service, Pricing, Partners } from './screens';

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Service />
        </Route>
        <Route exact path={servicePath}>
          <Service />
        </Route>
        <Route exact path={pricingPath}>
          <Pricing />
        </Route>
        <Route exact path="/partners">
          <Partners />
        </Route>
        <Route path={pricingSectionsPath}>
          <PricingSections />
        </Route>
        <Route path={serviceSectionsPath}>
          <ServiceSections />
        </Route>
        <Route exact path={guidanceSectionPath}>
          <GuidanceSection />
        </Route>
        <Route exact path={footerSectionPath}>
          <Footer />
        </Route>
      </Switch>
    </>
  );
}

export default App;
