import { Section6_1, Section6_2, Section6_3, Section6_4 } from './sections';

function Section6() {
  return (
    <>
      <Section6_1 />
      <Section6_2 />
      <Section6_3 />
      <Section6_4 />
    </>
  );
}

export default Section6;
