import styled, { css } from 'styled-components';

import { ColorTheme } from '@common/types';

import { Icon } from '../Icon';

type ColorType = keyof ColorTheme['landing_color'];

export type SecondaryButtonProps = {
  textColorKey?: ColorType;
  overrideTextColor?: `#${string}`;
  borderColorKey?: ColorType;
  overrideBackgroundColor?: `#${string}`;
  size?: 'large';
  responsiveMode?: 'none' | 'use';
  href?: string;
  target?: string;
  children?: React.ReactNode;
  isInIFrame?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  overrideCSS?: React.CSSProperties;
};

type StyleTypes = {
  isSelected?: boolean;
} & Pick<
  SecondaryButtonProps,
  | 'textColorKey'
  | 'overrideTextColor'
  | 'borderColorKey'
  | 'overrideBackgroundColor'
  | 'size'
  | 'responsiveMode'
>;

function SecondaryButton({
  textColorKey = 'black',
  overrideTextColor,
  borderColorKey = 'black',
  overrideBackgroundColor,
  size = 'large',
  responsiveMode = 'none',
  href,
  target,
  children,
  isInIFrame,
  onClick,
  onMouseDown,
  overrideCSS
}: SecondaryButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onMouseDown) {
      onMouseDown(e);
    }
  };

  function ButtonVariation() {
    if (href) {
      return (
        <S_LinkButton
          rel="noopener noreferrer"
          href={href}
          target={target}
          textColorKey={textColorKey}
          overrideTextColor={overrideTextColor}
          borderColorKey={borderColorKey}
          overrideBackgroundColor={overrideBackgroundColor}
          size={size}
          responsiveMode={responsiveMode}
          style={overrideCSS}
        >
          {children}
          <S_IconWrapper>
            <Icon iconName="ic_arrow_right_long" colorKey={textColorKey} />
          </S_IconWrapper>
        </S_LinkButton>
      );
    }
    return (
      <S_Button
        textColorKey={textColorKey}
        overrideTextColor={overrideTextColor}
        borderColorKey={borderColorKey}
        overrideBackgroundColor={overrideBackgroundColor}
        size={size}
        responsiveMode={responsiveMode}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        style={overrideCSS}
      >
        {children}

        {isInIFrame && (
          <S_IconWrapper>
            <Icon iconName="ic_arrow_right_long" colorKey={textColorKey} />
          </S_IconWrapper>
        )}
      </S_Button>
    );
  }

  return ButtonVariation();
}
const large = css<StyleTypes>`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_c};
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_c};
`;

const overrideStyle = css<StyleTypes>`
  background-color: ${({ overrideBackgroundColor }) =>
    overrideBackgroundColor && overrideBackgroundColor};
  color: ${({ overrideTextColor }) => overrideTextColor && overrideTextColor};
`;

const responsiveStyle = css<StyleTypes>`
  max-width: 100%;
  width: 100%;
`;

const buttonStyle = css<StyleTypes>`
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: ${({ theme, textColorKey }) => textColorKey && theme.landing_color[textColorKey]};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-width: fit-content;
  position: relative;

  &:focus {
    outline: none;
  }

  ${({ size }) => size && { large }[size]}
  ${({ responsiveMode }) => responsiveMode === 'use' && responsiveStyle};
`;

const S_LinkButton = styled.a<StyleTypes>`
  ${buttonStyle}

  ${overrideStyle}
`;

const S_Button = styled.button<StyleTypes>`
  ${buttonStyle}

  ${overrideStyle}
`;

const S_IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: fit-content;
  margin-left: 18px;
  transition: margin-left 0.25s ease-in-out;
  width: fit-content;

  ${S_Button}:hover & {
    margin-left: 32px;
  }
`;

export default SecondaryButton;
