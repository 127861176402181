import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  A00001,
  B00001,
  B00005,
  C00005,
  C00012,
  C00015,
  D00003,
  E00001,
  MobileApp,
  Pages
} from './assets';
import { BlockSheet } from './components';

function Section5() {
  const { t } = useTranslation('translation');

  return (
    <S_ContentBox>
      <S_Title>{t('str_44')}</S_Title>
      <S_Grid>
        <BlockSheet
          titleText={t('str_45')}
          descText={t('str_46')}
          gridColumnStart="auto"
          gridColumnEnd="span 7"
          borderMode="use"
          overrideBackgroundColor="#FF5C56"
        >
          <A00001 />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_47')}
          descText={t('str_48')}
          textColorTheme="grey900"
          gridColumnStart="auto"
          gridColumnEnd="span 5"
          borderMode="use"
          overrideBackgroundColor="#FFCF31"
        >
          <D00003 />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_49')}
          descText={t('str_50')}
          gridColumnStart="auto"
          gridColumnEnd="span 5"
          borderMode="use"
          overrideBackgroundColor="#F244BC"
        >
          <B00005 />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_51')}
          descText={t('str_52')}
          textColorTheme="grey900"
          gridColumnStart="auto"
          gridColumnEnd="span 7"
          borderMode="use"
          overrideBackgroundColor="#70F3CB"
        >
          <C00005 />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_53')}
          descText={t('str_54')}
          gridColumnStart="auto"
          gridColumnEnd="span 7"
          borderMode="use"
          overrideBackgroundColor="#0BC2D4"
        >
          <B00001 />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_55')}
          descText={t('str_56')}
          gridColumnStart="auto"
          gridColumnEnd="span 5"
          gridRowStart="auto"
          gridRowEnd="span 2"
          borderMode="use"
          overrideBackgroundColor="#C24FFF"
        >
          <C00015 />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_57')}
          descText={t('str_58')}
          textColorTheme="grey900"
          gridColumnStart="auto"
          gridColumnEnd="span 7"
          borderMode="use"
          overrideBackgroundColor="#8DCFFF"
        >
          <Pages />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_59')}
          descText={t('str_60')}
          gridColumnStart="auto"
          gridColumnEnd="span 5"
          borderMode="use"
          overrideBackgroundColor="#805DFC"
        >
          <E00001 />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_61')}
          descText={t('str_62')}
          gridColumnStart="auto"
          gridColumnEnd="span 7"
          borderMode="use"
          overrideBackgroundColor="#FF8354"
        >
          <C00012 />
        </BlockSheet>
        <BlockSheet
          titleText={t('str_63')}
          descText={t('str_64')}
          textColorTheme="grey900"
          gridColumnStart="auto"
          gridColumnEnd="span 7"
          borderMode="use"
          overrideBackgroundColor="#C3FB51"
        >
          <MobileApp />
        </BlockSheet>
        <BlockSheet
          gridColumnStart="auto"
          gridColumnEnd="span 5"
          borderMode="use"
          overrideBackgroundColor="#252948"
        >
          <S_LastSheetContentBox>
            <S_LastSheetDesc>{t('str_65')}</S_LastSheetDesc>
            <S_LastSheetTitle>{t('str_66')}</S_LastSheetTitle>
          </S_LastSheetContentBox>
        </BlockSheet>
      </S_Grid>

      <S_Desc>{t('str_67')}</S_Desc>
    </S_ContentBox>
  );
}

const S_ContentBox = styled.div`
  width: 100%;
`;

const S_Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
  text-align: center;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

const S_Desc = styled.p`
  color: ${({ theme }) => theme.landing_color.grey500};
  font-size: ${({ theme }) => theme.landing_font_size.xl4};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  text-align: center;

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl2};
    line-height: ${({ theme }) => theme.landing_line_height.snug};
  }
`;

const S_LastSheetContentBox = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.landing_color.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  transition: all 0.35s ease-in-out;
  width: 100%;

  &:hover {
    color: #3cff02;
  }
`;

const S_LastSheetTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_d};
`;

const S_LastSheetDesc = styled.p`
  font-size: 13.75rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  letter-spacing: -1rem;
  line-height: 1.1;

  /* mobile */
  @media (max-width: 1300px) {
    font-size: 11.875rem;
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: 11.875rem;
  }
`;

const S_Grid = styled.div`
  display: grid;
  flex: none;
  gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-auto-rows: minmax(0, 1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, minmax(480px, 1fr));
  height: min-content;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  position: relative;
  width: 100%;

  /* mobile + tablet */
  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

export default Section5;
