import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { TextType } from '@common/types/text';

import { MainButton } from '../../../../../../common/ui/components';

import type { ColorTheme, EffectTheme } from '../../../../../../common/types';

type ColorType = keyof ColorTheme['landing_color'];
type BoxShadowType = keyof EffectTheme['landing_box_shadow'];

export type ImageSheetProps = {
  titleText?: TextType;
  descText?: TextType;
  textColorTheme?: 'white' | 'black' | 'grey900';
  backgroundColorKey?: ColorType;
  overrideBackgroundColor?: `#${string}`;
  boxShadow?: 'none' | BoxShadowType;
  borderMode?: 'none' | 'use';
  borderWidth?: 1 | 2;
  borderColorKey?: ColorType;
  overrideBorderColor?: `#${string}`;
  gridColumnStart?: string;
  gridColumnEnd?: string;
  gridRowStart?: string;
  gridRowEnd?: string;
  imageSrc?: string;
  imageMode: 'mobile' | 'desktop';
  href?: string;

  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export type StyleProps = {
  textColorTheme?: 'white' | 'black' | 'grey900';
  $backgroundColorKey?: ColorType;
  overrideBackgroundColor?: `#${string}`;
  $boxShadow?: 'none' | BoxShadowType;
  borderMode?: 'none' | 'use';
  $borderWidth?: 1 | 2;
  $borderColorKey?: ColorType;
  overrideBorderColor?: `#${string}`;
  $gridColumnStart?: string;
  $gridColumnEnd?: string;
  $gridRowStart?: string;
  $gridRowEnd?: string;
  hasOnClick?: boolean;
  $href?: string;
  imageMode?: 'mobile' | 'desktop';
};

function ImageSheet({
  titleText,
  descText,
  textColorTheme = 'white',
  backgroundColorKey = 'grey30',
  overrideBackgroundColor,
  boxShadow = 'none',
  borderMode = 'use',
  borderWidth = 1,
  borderColorKey = 'grey100',
  overrideBorderColor,
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  gridRowEnd,
  imageSrc,
  href,
  imageMode,
  onClick
}: ImageSheetProps) {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  function ImageSheetVariation() {
    const { t } = useTranslation('translation');

    return (
      <S_ImageSheet
        $backgroundColorKey={backgroundColorKey}
        $boxShadow={boxShadow}
        overrideBackgroundColor={overrideBackgroundColor}
        borderMode={borderMode}
        $borderWidth={borderWidth}
        $borderColorKey={borderColorKey}
        overrideBorderColor={overrideBorderColor}
        $gridColumnStart={gridColumnStart}
        $gridColumnEnd={gridColumnEnd}
        $gridRowStart={gridRowStart}
        $gridRowEnd={gridRowEnd}
        hasOnClick={!!onClick}
        onClick={handleClick}
      >
        <ImageView
          src={imageSrc}
          // width={imageMode === 'desktop' ? 768 : 282}
          height="responsive"
          shapeType="rectangle"
        />
        <S_ContentBox>
          {(titleText || descText) && (
            <S_TextBox textColorTheme={textColorTheme}>
              {titleText && <S_Title imageMode={imageMode}>{t(`${titleText}`)}</S_Title>}
              {descText && (
                <S_Desc $href={href} imageMode={imageMode}>
                  {t(`${descText}`)}
                </S_Desc>
              )}
            </S_TextBox>
          )}
          {href && (
            <MainButton href={href} target="_blank" backgroundColorKey="white" textColorKey="black">
              {t('str_93')}
            </MainButton>
          )}
        </S_ContentBox>
      </S_ImageSheet>
    );
  }
  return ImageSheetVariation();
}

const S_TextBox = styled.div<StyleProps>`
  text-align: center;
  width: 80%;

  ${({ theme, textColorTheme }) =>
    textColorTheme &&
    {
      white: `color: ${theme.landing_color.white}`,
      black: `color: ${theme.landing_color.black}`,
      grey900: `color: ${theme.landing_color.grey900}`
    }[textColorTheme]};
`;

const S_Title = styled.h3<StyleProps>`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_d};

  ${({ theme, imageMode }) =>
    imageMode === 'mobile' && `font-size: ${theme.landing_font_size.xl2}`};
`;

const S_Desc = styled.p<StyleProps>`
  color: ${({ theme }) => theme.landing_color.grey400};
  margin-bottom: ${({ theme, $href }) => ($href ? theme.landing_spacing.spacing_g : 0)};

  ${({ theme, imageMode }) => imageMode === 'mobile' && `font-size: ${theme.landing_font_size.lg}`};
`;

const boxShadowStyle = css<StyleProps>`
  box-shadow: ${({ theme, $boxShadow }) =>
    $boxShadow && $boxShadow !== 'none' && theme.landing_box_shadow[$boxShadow]};
`;

const gridStyle = css<StyleProps>`
  grid-column-end: ${({ $gridColumnEnd }) => $gridColumnEnd};
  grid-column-start: ${({ $gridColumnStart }) => $gridColumnStart};
  grid-row-end: ${({ $gridRowEnd }) => $gridRowEnd};
  grid-row-start: ${({ $gridRowStart }) => $gridRowStart};
`;

const overrideStyle = css<StyleProps>`
  background-color: ${({ overrideBackgroundColor }) =>
    overrideBackgroundColor && overrideBackgroundColor};
  border-color: ${({ overrideBorderColor }) => overrideBorderColor && overrideBorderColor};
`;

const mediaQueryStyle = css<StyleProps>`
  /* mobile */
  @media (max-width: 767px) {
    grid-row-end: span 1;
    height: 240px;
  }
`;

export const S_ImageSheet = styled.div<StyleProps>`
  background-color: ${({ theme, $backgroundColorKey }) =>
    $backgroundColorKey && theme.landing_color[$backgroundColorKey]};
  border-color: ${({ theme, $borderColorKey }) =>
    $borderColorKey && theme.landing_color[$borderColorKey]};
  border-radius: ${({ theme }) => theme.landing_border_radius.lg};
  border-style: ${({ borderMode }) => (borderMode === 'use' ? 'solid' : 'none')};
  border-width: ${({ $borderWidth }) => `${$borderWidth}px`};
  box-sizing: border-box;
  cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : 'default')};
  height: 480px;
  margin-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  overflow: hidden;
  position: relative;

  ${({ $boxShadow }) => $boxShadow !== 'none' && boxShadowStyle};

  ${gridStyle}
  ${overrideStyle}
  ${mediaQueryStyle}

  & img {
    object-position: top;
  }
`;

const S_ContentBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.landing_color.black}${({ theme }) => theme.landing_opacity.opacity60};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.35s ease-in-out;
  width: 100%;
  @media (min-width: 768px) {
    ${S_ImageSheet}:hover & {
      opacity: 1;
    }
  }
`;

export default ImageSheet;
