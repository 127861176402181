import { Route, Switch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  addOnSectionPath,
  examplesSectionPath,
  featuresSectionPath,
  mainSectionPath,
  monetizeMethodSectionPath,
  monetizeSectionPath,
  noCodeSectionPath,
  paymentSectionPath,
  trustedBySectionPath
} from '@common/routers';

import {
  Section1,
  Section10,
  Section3,
  Section4,
  Section5,
  Section7,
  Section8,
  Section9
} from './sections';
import SeparateSection6 from './sections/Section6/SeparateSection6';

function ServiceSections() {
  return (
    <S_Main>
      <Switch>
        <Route exact path={mainSectionPath}>
          <Section1Background>
            <S_Section1Wrapper>
              <Section1 />
            </S_Section1Wrapper>
          </Section1Background>
        </Route>
        <Route exact path={trustedBySectionPath}>
          <Section10Background>
            <S_Section10Wrapper>
              <Section10 />
            </S_Section10Wrapper>
          </Section10Background>
        </Route>
        <Route exact path={noCodeSectionPath}>
          <Section9Background>
            <S_Section9Wrapper>
              <Section9 />
            </S_Section9Wrapper>
          </Section9Background>
        </Route>
        <Route exact path={monetizeSectionPath}>
          <Section3Background>
            <S_Section3Wrapper>
              <Section3 />
            </S_Section3Wrapper>
          </Section3Background>
        </Route>
        <Route exact path={addOnSectionPath}>
          <Section4Background>
            <S_Section4Wrapper>
              <Section4 />
            </S_Section4Wrapper>
          </Section4Background>
        </Route>
        <Route exact path={featuresSectionPath}>
          <Section5Background>
            <S_Section5Wrapper>
              <Section5 />
            </S_Section5Wrapper>
          </Section5Background>
        </Route>
        <Route path={monetizeMethodSectionPath}>
          <Section6Background>
            <S_Section6Wrapper>
              <SeparateSection6 />
            </S_Section6Wrapper>
          </Section6Background>
        </Route>
        <Route exact path={paymentSectionPath}>
          <Section7Background>
            <S_Section7Wrapper>
              <Section7 />
            </S_Section7Wrapper>
          </Section7Background>
        </Route>
        <Route exact path={examplesSectionPath}>
          <Section8Background>
            <S_Section8Wrapper>
              <Section8 />
            </S_Section8Wrapper>
          </Section8Background>
        </Route>
      </Switch>
    </S_Main>
  );
}

const backgroundStyle = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  /* mobile */
  @media (max-width: 767px) {
    overflow: hidden;
    clip-path: inset(0 0 0 0);
  }
`;

const wrapperStyle = css`
  max-width: 1440px;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_k};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: 200px;
  position: relative;
  width: 100%;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_l};
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_l};
  }

  /* mobile */
  @media (max-width: 767px) {
    padding-bottom: 56px;
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-top: 56px;
  }
`;

const S_Main = styled.div``;

const Section1Background = styled.div`
  ${backgroundStyle}

  /* mobile */
  @media (max-width: 767px) {
    overflow: visible;
    height: 100vh;
    height: fill-available;
    justify-content: center;
  }
`;

const S_Section1Wrapper = styled.div`
  ${wrapperStyle}

  padding-bottom: 0;
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: 0;
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_j};
  }
`;

const Section3Background = styled.div`
  ${backgroundStyle}
`;

const S_Section3Wrapper = styled.div`
  ${wrapperStyle}

  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_n};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_m};
`;

const Section4Background = styled.div`
  ${backgroundStyle}
`;

const S_Section4Wrapper = styled.div`
  ${wrapperStyle}
`;

const Section5Background = styled.div`
  ${backgroundStyle}
`;

const S_Section5Wrapper = styled.div`
  ${wrapperStyle}

  align-items: center;
  display: flex;
  justify-content: center;
`;

const Section6Background = styled.div`
  ${backgroundStyle}
`;

const S_Section6Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Section7Background = styled.div`
  ${backgroundStyle}
`;

const S_Section7Wrapper = styled.div`
  ${wrapperStyle}

  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Section8Background = styled.div`
  ${backgroundStyle}
`;

const S_Section8Wrapper = styled.div`
  ${wrapperStyle}

  align-items: center;
  display: flex;
  justify-content: center;
  max-width: unset;
  padding-left: unset;
  padding-right: unset;
  width: 100%;

  /* mobile + tablet */
  @media (max-width: 1023px) {
    padding-left: unset;
    padding-right: unset;
  }
`;

const Section9Background = styled.div`
  ${backgroundStyle}

  clip-path: inset(0 0 0 0);
  overflow: hidden;
`;

const S_Section9Wrapper = styled.div`
  ${wrapperStyle}

  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_m};

  /* mobile */
  @media (max-width: 767px) {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  }
`;

const Section10Background = styled.div`
  ${backgroundStyle}
`;

const S_Section10Wrapper = styled.div`
  ${wrapperStyle}

  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};
`;

export default ServiceSections;
