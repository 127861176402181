import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import resources from './resources';

const availableLanguages = ['ko'];

i18n
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    fallbackLng: { default: ['ko'] },
    resources,
    supportedLngs: availableLanguages,
    nonExplicitSupportedLngs: true,
    lowerCaseLng: true
  });

// override getSuffix method to support plurals in 'ko'
const originalGetSuffix = i18n.services.pluralResolver.getSuffix;
i18n.services.pluralResolver.getSuffix = (code: string, count: number, ...other: any[]) => {
  if (code === 'ko') {
    return count > 1 ? '_plural' : '';
  }

  return originalGetSuffix.call(i18n.services.pluralResolver, code, count, ...other);
};

export default i18n;
