import type IconType from '../IconType';

function Minus({ size, ...rest }: IconType) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" {...rest}>
      <rect
        transform="rotate(90 8.75 16.75)"
        x="14.5"
        y="-14.5"
        width="3"
        height="32"
        rx="1.5"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Minus;
