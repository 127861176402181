import styled from 'styled-components';

type SwitchProps = {
  name: string;
  isSwitchOn: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function Switch({ name, isSwitchOn, onChange }: SwitchProps) {
  return (
    <S_Switch>
      <S_Input type="checkbox" hidden id={name} checked={isSwitchOn} onChange={onChange} />
      <S_Label htmlFor={name} />
    </S_Switch>
  );
}

const S_Switch = styled.div`
  height: 40px;
  position: relative;
  width: 80px;
`;

const S_Label = styled.label`
  background: ${({ theme }) => theme.landing_color.blue500};
  border-radius: 24px;
  cursor: pointer;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;

  &::after {
    background: ${({ theme }) => theme.landing_color.white};
    border-radius: 24px;
    content: '';
    display: block;
    height: 32px;
    margin: ${({ theme }) => theme.landing_spacing.spacing_a};
    width: 32px;
  }
`;

const S_Input = styled.input`
  &:checked + ${S_Label} {
    background: ${({ theme }) => theme.landing_color.blue500};

    &::after {
      margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_a};
      margin-right: ${({ theme }) => theme.landing_spacing.spacing_a};
      margin-top: ${({ theme }) => theme.landing_spacing.spacing_a};
      position: absolute;
      right: 0;
    }
  }
`;

export default Switch;
