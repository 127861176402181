import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

import { SecondaryPAButton } from '@common/ui/components';

function Section3() {
  const { t } = useTranslation('translation');

  return (
    <S_ContentBox>
      <S_LeftSide>
        <S_TextBox>
          <S_Title>
            {t('str_32')}
            <S_TextEmphasis>{t('str_33')}</S_TextEmphasis>
            {t('str_34')}
            <S_TextEmphasis>{t('str_35')}</S_TextEmphasis>
            {t('str_36')}
          </S_Title>
          <S_Desc>{t('str_37')}</S_Desc>
        </S_TextBox>
        <SecondaryPAButton textColorKey="white" borderColorKey="white">
          {t('str_38')}
        </SecondaryPAButton>
      </S_LeftSide>
      <S_RightSide>
        <S_ContentsCard1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section3/img_landing_bm_transparent_05.png"
            width="responsive"
            ratio="1_1"
            scaleType="cover"
          />
        </S_ContentsCard1>
        <S_ContentsCard2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section3/img_landing_bm_transparent_03.png"
            width="responsive"
          />
        </S_ContentsCard2>
        <S_ContentsCard3>
          <ImageView
            src="https://static.publ.site/landing_page/service/section3/img_landing_bm_transparent_04.png"
            width="responsive"
          />
        </S_ContentsCard3>
        <S_ContentsCard4>
          <ImageView
            src="https://static.publ.site/landing_page/service/section3/img_landing_bm_transparent_01.png"
            width="responsive"
          />
        </S_ContentsCard4>
        <S_ContentsCard5>
          <ImageView
            src="https://static.publ.site/landing_page/service/section3/img_landing_bm_transparent_02.png"
            width="responsive"
          />
        </S_ContentsCard5>
      </S_RightSide>
    </S_ContentBox>
  );
}

const ContentsCardStyle = css`
  align-items: center;
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  display: flex;
  justify-content: center;
  position: absolute;
  transition: all 1s ease-in-out;
  width: 55%;

  /* mobile */
  @media (max-width: 767px) {
    width: 35%;
  }

  @media (max-width: 500px) {
    width: 40%;
  }

  @media (max-width: 400px) {
    width: 50%;
  }

  @media (max-width: 330px) {
    width: 60%;
  }
`;

const S_ContentBox = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(1, 1fr);

  /* mobile */
  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const S_LeftSide = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column-end: span 8;

  /* mobile */
  @media (max-width: 767px) {
    grid-column-end: span 4;
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  }
`;

const S_RightSide = styled.div`
  grid-column-end: span 4;
  position: relative;

  /* mobile */
  @media (max-width: 767px) {
    grid-column-end: span 4;
    height: 600px;
    margin: 0 12px 0 48px;
  }

  @media (max-width: 500px) {
    height: 400px;
  }

  @media (max-width: 400px) {
    height: 350px;
  }
`;

export const fadeOut = keyframes`
    from { opacity: 1; }
    to { opacity: 0.7; }
`;

export const translate1 = keyframes`
  from {
    right: 60%;
    top: 60%;
    z-index: 50;
  }

  5% {
    opacity: 1;
    z-index: 50;
  }

  8% {
    transform: scale(100%);
    opacity: 1;
    z-index: 50;
  }

  10% {
    right: 60%;
    top: 60%;
    transform: scale(50%);
    transform-origin: bottom left;
    opacity: 0;
    z-index: 50;
  }

  17% {
    right: 0;
    top: 0;
    transform: scale(90%);
    opacity: 0;
    z-index: 10;
  }

  20% {
    right: 0;
    top: 0;
    transform: scale(100%);
    opacity: 1;
  }

  40% {
    right: 15%;
    top: 15%;
    z-index: 20;
  }

  60% {
    right: 30%;
    top: 30%;
    z-index: 30;
  }

  80% {
    right: 45%;
    top: 45%;
    z-index: 40;
  }

  to {
    right: 60%;
    top: 60%;
    z-index: 50;
  }
`;

export const translate2 = keyframes`
  from {
    right: 45%;
    top: 45%;
    z-index: 40;
  }

  20% {
    right: 60%;
    top: 60%;
    z-index: 50;
  }

  25% {
    opacity: 1;
    z-index: 50;
  }
  
  28% {
    transform: scale(100%);
    opacity: 1;
    z-index: 50;
  }
  

  30% {
    right: 60%;
    top: 60%;
    transform: scale(50%);
    transform-origin: bottom left;
    opacity: 0;
    z-index: 50;
  }

  37% {
    right: 0;
    top: 0;
    transform: scale(90%);
    opacity: 0;
    z-index: 10;
  }

  40% {
    right: 0;
    top: 0;
    transform: scale(100%);
    opacity: 1;
  }

  60% {
    right: 15%;
    top: 15%;
    z-index: 20;
  }

  80% {
    right: 30%;
    top: 30%;
    z-index: 30;
  }

  to {
    right: 45%;
    top: 45%;
    z-index: 40;
  }
`;

export const translate3 = keyframes`
  from {
    right: 30%;
    top: 30%;
    z-index: 30;
  }

  20% {
    right: 45%;
    top: 45%;
    z-index: 40;
  }

  40% {
    right: 60%;
    top: 60%;
    z-index: 50;
  }

  45% {
    opacity: 1;
    z-index: 50;
  }

  48% {
    transform: scale(100%);
    opacity: 1;
    z-index: 50;
  }


  50% {
    right: 60%;
    top: 60%;
    transform: scale(50%);
    transform-origin: bottom left;
    opacity: 0;
    z-index: 50;
  }

  57% {
    right: 0;
    top: 0;
    transform: scale(90%);
    opacity: 0;
    z-index: 10;
  }

  60% {
    right: 0;
    top: 0;
    transform: scale(100%);
    opacity: 1;
  }

  80% {
    right: 15%;
    top: 15%;
    z-index: 20;
  }

  to {
    right: 30%;
    top: 30%;
    z-index: 30;
  }
`;

export const translate5 = keyframes`
  from {
    right: 0;
    top: 0;
    transform: scale(100%);
    opacity: 1;
  }

  20% {
    right: 15%;
    top: 15%;
    z-index: 20;
  }

  40% {
    right: 30%;
    top: 30%;
    z-index: 30;
  }

  60% {
    right: 45%;
    top: 45%;
    z-index: 40;
  }

  80% {
    right: 60%;
    top: 60%;
    z-index: 50;
  }

  85% {
    opacity: 1;
    z-index: 50;
  }

  88% {
    transform: scale(100%);
    opacity: 1;
    z-index: 50;
  }

  90% {
    right: 60%;
    top: 60%;
    transform: scale(50%);
    transform-origin: bottom left;
    opacity: 0;
    z-index: 50;
  }

  97% {
    right: 0;
    top: 0;
    transform: scale(90%);
    opacity: 0;
    z-index: 10;
  }

  to {
    right: 0;
    top: 0;
    transform: scale(100%);
    opacity: 1;
  }
`;

export const translate4 = keyframes`
  from {
    right: 15%;
    top: 15%;
    z-index: 20;
  }

  20% {
    right: 30%;
    top: 30%;
    z-index: 30;
  }

  40% {
    right: 45%;
    top: 45%;
    z-index: 40;
  }

  60% {
    right: 60%;
    top: 60%;
    z-index: 50;
  }

  65% {
    opacity: 1;
    z-index: 50;
  }

  68% {
    transform: scale(100%);
    opacity: 1;
    z-index: 50;
  }

  70% {
    right: 60%;
    top: 60%;
    transform: scale(50%);
    transform-origin: bottom left;
    opacity: 0;
    z-index: 50;
  }

  77% {
    right: 0;
    top: 0;
    transform: scale(90%);
    opacity: 0;
    z-index: 10;
  }

  80% {
    right: 0;
    top: 0;
    transform: scale(100%);
    opacity: 1;
  }

  to {
    right: 15%;
    top: 15%;
    z-index: 20;
  }
`;

export const color = keyframes`
  from {
    color: #6b36ff;
  }

  20% {
    color: #5eff55;
  }

  40% {
    color: #ff55fb;
  }

  60% {
    color: #ffef2c;
  }

  80% {
    color: #5ceef2;
  }

  to {
    color: #6b36ff;
  }
`;

export const CardAni1 = css`
  animation: ${translate1} 7s infinite cubic-bezier(0.51, -0.15, 0.46, 1.11);
`;

export const CardAni2 = css`
  animation: ${translate2} 7s infinite cubic-bezier(0.51, -0.15, 0.46, 1.11);
`;

export const CardAni3 = css`
  animation: ${translate3} 7s infinite cubic-bezier(0.51, -0.15, 0.46, 1.11);
`;

export const CardAni4 = css`
  animation: ${translate4} 7s infinite cubic-bezier(0.51, -0.15, 0.46, 1.11);
`;

export const CardAni5 = css`
  animation: ${translate5} 7s infinite cubic-bezier(0.51, -0.15, 0.46, 1.11);
`;

export const TextColorAni = css`
  animation: ${color} 7s infinite ease-in-out;
`;

export const CardTextStyle = css`
  color: ${({ theme }) => theme.landing_color.grey400};
  display: none;
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.landing_font_size.xl7};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 110%;
  text-align: right;
  transition: opacity 0.35s ease-in-out;
  width: 100%;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl6};
  }

  /* mobile */
  @media (max-width: 767px) {
    right: unset;
    text-align: center;
    bottom: -50px;
    justify-content: flex-end;
    font-size: ${({ theme }) => theme.landing_font_size.xl3};
  }
`;

const S_ContentsCard1 = styled.div`
  ${ContentsCardStyle}

  background-color: #6b36ff;
  right: 60%;
  top: 60%;
  z-index: 50;

  ${CardAni1}
`;

const S_ContentsCard2 = styled.div`
  ${ContentsCardStyle}

  background-color: #5eff55;
  right: 45%;
  top: 45%;
  z-index: 40;

  ${CardAni2}
`;

const S_ContentsCard3 = styled.div`
  ${ContentsCardStyle}

  background-color: #ff55fb;
  right: 30%;
  top: 30%;
  z-index: 30;

  ${CardAni3}
`;

const S_ContentsCard4 = styled.div`
  ${ContentsCardStyle}

  background-color: #ffef2c;
  right: 15%;
  top: 15%;
  z-index: 20;

  ${CardAni4}
`;

const S_ContentsCard5 = styled.div`
  ${ContentsCardStyle}

  background-color: #5ceef2;
  right: 0;
  top: 0;
  z-index: 10;

  ${CardAni5}
`;

const S_TextBox = styled.div`
  color: ${({ theme }) => theme.landing_color.white};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  text-align: left;
`;

const S_Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }
`;

const S_Desc = styled.p`
  color: #a5a5ab;
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
`;

const S_TextEmphasis = styled.strong`
  color: ${({ theme }) => theme.landing_color.skyblue300};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};

  ${TextColorAni}
`;

export default Section3;
