import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

type CardPropTypes = {
  tagline: string;
  mainText: JSX.Element;
  description: string;
  caption?: string;
  color: string;
  button: React.ReactNode;
};

function Card({ tagline, mainText, description, caption, button, color }: CardPropTypes) {
  return (
    <CardWrapper $backgroundColor={color}>
      <Contents>
        <Tagline>{tagline}</Tagline>
        {mainText}
        {description && <Description>{description}</Description>}
      </Contents>
      <BottomBox>
        {caption && <Caption>{caption}</Caption>}
        <ButtonWrapper $paddingTop={caption ? 16 : 0}>{button}</ButtonWrapper>
      </BottomBox>
    </CardWrapper>
  );
}

const CardWrapper = styled.div<{
  $backgroundColor: string;
}>`
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: 40px;

  @media ${device.mobile} {
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

const Contents = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Tagline = styled.span`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
  text-align: center;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.landing_color.grey600};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_e};
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl};
  }
`;

const Caption = styled.div`
  color: ${({ theme }) => theme.landing_color.grey500};
  font-size: ${({ theme }) => theme.landing_font_size.xs};
  text-align: center;
  word-break: keep-all;
`;

const BottomBox = styled.div`
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_g};
`;

const ButtonWrapper = styled.div<{
  $paddingTop: number;
}>`
  padding-top: ${({ $paddingTop }) => $paddingTop}px;
  width: 100%;
`;

export default Card;
