import ic_arrow_right_long from './ArrowRightLong';
import ic_check from './Check';
import ic_minus from './Minus';
import ic_plus from './Plus';

const lineIcons = {
  ic_arrow_right_long,
  ic_check,
  ic_plus,
  ic_minus
} as const;

export default lineIcons;
