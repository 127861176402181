import styled from 'styled-components';

import { VideoPlayerProps } from '@common/types/videoPlayer';

import { VideojsRenderer } from '.';

function VideoPlayer({ ...args }: VideoPlayerProps) {
  const playerRef = VideojsRenderer({ ...args });

  return (
    <S_VideoPlayerWrapper>
      <S_Player
        ref={playerRef}
        className="video-js"
        controls={false}
        crossOrigin="anonymous"
        playsInline
        autoPlay
        muted
      />
    </S_VideoPlayerWrapper>
  );
}

export default VideoPlayer;

const S_VideoPlayerWrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const S_Player = styled.video`
  outline: none;
  pointer-events: none;

  video:focus {
    outline: none;
  }
`;
