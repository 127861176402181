import { CONSOLE_PUBL_BIZ_LINK, REQUEST_REDIRECT_TO_CONSOLE } from '@common/constants/hardcoded';

import SecondaryButton, { SecondaryButtonProps } from '../SecondaryButton/SecondaryButton';

function SecondaryPAButton({ children, ...rest }: SecondaryButtonProps) {
  const isInIFrame = window.location !== window.parent.location;

  const handleHrefButtonClick = () => {
    window.parent.postMessage({ message: REQUEST_REDIRECT_TO_CONSOLE }, '*');
  };

  return (
    <SecondaryButton
      href={isInIFrame ? undefined : CONSOLE_PUBL_BIZ_LINK}
      onClick={handleHrefButtonClick}
      isInIFrame={isInIFrame}
      {...rest}
    >
      {children}
    </SecondaryButton>
  );
}

export default SecondaryPAButton;
