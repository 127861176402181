import { Icon } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ColorTheme } from '@common/types';
import { PAButton } from '@common/ui/components';
import { device } from '@common/ui/styles/media';

import { PaymentMethods, PAYMENT_METHODS, PLANS } from '../types';

import { Card } from './Card';
import { plans } from './plans';
import Switch from './Switch';

type PlanSectionPropTypes = {
  paymentMethod: PaymentMethods;
  onChangeSwitch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickAnchor: () => void;
};

function PlansSection({ paymentMethod, onChangeSwitch, onClickAnchor }: PlanSectionPropTypes) {
  const { t } = useTranslation('translation');

  return (
    <PlansSectionLayout>
      <PageTitleBox>
        <PageMainTitle>{t('str_132')}</PageMainTitle>
        <PageSubTitle>{t('str_133')}</PageSubTitle>
        <PaymentMethodSwitchBox>
          <PaymentMethod $color={paymentMethod === PAYMENT_METHODS.MONTHLY ? 'blue500' : 'grey400'}>
            {t('str_134')}
          </PaymentMethod>
          <Switch
            name="payment-method-selector"
            isSwitchOn={paymentMethod === PAYMENT_METHODS.ANNUAL}
            onChange={onChangeSwitch}
          />
          <PaymentMethod $color={paymentMethod === PAYMENT_METHODS.ANNUAL ? 'blue500' : 'grey400'}>
            {t('str_135')}
          </PaymentMethod>
        </PaymentMethodSwitchBox>
      </PageTitleBox>
      <CardList>
        {plans.map((plan) => (
          <Card
            key={plan.type}
            tagline={t(plan.type)}
            mainText={
              paymentMethod === PAYMENT_METHODS.MONTHLY ? (
                <MainTextWrapper>
                  <MainText isPrimaryColor={plan.type === PLANS.ESSENTIAL}>
                    {t(plan.fee.monthly)}
                  </MainText>
                  {plan.type !== PLANS.TRIAL && (
                    <MainSubText isPrimaryColor={plan.type === PLANS.ESSENTIAL}>
                      {t('str_146')}
                    </MainSubText>
                  )}
                </MainTextWrapper>
              ) : (
                <MainTextWrapper>
                  <MainText isPrimaryColor={plan.type === PLANS.ESSENTIAL}>
                    {t(plan.fee.annual)}
                  </MainText>
                  {plan.type !== PLANS.TRIAL && (
                    <MainSubText isPrimaryColor={plan.type === PLANS.ESSENTIAL}>
                      {t('str_146')}
                    </MainSubText>
                  )}
                </MainTextWrapper>
              )
            }
            description={t(plan.description)}
            caption={t(plan.caption)}
            button={
              <PAButton
                size="large"
                responsiveMode="use"
                backgroundColorKey={plan.type === PLANS.ESSENTIAL ? 'blue500' : 'black'}
              >
                {t(plan.buttonText)}
              </PAButton>
            }
            checkList={plan.checkList}
            annotation={t(plan.annotation)}
          />
        ))}
      </CardList>
      <FeatureTableNavBox>
        <Anchor onClick={onClickAnchor}>
          <Label>{t('str_180')}</Label>
          <Icon iconName="ic_arrow_down" />
        </Anchor>
      </FeatureTableNavBox>
    </PlansSectionLayout>
  );
}

const PlansSectionLayout = styled.div`
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_j};
  width: 100%;

  @media ${device.mobile} {
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

const PageTitleBox = styled.div`
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
`;

const PageMainTitle = styled.h1`
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
  text-align: center;

  /* tablet */
  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.landing_font_size.xl9};
  }

  /* mobile */
  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl9};
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_c};
  }
`;

const PageSubTitle = styled.div`
  box-sizing: border-box;
  color: ${({ theme }) => theme.landing_color.grey500};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  overflow-wrap: break-word;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
  word-break: keep-all;

  @media ${device.mobile} {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

const PaymentMethodSwitchBox = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.landing_spacing.spacing_c};
  justify-content: center;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl};
  }
`;

const PaymentMethod = styled.span<{
  $color: keyof ColorTheme['landing_color'];
}>`
  color: ${({ theme, $color }) => theme.landing_color[$color]};
  font-size: ${({ theme }) => theme.landing_font_size.xl3};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
`;

const CardList = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

const MainTextWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.landing_spacing.spacing_a};
`;

const MainText = styled.span<{
  isPrimaryColor: boolean;
}>`
  color: ${({ isPrimaryColor, theme }) =>
    isPrimaryColor ? theme.landing_color.blue500 : theme.landing_color.black};
  font-size: ${({ theme }) => theme.landing_font_size.xl7};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
`;

const MainSubText = styled.span<{
  isPrimaryColor: boolean;
}>`
  color: ${({ isPrimaryColor, theme }) =>
    isPrimaryColor ? theme.landing_color.blue500 : theme.landing_color.black};
  font-size: ${({ theme }) => theme.landing_font_size.xl5};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  white-space: nowrap;
  word-break: keep-all;
`;

const FeatureTableNavBox = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  width: 100%;
`;

const Anchor = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.landing_color.grey600};
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_b};
`;

export default PlansSection;
