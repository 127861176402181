import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

import { PAButton } from '../PAButton';

function GuidanceSection() {
  const { t } = useTranslation('translation');

  return (
    <GuidanceSectionLayout>
      <SectionTitle>{t('str_1')}</SectionTitle>
      <Description>{t('str_2')}</Description>
      <PAButton size="large" backgroundColorKey="black" textColorKey="white">
        {t('str_3')}
      </PAButton>
    </GuidanceSectionLayout>
  );
}

const GuidanceSectionLayout = styled.div`
  align-items: center;
  background-image: url(https://static.publ.site/landing_page/common/guidance_section/img_bg_guidance_section.jpg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_k};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};

  /* mobile */
  @media (max-width: 767px) {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_i};
  }
`;

const SectionTitle = styled.h2`
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  text-align: center;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;
`;

export default GuidanceSection;
