import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SecondaryPAButton } from '@common/ui/components';

function Section4() {
  const { t } = useTranslation('translation');

  return (
    <>
      <S_ContentBox>
        <S_GridItem1>
          <S_Title>{t('str_40')}</S_Title>
        </S_GridItem1>
        <S_GridItem2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section4/img_block_body_02.png"
            width="responsive"
          />
        </S_GridItem2>
        <S_GridItem3>
          <S_Desc>{t('str_41')}</S_Desc>
          <SecondaryPAButton>{t('str_42')}</SecondaryPAButton>
        </S_GridItem3>
      </S_ContentBox>
    </>
  );
}

const S_ContentBox = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-template-columns: repeat(12, 1fr);
  position: relative;

  /* mobile */
  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
    gap: ${({ theme }) => theme.landing_spacing.spacing_f};
  }
`;

const S_GridItem1 = styled.div`
  display: flex;
  grid-column: 1 / 6;
  grid-row: 1 / 1;
  justify-content: flex-start;

  /* mobile */
  @media (max-width: 767px) {
    grid-column: 1 / 5;
  }
`;

const S_GridItem2 = styled.div`
  display: flex;
  grid-column: 4 / 10;
  grid-row: 2 / 2;
  justify-content: center;
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_b};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_b};

  /* mobile */
  @media (max-width: 767px) {
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_g};
    grid-row: 3 / 3;
    grid-column: 1 / 5;
  }
`;

const S_GridItem3 = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 9 / 13;
  grid-row: 3 / 3;
  justify-content: flex-start;

  /* mobile */
  @media (max-width: 767px) {
    grid-column: 1 / 5;
    grid-row: 2 / 2;
  }
`;

const S_Title = styled.h2`
  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }
`;

const S_Desc = styled.p`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
`;

export default Section4;
