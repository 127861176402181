import type IconType from '../IconType';

function Plus({ size, ...rest }: IconType) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" {...rest}>
      <g transform="translate(8 8)" fill="#000" fillRule="evenodd">
        <rect x="14.5" width="3" height="32" rx="1.5" />
        <rect transform="rotate(90 16 16)" x="14.5" width="3" height="32" rx="1.5" />
      </g>
    </svg>
  );
}

export default Plus;
