import type IconType from '../IconType';

function CircleCheck({ size, ...rest }: IconType) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" {...rest}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M28 7C16.402 7 7 16.401 7 28s9.403 21 21 21c11.598 0 21-9.402 21-21S39.597 7 28 7"
          fill="#1EEBB4"
        />
        <path
          d="m39.283 22.252-12.1 15.855a1.75 1.75 0 0 1-1.337.688h-.054c-.509 0-.992-.219-1.325-.606l-7.684-8.914a1.751 1.751 0 0 1 2.65-2.286l6.277 7.28 10.792-14.14a1.75 1.75 0 0 1 2.781 2.123"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default CircleCheck;
