import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function Pages() {
  return (
    <S_Wrapper>
      <S_ImageWrapper1>
        <ImageView
          src="https://static.publ.site/landing_page/service/section5/img_landing_papp_pages_main_01.png"
          width={600}
        />
      </S_ImageWrapper1>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_ImageWrapper1 = styled.div`
  bottom: -100px;
  position: absolute;
  right: calc(50% - 300px);
  transform: scale(90%);
  transform-origin: bottom;
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    transform: scale(100%);
  }

  @media ${device.mobile} {
    transform: scale(100%);
  }
`;

export default Pages;
