import styled, { css } from 'styled-components';

import { TextType } from '@common/types/text';

import type { ColorTheme, EffectTheme } from '@common/types';

type ColorType = keyof ColorTheme['landing_color'];
type BoxShadowType = keyof EffectTheme['landing_box_shadow'];

export type BlockSheetProps = {
  titleText?: TextType;
  descText?: TextType;
  textColorTheme?: 'white' | 'black' | 'grey900';
  backgroundColorKey?: ColorType;
  overrideBackgroundColor?: `#${string}`;
  boxShadow?: 'none' | BoxShadowType;
  borderMode?: 'none' | 'use';
  borderWidth?: 1 | 2;
  borderColorKey?: ColorType;
  overrideBorderColor?: `#${string}`;
  gridColumnStart?: string;
  gridColumnEnd?: string;
  gridRowStart?: string;
  gridRowEnd?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export type StyleProps = {
  textColorTheme?: 'white' | 'black' | 'grey900';
  $backgroundColorKey?: ColorType;
  overrideBackgroundColor?: `#${string}`;
  $boxShadow?: 'none' | BoxShadowType;
  borderMode?: 'none' | 'use';
  $borderWidth?: 1 | 2;
  $borderColorKey?: ColorType;
  overrideBorderColor?: `#${string}`;
  $gridColumnStart?: string;
  $gridColumnEnd?: string;
  $gridRowStart?: string;
  $gridRowEnd?: string;
  hasOnClick?: boolean;
};

function BlockSheet({
  titleText,
  descText,
  textColorTheme = 'white',
  backgroundColorKey = 'grey30',
  overrideBackgroundColor,
  boxShadow = 'none',
  borderMode = 'none',
  borderWidth = 1,
  borderColorKey = 'grey100',
  overrideBorderColor,
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  gridRowEnd,
  children,
  onClick
}: BlockSheetProps) {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  function BlockSheetVariation() {
    return (
      <S_BlockSheet
        $backgroundColorKey={backgroundColorKey}
        $boxShadow={boxShadow}
        overrideBackgroundColor={overrideBackgroundColor}
        borderMode={borderMode}
        $borderWidth={borderWidth}
        $borderColorKey={borderColorKey}
        overrideBorderColor={overrideBorderColor}
        $gridColumnStart={gridColumnStart}
        $gridColumnEnd={gridColumnEnd}
        $gridRowStart={gridRowStart}
        $gridRowEnd={gridRowEnd}
        onClick={handleClick}
        hasOnClick={!!onClick}
      >
        {(titleText || descText) && (
          <S_TextBox textColorTheme={textColorTheme}>
            <S_Title>{`${titleText}`}</S_Title>
            <S_Desc>{`${descText}`}</S_Desc>
          </S_TextBox>
        )}
        <S_ContentBox>{children}</S_ContentBox>
      </S_BlockSheet>
    );
  }
  return BlockSheetVariation();
}

const S_TextBox = styled.div<StyleProps>`
  text-align: left;

  ${({ theme, textColorTheme }) =>
    textColorTheme &&
    {
      white: `color: ${theme.landing_color.white}`,
      black: `color: ${theme.landing_color.black}`,
      grey900: `color: ${theme.landing_color.grey900}`
    }[textColorTheme]};
`;

const S_Title = styled.h3`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
`;

const S_Desc = styled.p`
  text-align: left;
`;

const S_ContentBox = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const boxShadowStyle = css<StyleProps>`
  box-shadow: ${({ theme, $boxShadow }) =>
    $boxShadow && $boxShadow !== 'none' && theme.landing_box_shadow[$boxShadow]};
`;

const gridStyle = css<StyleProps>`
  grid-column-end: ${({ $gridColumnEnd }) => $gridColumnEnd};
  grid-column-start: ${({ $gridColumnStart }) => $gridColumnStart};
  grid-row-end: ${({ $gridRowEnd }) => $gridRowEnd};
  grid-row-start: ${({ $gridRowStart }) => $gridRowStart};
`;

const overrideStyle = css<StyleProps>`
  background-color: ${({ overrideBackgroundColor }) =>
    overrideBackgroundColor && overrideBackgroundColor};
  border-color: ${({ overrideBorderColor }) => overrideBorderColor && overrideBorderColor};
`;
const mediaQueryStyle = css<StyleProps>`
  /* mobile */
  @media (max-width: 767px) {
    grid-row-end: span 1;
  }
`;

export const S_BlockSheet = styled.div<StyleProps>`
  background-color: ${({ theme, $backgroundColorKey }) =>
    $backgroundColorKey && theme.landing_color[$backgroundColorKey]};
  border-color: ${({ theme, $borderColorKey }) =>
    $borderColorKey && theme.landing_color[$borderColorKey]};
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  border-style: ${({ borderMode }) => (borderMode === 'use' ? 'solid' : 'none')};
  border-width: ${({ $borderWidth }) => `${$borderWidth}px`};
  box-sizing: border-box;
  cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : 'default')};
  overflow: hidden;
  padding: ${({ theme }) => theme.landing_spacing.spacing_g};
  position: relative;

  ${({ $boxShadow }) => $boxShadow !== 'none' && boxShadowStyle};

  ${gridStyle}
  ${overrideStyle}
  ${mediaQueryStyle}
`;

export default BlockSheet;
