import { PLANS } from '../types';

export const plan = {
  type: PLANS.ENTERPRISE,
  fee: {
    monthly: 'str_208',
    annual: 'str_209'
  },
  buttonText: 'str_218',
  color: '',
  description: 'str_211',
  checkList: ['str_212', 'str_213', 'str_214', 'str_215', 'str_217'],
  tags: [],
  caption: ''
};
