import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

import { SecondaryPAButton } from '@common/ui/components';

function Section9() {
  const { t } = useTranslation('translation');

  return (
    <S_ContentBox>
      <S_LeftSide>
        <S_ImageBox>
          <ImageView
            src="https://static.publ.site/landing_page/service/section9/img_device_papp_m_01.png"
            width="responsive"
          />
          <S_ImageWrapper1>
            <ImageView
              src="https://static.publ.site/landing_page/service/section9/img_device_papp_m_01.png"
              width="responsive"
            />
          </S_ImageWrapper1>
          <S_ImageWrapper2>
            <ImageView
              src="https://static.publ.site/landing_page/service/section9/img_device_papp_m_02.png"
              width="responsive"
            />
          </S_ImageWrapper2>
          <S_ImageWrapper3>
            <ImageView
              src="https://static.publ.site/landing_page/service/section9/img_device_papp_m_03.png"
              width="responsive"
            />
          </S_ImageWrapper3>
          <S_ImageWrapper4>
            <ImageView
              src="https://static.publ.site/landing_page/service/section9/img_device_papp_w_01.png"
              width="responsive"
            />
          </S_ImageWrapper4>
          <S_ImageWrapper5>
            <ImageView
              src="https://static.publ.site/landing_page/service/section9/img_device_papp_w_02.png"
              width="responsive"
            />
          </S_ImageWrapper5>
          <S_ImageWrapper6>
            <ImageView
              src="https://static.publ.site/landing_page/service/section9/img_device_papp_w_03.png"
              width="responsive"
            />
          </S_ImageWrapper6>
        </S_ImageBox>
      </S_LeftSide>
      <S_RightSide>
        <S_TextBox>
          <S_Title>{t('str_27')}</S_Title>
          <S_Desc>{t('str_28')}</S_Desc>
        </S_TextBox>
        <SecondaryPAButton>{t('str_29')}</SecondaryPAButton>
      </S_RightSide>
    </S_ContentBox>
  );
}

const S_ContentBox = styled.div`
  align-items: start;
  display: grid;
  gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-template-columns: repeat(12, 1fr);

  /* mobile */
  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const S_LeftSide = styled.div`
  align-items: center;
  display: flex;
  grid-column-end: span 6;
  justify-content: center;
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};
  position: relative;
  width: 100%;

  /* mobile */
  @media (max-width: 767px) {
    grid-column-end: span 4;
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_g};
    order: 2;
  }
`;

export const opacityChange = keyframes`
  from, to {
    opacity: 1;
  }

  15% {
    opacity: 1;
  }

  17% {
    opacity: 0;
  }
  
  32% {
    opacity: 0;
  }

  34% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }

  51% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  68% {
    opacity: 0;
  }
  
  83% {
    opacity: 0;
  }

  85% {
    opacity: 0;
  }

  98% {
    opacity: 0;
  }
`;

export const ImageAni1 = css`
  animation: ${opacityChange} 16s 0s infinite ease-in-out;
`;

export const ImageAni2 = css`
  animation: ${opacityChange} 16s 2.6s infinite ease-in-out;
`;

export const ImageAni3 = css`
  animation: ${opacityChange} 16s 5.3s infinite ease-in-out;
`;

export const ImageAni4 = css`
  animation: ${opacityChange} 16s 8s infinite ease-in-out;
`;

export const ImageAni5 = css`
  animation: ${opacityChange} 16s 10.6s infinite ease-in-out;
`;

export const ImageAni6 = css`
  animation: ${opacityChange} 16s 13.3s infinite ease-in-out;
`;

export const ImageWrapperStyle = css`
  height: 100%;
  left: -10%;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;

  /* mobile */
  @media (max-width: 767px) {
    left: 0;
  }
`;

const S_ImageBox = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  & > div:first-of-type {
    opacity: 0;
  }
`;

const S_ImageWrapper1 = styled.div`
  ${ImageWrapperStyle}
  ${ImageAni1}
`;

const S_ImageWrapper2 = styled.div`
  ${ImageWrapperStyle}
  ${ImageAni2}
`;

const S_ImageWrapper3 = styled.div`
  ${ImageWrapperStyle}
  ${ImageAni3}
`;

const S_ImageWrapper4 = styled.div`
  ${ImageWrapperStyle}
  ${ImageAni4}
`;

const S_ImageWrapper5 = styled.div`
  ${ImageWrapperStyle}
  ${ImageAni5}
`;

const S_ImageWrapper6 = styled.div`
  ${ImageWrapperStyle}
  ${ImageAni6}
`;

const S_RightSide = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column-end: span 6;
  grid-column-start: 8;
  justify-content: center;

  /* mobile */
  @media (max-width: 767px) {
    grid-column-end: span 4;
    grid-column-start: auto;
    order: 1;
  }
`;

const S_TextBox = styled.div`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  text-align: left;
`;

const S_Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }
`;

const S_Desc = styled.p`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
`;

export default Section9;
