import styled, { css } from 'styled-components';

import { Footer } from '@common/ui/components';

import { Section1, Section2, Section3, Section4 } from './sections';

function Partners() {
  return (
    <S_Main>
      <Section1Background>
        <S_Section1Wrapper>
          <Section1 />
          <Section2 />
        </S_Section1Wrapper>
      </Section1Background>
      <Section3Background>
        <S_Section3Wrapper>
          <Section3 />
        </S_Section3Wrapper>
      </Section3Background>
      <Section4Background>
        <S_Section4Wrapper>
          <Section4 />
        </S_Section4Wrapper>
      </Section4Background>
      <Footer />
    </S_Main>
  );
}

const backgroundStyle = css`
  align-items: center;
  background-color: ${({ theme }) => theme.landing_color.white};
  display: flex;
  flex-direction: column;
`;

const wrapperStyle = css`
  max-width: 1440px;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_k};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};
  width: 100%;

  /* mobile */
  @media (max-width: 767px) {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_i};
  }
`;

const S_Main = styled.div`
  background-color: ${({ theme }) => theme.landing_color.white};
`;

const Section1Background = styled.div`
  ${backgroundStyle}

  background: #181818;
  background: linear-gradient(135deg, #181818 0%, #565656 100%);
`;

const S_Section1Wrapper = styled.div`
  ${wrapperStyle}

  padding-top: ${({ theme }) => theme.landing_spacing.spacing_j};

  /* mobile */
  @media (max-width: 767px) {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  }
`;

const Section3Background = styled.div`
  ${backgroundStyle}
`;

const S_Section3Wrapper = styled.div`
  ${wrapperStyle}
`;

const Section4Background = styled.div`
  ${backgroundStyle}

  background: #53f8c3;
  background: linear-gradient(135deg, #53f8c3 0%, #21fffb 100%);
`;

const S_Section4Wrapper = styled.div`
  ${wrapperStyle}
`;

export default Partners;
