import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function A00001() {
  return (
    <S_Wrapper>
      <S_Desktop>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_main_01.png"
            width={590}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_main_02.png"
            width={230}
          />
        </S_ImageWrapper2>
        <S_ImageWrapper3>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_sub_01.png"
            width={153}
          />
        </S_ImageWrapper3>
        <S_ImageWrapper4>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_sub_02.png"
            width={147}
          />
        </S_ImageWrapper4>
        <S_ImageWrapper5>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_sub_03.png"
            width={148}
          />
        </S_ImageWrapper5>
      </S_Desktop>
      <S_Mobile>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_main_01.png"
            width={445}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_main_02.png"
            width={152}
          />
        </S_ImageWrapper2>
        <S_ImageWrapper3>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_sub_01.png"
            width={120}
          />
        </S_ImageWrapper3>
        <S_ImageWrapper4>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_sub_02.png"
            width={120}
          />
        </S_ImageWrapper4>
        <S_ImageWrapper5>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_timeline_sub_03.png"
            width={120}
          />
        </S_ImageWrapper5>
      </S_Mobile>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_Desktop = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

const S_Mobile = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    display: none;
  }
`;

const S_ImageWrapper1 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: -120px;
    left: -40px;
    position: absolute;
    transition: all 0.35s ease-in-out;

    ${S_Wrapper}:hover & {
      transform: translate(20px, -20px);
    }
  }

  @media ${device.mobile} {
    bottom: -40px;
    left: -120px;
    position: absolute;
  }
`;

const S_ImageWrapper2 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: -10px;
    position: absolute;
    right: 120px;
    transition: all 0.35s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: translate(-10px, -10px);
    }
  }

  @media ${device.mobile} {
    bottom: 160px;
    position: absolute;
    left: 180px;
  }
`;

const S_ImageWrapper3 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 64px;
    opacity: 0;
    position: absolute;
    right: 88px;
    transform: scale(90%);
    transition: all 0.3s 0.05s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: scale(100%);
    }
  }

  @media ${device.mobile} {
    bottom: 205px;
    position: absolute;
    left: 96px;
  }
`;

const S_ImageWrapper4 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 136px;
    opacity: 0;
    position: absolute;
    right: 32px;
    transform: scale(90%);
    transition: all 0.2s 0.15s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: scale(100%);
    }
  }

  @media ${device.mobile} {
    bottom: 240px;
    position: absolute;
    left: 150px;
  }
`;

const S_ImageWrapper5 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 170px;
    opacity: 0;
    position: absolute;
    right: 82px;
    transform: scale(90%);
    transition: all 0.15s 0.2s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: scale(100%);
    }
  }

  @media ${device.mobile} {
    bottom: 245px;
    position: absolute;
    left: 115px;
  }
`;

export default A00001;
