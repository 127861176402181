import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function B00001() {
  return (
    <S_Wrapper>
      <S_Desktop>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_article_main_01.png"
            width={554}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_article_sub_01.png"
            width={554}
          />
        </S_ImageWrapper2>
      </S_Desktop>
      <S_Mobile>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_article_main_01.png"
            width={480}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_article_sub_01.png"
            width={350}
          />
        </S_ImageWrapper2>
      </S_Mobile>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_Desktop = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

const S_Mobile = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    display: none;
  }
`;

const S_ImageWrapper1 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: -114px;
    left: -32px;
    position: absolute;
    transition: all 0.35s ease-in-out;

    ${S_Wrapper}:hover & {
      transform: translateX(30px);
    }
  }

  @media ${device.mobile} {
    bottom: -50px;
    left: -130px;
    position: absolute;
  }
`;

const S_ImageWrapper2 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: -162px;
    left: 200px;
    opacity: 0;
    position: absolute;
    transition: all 0.35s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: translateY(-20px);
    }
  }

  @media ${device.mobile} {
    bottom: 40px;
    left: 100px;
    position: absolute;
  }
`;

export default B00001;
