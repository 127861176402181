import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import {
  businessPlanSectionPath,
  enterprisePlanSectionPath,
  faqSectionPath,
  planFeatureTableSectionPath,
  plansSectionPath
} from '@common/routers';

import { BusinessPlansSection } from './BusinessPlansSection';
import { EnterprisePlanSection } from './EnterprisePlanSection';
import { FAQSection } from './FAQSection';
import { PlanFeatureTableSection } from './PlanFeatureTableSection';
import { PlansSection } from './PlansSection';
import { PaymentMethods, PAYMENT_METHODS } from './types';

function PricingSections() {
  const [isMobileView, setIsMobileView] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(PAYMENT_METHODS.ANNUAL);

  const FEATURE_TABLE_ID = 'feature-table';

  useEffect(() => {
    const handleWindowResize = () => {
      const MAX_PAGE_WIDTH = 1024;

      if (window.innerWidth < MAX_PAGE_WIDTH) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked
      ? setPaymentMethod(PAYMENT_METHODS.ANNUAL)
      : setPaymentMethod(PAYMENT_METHODS.MONTHLY);
  };

  const handleAnchorClick = () => {
    const featureTableSection = document.querySelector(`#${FEATURE_TABLE_ID}`);

    if (featureTableSection) {
      const positionY = featureTableSection.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({ top: positionY });
    }
  };

  return (
    <>
      <Switch>
        <PricingPageLayout>
          <Main>
            <Route exact path={plansSectionPath}>
              <PlansSectionBox>
                <CenteredLayout>
                  <PlansSection
                    paymentMethod={paymentMethod}
                    onChangeSwitch={handleSwitchChange}
                    onClickAnchor={handleAnchorClick}
                  />
                </CenteredLayout>
              </PlansSectionBox>
            </Route>
            <Route exact path={businessPlanSectionPath}>
              <SectionBox>
                <CenteredLayout>
                  <BusinessPlansSection paymentMethod={paymentMethod} />
                </CenteredLayout>
              </SectionBox>
            </Route>
            <Route exact path={enterprisePlanSectionPath}>
              <EnterprisePlanSectionBox>
                <CenteredLayout>
                  <EnterprisePlanSection />
                </CenteredLayout>
              </EnterprisePlanSectionBox>
            </Route>
            <Route exact path={planFeatureTableSectionPath}>
              <SectionBox>
                <CenteredLayout>
                  <PlanFeatureTableSection isMobileView={isMobileView} />
                </CenteredLayout>
              </SectionBox>
            </Route>
            <Route exact path={faqSectionPath}>
              <SectionBox>
                <CenteredLayout>
                  <FAQSection />
                </CenteredLayout>
              </SectionBox>
            </Route>
          </Main>
        </PricingPageLayout>
      </Switch>
    </>
  );
}

const EnterprisePlanSectionBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const PricingPageLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const Main = styled.main`
  width: 100%;
`;

const PlansSectionBox = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SectionBox = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const CenteredLayout = styled.article`
  box-sizing: border-box;
  max-width: 1440px;
  width: 100%;
`;

export default PricingSections;
