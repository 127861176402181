import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon } from '@common/ui/components';
import { device } from '@common/ui/styles/media';

export type checkList = {
  checkListItem: string;
};

type CardPropTypes = {
  tagline: string;
  mainText: React.ReactNode;
  description?: string;
  caption: string;
  annotation?: string;
  chips?: React.ReactNode;
  button: React.ReactNode;
  checkList?: string[];
};

function Card({
  tagline,
  mainText,
  description,
  caption,
  chips,
  checkList,
  button,
  annotation
}: CardPropTypes) {
  const { t } = useTranslation('translation');

  return (
    <S_Wrapper>
      <CardWrapper>
        <TopContents>
          <Tagline>{tagline}</Tagline>
          <Caption>{caption}</Caption>
          {mainText}
          {chips && chips}
          <S_Line />
          {description && <Description>{description}</Description>}
          {checkList && (
            <S_CheckList>
              {checkList.map((data, index) => (
                <S_CheckListItem key={index}>
                  <S_Icon iconName="ic_check" overrideColor="#1EEBB4" size={24} /> {t(data)}
                </S_CheckListItem>
              ))}
            </S_CheckList>
          )}
        </TopContents>
        <ButtonWrapper>{button}</ButtonWrapper>
      </CardWrapper>
      {annotation && <S_Annotation>{annotation}</S_Annotation>}
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  position: relative;
`;

const CardWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.landing_color.white};
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_f};
  position: relative;
  transition: all 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 2px 34px 0 rgba(0, 100, 248, 0.2);
  }
`;

const TopContents = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const S_Line = styled.div`
  background-color: #e3e5e9;
  height: 1px;
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  margin-top: ${({ theme }) => theme.landing_spacing.spacing_f};
  width: 42px;
`;

const Tagline = styled.span`
  font-size: ${({ theme }) => theme.landing_font_size.xl4};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_b};
  text-align: center;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.landing_color.grey500};
  font-size: ${({ theme }) => theme.landing_font_size.base};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  text-align: center;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl};
    white-space: pre-wrap !important;
  }
`;

const Caption = styled.p`
  color: ${({ theme }) => theme.landing_color.grey500};
  font-size: ${({ theme }) => theme.landing_font_size.sm};
  font-weight: ${({ theme }) => theme.landing_font_weight.medium};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_d};
  text-align: center;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.lg};
  }
`;

const S_CheckList = styled.ul`
  align-self: flex-start;
  display: grid;
  grid-row-gap: ${({ theme }) => theme.landing_spacing.spacing_d};
  list-style: none;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  padding-left: 0;
  text-align: left;
`;

const S_CheckListItem = styled.li`
  align-items: center;
  color: ${({ theme }) => theme.landing_color.grey500};
  display: flex;
  font-size: ${({ theme }) => theme.landing_font_size.base};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  white-space: pre-wrap;
  word-break: keep-all;
  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl};
  }
`;

const S_Icon = styled(Icon)`
  margin-right: ${({ theme }) => theme.landing_spacing.spacing_b};
  min-width: fit-content;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_a};
`;

const ButtonWrapper = styled.div`
  width: 100%;
`;

const S_Annotation = styled.p`
  bottom: -28px;
  color: ${({ theme }) => theme.landing_color.grey500};
  display: flex;
  font-size: ${({ theme }) => theme.landing_font_size.xs};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  justify-content: center;
  position: absolute;
  width: 100%;
`;

export default Card;
