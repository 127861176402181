/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
import locale1 from './ko.json';
import locale2 from './en.json';
import locale3 from './ja.json';

const locale = {
  ko: locale1,
  en: locale2,
  ja: locale3
} as const;

export default locale;
