import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

import { Feature, features } from '../../features';
import ScrollSpy from '../ScrollSpy';

import { TableBodyRowItem } from './TableBodyRowItem';

function TableBody() {
  const { t } = useTranslation('translation');

  const featuresArray: Feature[] = Object.entries(features).map(([key, feature]: [string, any]) => {
    return { id: key, ...feature };
  });

  return (
    <TableBodyLayout scrollThrottle={200} useBoxMethod updateHistoryStack={false}>
      {featuresArray.map((feature) => {
        return (
          <FeatureSheet key={feature.id} id={feature.id}>
            {feature.table.map((row) => (
              <TableBodyRowItem
                key={row.name}
                title={t(row.name)}
                description={t(row.description)}
                tip={t(row.tip)}
                superscript={row.footnoteNumber || undefined}
                columns={[
                  typeof row.col1 === 'string' ? t(row.col1) : row.col1,
                  typeof row.col2 === 'string' ? t(row.col2) : row.col2,
                  typeof row.col3 === 'string' ? t(row.col3) : row.col3
                ]}
              />
            ))}
            {feature.footnotes.length > 0 && (
              <FootnoteList>
                {feature.footnotes.map((footnote, index) => (
                  <Footnote key={index}>{`${index + 1} ${t(footnote)}`}</Footnote>
                ))}
              </FootnoteList>
            )}
          </FeatureSheet>
        );
      })}
    </TableBodyLayout>
  );
}

const TableBodyLayout = styled(ScrollSpy)`
  overflow-y: auto;
`;

const FeatureSheet = styled.ul`
  background-color: ${({ theme }) => theme.landing_color.white};
  border-radius: ${({ theme }) => theme.landing_border_radius.xl3};
  list-style-type: none;
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_b};

  @media ${device.mobile} {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

const FootnoteList = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_g};
  text-align: end;
  width: 100%;

  @media ${device.mobile} {
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

const Footnote = styled.div`
  color: #c7c7c9;
  font-size: 0.5rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  white-space: pre-wrap;
  word-break: keep-all;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xs};
  }
`;

export default TableBody;
