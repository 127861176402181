import { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';

import { VideoPlayerProps } from '@common/types/videoPlayer';

function Player({ videoSrc, thumbnailSrc }: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  const defaultOptions: VideoJsPlayerOptions = {
    aspectRatio: '16:9',
    responsive: true,
    autoplay: true,
    controls: false,
    muted: true,
    loop: true,
    preload: 'metadata',
    html5: {
      vhs: {
        enableLowInitialPlaylist: false,
        limitRenditionByPlayerDimensions: false,
        useNetworkInformationApi: true,
        experimentalBufferBasedABR: false,
        cacheEncryptionKeys: true,
        overrideNative: true
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      nativeTextTracks: true
    }
  };

  const readyCallBack = () => {
    if (!playerRef.current) {
      return;
    }
    if (videoSrc) {
      playerRef.current.src({
        src: videoSrc
      });
    }

    if (thumbnailSrc) {
      playerRef.current?.poster(thumbnailSrc);
    }
  };

  /**
  @when : 최초 진입 시 || 비디오 요소 변경시
  @expected : videojs 클래스 생성
  @clear : -
 */
  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current || '', defaultOptions, readyCallBack);
    playerRef.current = vjsPlayer;
  }, [videoSrc, playerRef]);

  /**
  @when :  화면 이탈 시
  @expected : videojs 클래스 dispose
  @clear : -
*/
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        if (!playerRef.current.paused()) {
          playerRef.current.pause();
        }
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return videoRef;
}

export default Player;
