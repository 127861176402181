import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { features } from '../../features';

function TableNavigationSideBar() {
  const { t } = useTranslation('translation');

  const anchors = Object.entries(features).map(([key, value]) => ({ id: key, title: value.title }));

  const handleAnchorClick = (id: string) => {
    const target = window.document.getElementById(id);

    if (target) {
      const headerOffset = 90;
      const elementPosition = target.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <TableNavigationCol>
      <NavWrapper>
        <AnchorList>
          {anchors.map((anchor) => (
            <Anchor key={anchor.id} onClick={() => handleAnchorClick(anchor.id)}>
              <Text data-to-scrollspy-id={anchor.id}>{t(anchor.title)}</Text>
            </Anchor>
          ))}
        </AnchorList>
      </NavWrapper>
    </TableNavigationCol>
  );
}

const TableNavigationCol = styled.div`
  align-self: flex-start;
  grid-column: 1 col-start / span 3;
  position: sticky;
  top: 90px;
`;

const NavWrapper = styled.div`
  display: block;
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_e};
  position: sticky;
`;

const AnchorList = styled.nav`
  display: flex;
  flex-direction: column;
`;

const Anchor = styled.div`
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
`;

const Text = styled.div`
  color: ${({ theme }) => theme.landing_color.grey400};
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  word-break: keep-all;
`;

export default TableNavigationSideBar;
