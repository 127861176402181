import { ButtonTransition } from 'pds-dev-kit-web';
import styled, { css } from 'styled-components';

import { ColorTheme } from '@common/types';

type ColorType = keyof ColorTheme['landing_color'];

export type MainButtonProps = {
  textColorKey?: ColorType;
  overrideTextColor?: `#${string}`;
  backgroundColorKey?: ColorType;
  overrideBackgroundColor?: `#${string}`;
  size?: 'xlarge' | 'large';
  responsiveMode?: 'none' | 'use';
  href?: string;
  target?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  overrideCSS?: React.CSSProperties;
};

type StyleTypes = {
  isSelected?: boolean;
} & Pick<
  MainButtonProps,
  | 'textColorKey'
  | 'overrideTextColor'
  | 'backgroundColorKey'
  | 'overrideBackgroundColor'
  | 'size'
  | 'responsiveMode'
>;

function MainButton({
  textColorKey = 'white',
  overrideTextColor,
  backgroundColorKey = 'blue500',
  overrideBackgroundColor,
  size = 'xlarge',
  responsiveMode = 'none',
  href,
  target,
  children,
  onClick,
  onMouseDown,
  overrideCSS,
  ...props
}: MainButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onMouseDown) {
      onMouseDown(e);
    }
  };

  function ButtonVariation() {
    if (href) {
      return (
        <S_LinkButton
          rel="noopener noreferrer"
          href={href}
          target={target}
          responsiveMode={responsiveMode}
          textColorKey={textColorKey}
          overrideTextColor={overrideTextColor}
          backgroundColorKey={backgroundColorKey}
          overrideBackgroundColor={overrideBackgroundColor}
          size={size}
          style={overrideCSS}
          {...props}
        >
          {children}
        </S_LinkButton>
      );
    }
    return (
      <S_Button
        textColorKey={textColorKey}
        overrideTextColor={overrideTextColor}
        backgroundColorKey={backgroundColorKey}
        overrideBackgroundColor={overrideBackgroundColor}
        size={size}
        responsiveMode={responsiveMode}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        style={overrideCSS}
        {...props}
      >
        {children}
      </S_Button>
    );
  }

  return ButtonVariation();
}

const xlarge = css<StyleTypes>`
  border-radius: ${({ theme }) => theme.landing_border_radius.full};
  font-size: ${({ theme }) => theme.landing_font_size.xl};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  padding: ${({ theme }) =>
    `${theme.landing_spacing.spacing_d} ${theme.landing_spacing.spacing_f}`};
`;

const large = css<StyleTypes>`
  border-radius: ${({ theme }) => theme.landing_border_radius.full};
  font-size: ${({ theme }) => theme.landing_font_size.lg};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  padding: ${({ theme }) =>
    `${theme.landing_spacing.spacing_c} ${theme.landing_spacing.spacing_g}`};
`;

const overrideStyle = css<StyleTypes>`
  background-color: ${({ overrideBackgroundColor }) =>
    overrideBackgroundColor && overrideBackgroundColor};
  color: ${({ overrideTextColor }) => overrideTextColor && overrideTextColor};
`;

const responsiveStyle = css<StyleTypes>`
  max-width: 100%;
  width: 100%;
`;

const buttonStyle = css<StyleTypes>`
  align-items: center;
  background-color: ${({ theme, backgroundColorKey }) =>
    backgroundColorKey && theme.landing_color[backgroundColorKey]};
  border: 1px solid transparent;
  box-sizing: border-box;
  color: ${({ theme, textColorKey }) => textColorKey && theme.landing_color[textColorKey]};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-width: fit-content;
  position: relative;
  word-break: keep-all;

  &:focus {
    outline: none;
  }

  &::before {
    background-color: transparent;
    border-radius: inherit;
    content: '';
    height: 100%;
    border: inherit;
    padding: 0;
    position: absolute;
    width: 100%;
    ${ButtonTransition}
  }

  &:hover:enabled::before {
    background-color: ${({ theme }) =>
      `${theme.landing_color.white}${theme.landing_opacity.opacity20}`};
  }

  &:active:enabled::before {
    background-color: ${({ theme }) =>
      `${theme.landing_color.grey950}${theme.landing_opacity.opacity15}`};
  }

  ${({ size }) => size && { xlarge, large }[size]}
  ${({ responsiveMode }) => responsiveMode === 'use' && responsiveStyle};
`;

const S_LinkButton = styled.a<StyleTypes>`
  ${buttonStyle}

  ${overrideStyle}
`;

const S_Button = styled.button<StyleTypes>`
  ${buttonStyle}

  ${overrideStyle}
`;

export default MainButton;
