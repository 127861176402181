import type IconType from '../IconType';

function QuestionMark({ size, ...rest }: IconType) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        d="M16.108 10.312c-.21.417-.546.843-1.01 1.278l-1.094.984c-.312.288-.502.608-.648 1.315-.152.74-.637 1.178-1.414 1.177-.83 0-1.385-.53-1.401-1.264-.008-.34.012-.692.248-1.263.192-.462.506-.868.977-1.39.397-.44.827-.728 1.07-1.044a1.66 1.66 0 0 0 .367-1.044c0-.925-.38-1.286-1.11-1.38-.377-.048-.775.06-.996.34-.18.227-.2.347-.276.53-.216.523-.744.836-1.385.836H9.34c-1.132 0-1.737-.987-1.428-1.95.14-.436.309-.736.739-1.13.767-.7 1.892-1.154 3.314-1.154 1.415 0 2.512.445 3.29 1.076.778.632 1.166 1.53 1.166 2.694 0 .51-.105.972-.314 1.39m-2.915 8.576c-.33.314-.741.471-1.234.471s-.905-.157-1.234-.47c-.33-.314-.494-.716-.494-1.204 0-.489.164-.89.494-1.204.329-.313.74-.47 1.234-.47.493 0 .904.157 1.234.47.33.314.495.715.495 1.204 0 .488-.165.89-.495 1.203M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11c6.076 0 11-4.925 11-11S18.076 1 12 1"
        fill="#1E1E20"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default QuestionMark;
