import { CONSOLE_PUBL_BIZ_LINK, REQUEST_REDIRECT_TO_CONSOLE } from '@common/constants/hardcoded';

import MainButton, { MainButtonProps } from '../MainButton/MainButton';

function PAButton({ children, ...rest }: MainButtonProps) {
  const isInIFrame = window.location !== window.parent.location;

  const handleHrefButtonClick = () => {
    window.parent.postMessage({ message: REQUEST_REDIRECT_TO_CONSOLE }, '*');
  };

  return (
    <MainButton
      href={isInIFrame ? undefined : CONSOLE_PUBL_BIZ_LINK}
      onClick={handleHrefButtonClick}
      {...rest}
    >
      {children}
    </MainButton>
  );
}

export default PAButton;
