export const PAYMENT_METHODS = {
  MONTHLY: 'MONTHLY',
  ANNUAL: 'ANNUAL'
} as const;

export type PaymentMethods = keyof typeof PAYMENT_METHODS;

export const PLANS = {
  TRIAL: 'str_136',
  STARTER: 'str_142',
  ESSENTIAL: 'str_154',
  BUSINESS: 'str_166',
  AGENCY_ESSENTIAL: 'str_185',
  AGENCY_BUSINESS: 'str_192',
  ACADEMY: 'str_199',
  ENTERPRISE: 'str_207'
} as const;

export type PlansType = keyof typeof PLANS;
