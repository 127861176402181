import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';

const throttle = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let throttleCheck: NodeJS.Timeout | null;

  const throttled = (...args: Parameters<F>) => {
    if (!throttleCheck) {
      throttleCheck = setTimeout(() => {
        func(...args);
        throttleCheck = null;
      }, waitFor);
    }
  };

  return throttled as (...args: Parameters<F>) => ReturnType<F>;
};

interface ScrollSpyProps {
  children: ReactNode;
  navContainerRef?: MutableRefObject<HTMLDivElement | null>;
  parentScrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
  scrollThrottle?: number;
  onUpdateCallback?: (id: string) => void;
  offsetTop?: number;
  offsetBottom?: number;
  useDataAttribute?: string;
  activeClass?: string;
  useBoxMethod?: boolean;
  updateHistoryStack?: boolean;
}

const ScrollSpy = ({
  children,
  navContainerRef,
  parentScrollContainerRef,
  scrollThrottle = 300,
  onUpdateCallback,
  offsetTop = 0,
  offsetBottom = 0,
  useDataAttribute = 'to-scrollspy-id',
  activeClass = 'active-scroll-spy',
  useBoxMethod = true,
  updateHistoryStack = true
}: ScrollSpyProps) => {
  const [navContainerItems, setNavContainerItems] = useState<NodeListOf<Element> | undefined>(); // prettier-ignore

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const prevIdTracker = useRef('');

  useEffect(() => {
    if (parentScrollContainerRef) {
      parentScrollContainerRef.current?.addEventListener(
        'scroll',
        throttle(checkAndUpdateActiveScrollSpy, scrollThrottle)
      );
      parentScrollContainerRef.current?.addEventListener(
        'resize',
        throttle(checkAndUpdateActiveScrollSpy, scrollThrottle)
      );
    } else {
      window.addEventListener('scroll', throttle(checkAndUpdateActiveScrollSpy, scrollThrottle));
      window.addEventListener('resize', throttle(checkAndUpdateActiveScrollSpy, scrollThrottle));
    }
  });

  useEffect(() => {
    navContainerRef
      ? setNavContainerItems(
          navContainerRef.current?.querySelectorAll(`[data-${useDataAttribute}]`)
        )
      : setNavContainerItems(document.querySelectorAll(`[data-${useDataAttribute}]`));
  }, [navContainerRef]);

  useEffect(() => {
    checkAndUpdateActiveScrollSpy();
  }, [navContainerItems]);

  function isVisible(el: HTMLElement) {
    const rect = el.getBoundingClientRect();

    if (useBoxMethod) {
      const offsetHeight = parentScrollContainerRef?.current
        ? parentScrollContainerRef?.current.offsetHeight
        : window.innerHeight;

      const { top } = rect;
      const bottom = rect.top + offsetHeight;

      return offsetHeight < bottom + offsetBottom && offsetHeight > top - offsetTop;
    }

    const leniency = parentScrollContainerRef?.current
      ? parentScrollContainerRef?.current.offsetHeight * 0.5
      : window.innerHeight * 0.5;

    const height = parentScrollContainerRef?.current
      ? parentScrollContainerRef?.current.offsetHeight
      : window.innerHeight;

    return rect.top + leniency + offsetTop >= 0 && rect.bottom - leniency - offsetBottom <= height;
  }

  function checkAndUpdateActiveScrollSpy() {
    const scrollParentContainer = scrollContainerRef.current;

    if (!(scrollParentContainer && navContainerItems)) {
      return;
    }

    for (let i = 0; i < scrollParentContainer.children.length; i += 1) {
      const useChild = scrollParentContainer.children.item(i) as HTMLDivElement;

      const elementIsVisible = isVisible(useChild);

      if (elementIsVisible) {
        const changeHighlightedItemId = useChild.id;

        if (prevIdTracker.current === changeHighlightedItemId) return;

        navContainerItems.forEach((el) => {
          const attrId = el.getAttribute(`data-${useDataAttribute}`);

          if (el.classList.contains(activeClass)) {
            el.classList.remove(activeClass);
          }

          if (attrId === changeHighlightedItemId && !el.classList.contains(activeClass)) {
            el.classList.add(activeClass);

            if (onUpdateCallback) {
              onUpdateCallback(changeHighlightedItemId);
            }

            prevIdTracker.current = changeHighlightedItemId;

            if (updateHistoryStack) {
              window.history.replaceState({}, '', `#${changeHighlightedItemId}`);
            }
          }
        });

        const anchorElement = document.querySelector(`#${changeHighlightedItemId}_anchor`);

        if (anchorElement) {
          const navigationBox = document.querySelector('#navigation_box_mobile');

          const elementRect = anchorElement.getBoundingClientRect();
          const containerRect = navigationBox?.getBoundingClientRect();

          if (containerRect && navigationBox) {
            const scrollLeft = elementRect.left - containerRect.left + navigationBox.scrollLeft;

            navigationBox.scrollTo({ left: scrollLeft, behavior: 'smooth' });
          }
        }

        break;
      }
    }
  }

  return <div ref={scrollContainerRef}>{children}</div>;
};

export default ScrollSpy;
