import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-scroll-parallax';
import styled, { css } from 'styled-components';

import { device } from '@common/ui/styles/media';

type Props = {
  isSeparate?: boolean;
};

function Section6_1({ isSeparate }: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_Background isSeparate={isSeparate}>
      {!isSeparate && (
        <>
          <S_ParallaxText speed={-20} easing="easeInOut">
            {t('str_68')}
          </S_ParallaxText>
          <S_ParallaxTextMobile speed={0} easing="easeInOut">
            {t('str_68')}
          </S_ParallaxTextMobile>
        </>
      )}
      <S_Wrapper>
        <S_ContentBox>
          <S_Image1Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_intro_sub_01.png"
              width="responsive"
            />
          </S_Image1Wrapper>
          <S_Title>{t('str_73')}</S_Title>
          <S_DescriptionWrapper>
            <S_Description>{t('str_74')}</S_Description>
            <S_Description>{t('str_76')}</S_Description>
            <S_Description>{t('str_81')}</S_Description>
          </S_DescriptionWrapper>
          <S_Image2Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_intro_sub_02.png"
              width="responsive"
            />
          </S_Image2Wrapper>
          <S_Image3Wrapper>
            <ImageView
              src="https://static.publ.site/landing_page/service/section6/img_monetization_intro_sub_03.png"
              width="responsive"
            />
          </S_Image3Wrapper>
        </S_ContentBox>
      </S_Wrapper>
    </S_Background>
  );
}

const parallaxTextStyle = css`
  display: flex;
  font-size: 260px;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 30%;
  user-select: none;
  white-space: nowrap;

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl11};
    position: fixed;
    display: none;
    width: 100%;
  }
`;

const MobileBackgroundTextStyle = css`
  display: flex;
  font-size: 6.1rem;
  top: 250px;
`;

const S_ParallaxText = styled(Parallax)`
  ${parallaxTextStyle}

  color: #fff;
  opacity: 0.05;
`;

const S_ParallaxTextMobile = styled(S_ParallaxText)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}
  }
`;

const BackgroundColorStyle = css`
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, #000 32%, #3e3232 80%, #474747);
`;

const S_Background = styled.div<Props>`
  align-items: center;
  clip-path: inset(0 0 0 0);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  @media ${device.mobile} {
    height: unset;
    min-height: unset;
  }

  ${({ isSeparate }) => !isSeparate && BackgroundColorStyle};
`;

const S_Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 1440px;
  position: relative;
  width: 100%;
`;

const ImageWrapperStyle = css`
  position: absolute;
  transition: all 0.35s ease-in-out;
`;

const S_Image1Wrapper = styled.div`
  ${ImageWrapperStyle}

  bottom: 50px;
  left: -190px;
  transform: rotate(-15deg);
  width: 300px;

  @media ${device.tablet} {
    bottom: 30px;
    left: 200px;
  }

  @media ${device.mobile} {
    bottom: 50px;
    width: 240px;
    left: 80px;
  }
`;

const S_Image2Wrapper = styled.div`
  ${ImageWrapperStyle}

  right: 150px;
  top: -30px;
  transform: rotate(15deg);
  width: 160px;

  @media ${device.tablet} {
    left: 40px;
    transform: rotate(-15deg);
    top: -100px;
  }

  @media ${device.mobile} {
    left: 30px;
    transform: rotate(-15deg);
    width: 100px;
    top: 80px;
  }
`;

const S_Image3Wrapper = styled.div`
  ${ImageWrapperStyle}

  bottom: 30px;
  right: 0;
  transform: rotate(15deg);
  width: 150px;

  @media ${device.tablet} {
    top: -95px;
    width: 120px;
    right: 60px;
  }

  @media ${device.mobile} {
    right: 60px;
    width: 82px;
    top: 90px;
  }
`;

const S_ContentBox = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    &:hover ${S_Image1Wrapper} {
      transform: rotate(-20deg) translate(-60px, -10px);
    }
    &:hover ${S_Image2Wrapper} {
      transform: rotate(20deg) translate(40px, -40px);
    }
    &:hover ${S_Image3Wrapper} {
      transform: rotate(15deg) translate(160px, 0);
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 70%;

    &:hover ${S_Image1Wrapper} {
      transform: rotate(-20deg) translate(30px, 30px);
    }
    &:hover ${S_Image2Wrapper} {
      transform: rotate(-15deg) translate(-20px, -40px);
    }
    &:hover ${S_Image3Wrapper} {
      transform: rotate(15deg) translate(50px, -25px);
    }
  }

  @media ${device.mobile} {
    width: 80%;
  }
`;

const S_Title = styled.h2`
  color: ${({ theme }) => theme.landing_color.white};
  font-size: ${({ theme }) => theme.landing_font_size.xl10};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  position: relative;

  @media ${device.mobile} {
    margin-top: ${({ theme }) => theme.landing_spacing.spacing_k};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_k};
    font-size: ${({ theme }) => theme.landing_font_size.xl8};
    white-space: pre-wrap !important;
  }
`;

const S_DescriptionWrapper = styled.div`
  display: flex;
  position: relative;

  @media ${device.mobile} {
    display: none;
  }
`;

const S_Description = styled.p`
  color: #979797;
  font-size: ${({ theme }) => theme.landing_font_size.xl4};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

export default Section6_1;
