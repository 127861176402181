export const servicePath = '/service' as const;

export const serviceSectionsPath = '/service-sections' as const;

export const mainSectionPath = `${serviceSectionsPath}/main` as const;
export const trustedBySectionPath = `${serviceSectionsPath}/trusted-by` as const;
export const noCodeSectionPath = `${serviceSectionsPath}/no-code` as const;
export const monetizeSectionPath = `${serviceSectionsPath}/monetize` as const;
export const addOnSectionPath = `${serviceSectionsPath}/add-on` as const;
export const featuresSectionPath = `${serviceSectionsPath}/features` as const;
export const monetizeMethodSectionPath = `${serviceSectionsPath}/monetize-method` as const;
export const monetizeMethodTitleSectionPath = `${monetizeMethodSectionPath}/title` as const;
export const monetizeMethodContentsSectionPath = `${monetizeMethodSectionPath}/contents` as const;
export const monetizeMethodMembershipSectionPath =
  `${monetizeMethodSectionPath}/membership` as const;
export const monetizeMethodCommerceSectionPath = `${monetizeMethodSectionPath}/commerce` as const;
export const paymentSectionPath = `${serviceSectionsPath}/payment` as const;
export const examplesSectionPath = `${serviceSectionsPath}/examples` as const;
