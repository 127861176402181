import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

function Section2() {
  return (
    <S_ContentWrapper>
      <S_Card>
        <ImageView
          src="https://static.publ.site/landing_page/service/section2/img_intro_humanmade_01.png"
          width="responsive"
        />
      </S_Card>
    </S_ContentWrapper>
  );
}

const S_ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const S_Card = styled.div`
  position: absolute;
  top: -650px;
  width: 90%;

  /* mobile */
  @media (max-width: 767px) {
    top: -${({ theme }) => theme.landing_spacing.spacing_h};
  }
`;

export default Section2;
