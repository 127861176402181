import { ImageView } from 'pds-dev-kit-web';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-scroll-parallax';
import styled, { css } from 'styled-components';

import { device } from '@common/ui/styles/media';

type Props = {
  isSeparate?: boolean;
};

function Section6_4({ isSeparate }: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_Background isSeparate={isSeparate}>
      {!isSeparate && (
        <>
          <S_ParallaxText speed={-20} easing="easeInOut">
            {t('str_71')}
          </S_ParallaxText>
          <S_ParallaxTextMobile speed={0} easing="easeInOut">
            {t('str_71')}
          </S_ParallaxTextMobile>{' '}
        </>
      )}
      <S_Wrapper>
        <S_Caption>{t('str_73')}</S_Caption>
        <S_ContentBox>
          <S_Grid>
            <S_Image1Wrapper>
              <ImageView
                src="https://static.publ.site/landing_page/service/section6/img_monetization_commerce_main_01.png"
                width="responsive"
              />
            </S_Image1Wrapper>
            <S_Image2Wrapper>
              <ImageView
                src="https://static.publ.site/landing_page/service/section6/img_monetization_commerce_main_02.png"
                width="responsive"
              />
            </S_Image2Wrapper>
            <S_Image3Wrapper>
              <ImageView
                src="https://static.publ.site/landing_page/service/section6/img_monetization_commerce_main_03.png"
                width="responsive"
              />
            </S_Image3Wrapper>
            <S_Image4Wrapper>
              <ImageView
                src="https://static.publ.site/landing_page/service/section6/img_monetization_commerce_main_mobile.png"
                width="responsive"
              />
            </S_Image4Wrapper>
          </S_Grid>
        </S_ContentBox>
        <S_TextBox>
          <S_Title>{t('str_81')}</S_Title>
          <S_Description>{t('str_82')}</S_Description>
        </S_TextBox>
      </S_Wrapper>
    </S_Background>
  );
}

const parallaxTextStyle = css`
  display: flex;
  font-size: 260px;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 30%;
  user-select: none;
  white-space: nowrap;

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl11};
    position: fixed;
    display: none;
    width: 100%;
  }
`;

const MobileBackgroundTextStyle = css`
  display: flex;
  font-size: 6.1rem;
  top: 250px;
`;

const S_ParallaxText = styled(Parallax)`
  ${parallaxTextStyle}

  color: #000;
  opacity: 0.03;
`;

const S_ParallaxTextMobile = styled(S_ParallaxText)`
  display: none;

  /* mobile */
  @media (max-width: 767px) {
    ${MobileBackgroundTextStyle}
  }
`;

const BackgroundColorStyle = css`
  background: #ffa406;
`;

const S_Background = styled.div<Props>`
  align-items: center;
  clip-path: inset(0 0 0 0);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  padding: ${({ theme }) => theme.landing_spacing.spacing_j};
  position: relative;

  @media ${device.tablet} {
    height: unset;
    min-height: unset;
  }

  @media ${device.mobile} {
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }

  ${({ isSeparate }) => !isSeparate && BackgroundColorStyle};
`;

const S_Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 1440px;
  position: relative;
  width: 100%;

  @media ${device.mobile} {
    max-width: 767px;
  }
`;

const S_ContentBox = styled.div`
  align-items: center;
  display: flex;
  height: 392px;
  left: calc(50% - 60% / 2);
  position: absolute;
  top: calc(50% - 392px / 2);
  width: 60%;

  @media ${device.tablet} {
    position: relative;
    width: 100%;
    left: unset;
    height: auto;
    margin-top: ${({ theme }) => theme.landing_spacing.spacing_h};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  }

  @media ${device.mobile} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const S_Grid = styled.div`
  align-items: center;
  display: grid;
  flex: none;
  gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-auto-rows: minmax(0, 1fr);
  grid-template-columns: repeat(3, 1fr);
  height: min-content;
  justify-content: center;
  justify-items: center;
  position: relative;
  width: 100%;

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: ${({ theme }) => theme.landing_spacing.spacing_k};
  }
`;

const ImageWrapperStyle = css`
  border-radius: 12px;
  box-shadow: 0 60px 70px 0 rgba(217, 138, 0, 0.8);
  overflow: hidden;
  transition: all 0.35s ease-in-out;

  @media ${device.mobile} {
    display: none;
  }
`;

const ImageWrapperHoverStyle = css`
  &:hover {
    transform: translateY(-60px);
  }
`;

const S_Image1Wrapper = styled.div`
  ${ImageWrapperStyle}
  ${ImageWrapperHoverStyle}
`;

const S_Image2Wrapper = styled.div`
  ${ImageWrapperStyle}
  ${ImageWrapperHoverStyle}
`;

const S_Image3Wrapper = styled.div`
  ${ImageWrapperStyle}
  ${ImageWrapperHoverStyle}
`;

const S_Image4Wrapper = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    display: none;
  }
`;

const S_Caption = styled.p`
  color: rgb(238, 150, 0);
  font-size: ${({ theme }) => theme.landing_font_size.xl8};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  @media ${device.mobile} {
    display: none;
  }
`;

const S_TextBox = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  position: relative;

  @media ${device.tablet} {
    color: ${({ theme }) => theme.landing_color.white};
    align-self: flex-start;
  }
  @media ${device.mobile} {
    max-width: 75%;
  }
`;

const S_Title = styled.h2`
  font-size: ${({ theme }) => theme.landing_font_size.xl10};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl8};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_d};
  }
`;

const S_Description = styled.p`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
`;

export default Section6_4;
