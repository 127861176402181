import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MainButton } from '@common/ui/components';
import { device } from '@common/ui/styles/media';

import { PaymentMethods, PAYMENT_METHODS, PLANS } from '../types';

import { Card } from './Card';
import { plans } from './plans';

type BusinessPlansPropTypes = {
  paymentMethod: PaymentMethods;
};

function BusinessPlansSection({ paymentMethod }: BusinessPlansPropTypes) {
  const { t } = useTranslation('translation');

  return (
    <BusinessPlansSectionLayout>
      <SectionTitle>{t('str_184')}</SectionTitle>
      <CardList>
        {plans.map((plan) => (
          <Card
            key={plan.type}
            tagline={t(plan.type)}
            mainText={
              paymentMethod === PAYMENT_METHODS.MONTHLY ? (
                <MainTextWrapper>
                  <MainText>{t(plan.fee.monthly)}</MainText>
                  {plan.type !== PLANS.ACADEMY && <MainSubText>{t('str_188')}</MainSubText>}
                </MainTextWrapper>
              ) : (
                <MainTextWrapper>
                  <MainText>{t(plan.fee.annual)}</MainText>
                  {plan.type !== PLANS.ACADEMY && <MainSubText>{t('str_188')}</MainSubText>}
                </MainTextWrapper>
              )
            }
            description={t(plan.description)}
            caption={t(plan.caption)}
            color={plan.color}
            button={
              <BottomContentsBox>
                <ButtonWrapper>
                  <MainButton
                    size="large"
                    responsiveMode="use"
                    backgroundColorKey="black"
                    href="mailto:contact@publ.biz"
                  >
                    {t(plan.buttonText)}
                  </MainButton>
                </ButtonWrapper>
              </BottomContentsBox>
            }
          />
        ))}
      </CardList>
    </BusinessPlansSectionLayout>
  );
}

const BusinessPlansSectionLayout = styled.div`
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_k};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};
  width: 100%;

  @media ${device.mobile} {
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_j};
  }
`;

const CardList = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-template-columns: repeat(3, 1fr);

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const SectionTitle = styled.div`
  box-sizing: border-box;
  font-size: 3.5rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
  word-break: keep-all;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl6};
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

const MainTextWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.landing_spacing.spacing_a};
`;

const MainText = styled.span`
  font-size: ${({ theme }) => theme.landing_font_size.xl7};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  white-space: nowrap;
  word-break: keep-all;
`;

const MainSubText = styled.span`
  font-size: ${({ theme }) => theme.landing_font_size.xl5};
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  white-space: nowrap;
  word-break: keep-all;
`;

const BottomContentsBox = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  width: 270px;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export default BusinessPlansSection;
