import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Marquee } from '@common/ui/components';

import { ImageSheet } from './components';
import { channelImages1 } from './data/channels1';
import { channelImages2 } from './data/channels2';

function shuffle<T>(arr: T[]): T[] {
  // eslint-disable-next-line no-plusplus
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
}

function Section8() {
  const { t } = useTranslation('translation');

  return (
    <S_ContentBox>
      <S_Title>{t('str_92')}</S_Title>
      <S_DesktopMarquee>
        <Marquee
          speed={50}
          pauseOnClick
          gradient={false}
          style={{ marginBottom: '32px', minWidth: '16000px' }}
        >
          {shuffle(channelImages1).map((data, index) => (
            <ImageSheet
              key={`${data.title}${index}`}
              titleText={data.title}
              descText={data.desc}
              imageSrc={data.imageSrc}
              href={data.href}
              imageMode={data.imageMode}
            />
          ))}
        </Marquee>
        <Marquee
          speed={50}
          pauseOnClick
          gradient={false}
          direction="right"
          style={{ minWidth: '16000px' }}
        >
          {shuffle(channelImages2).map((data, index) => (
            <ImageSheet
              key={`${data.title}${index}`}
              titleText={data.title}
              descText={data.desc}
              imageSrc={data.imageSrc}
              href={data.href}
              imageMode={data.imageMode}
            />
          ))}
        </Marquee>
      </S_DesktopMarquee>
      <S_MobileMarquee>
        <Marquee speed={50} gradient={false} style={{ marginBottom: '32px', minWidth: '8000px' }}>
          {shuffle(channelImages1).map((data, index) => (
            <ImageSheet
              key={`${data.title}${index}`}
              titleText={data.title}
              descText={data.desc}
              imageSrc={data.imageSrc}
              href={data.href}
              imageMode={data.imageMode}
            />
          ))}
        </Marquee>
        <Marquee speed={50} gradient={false} direction="right" style={{ minWidth: '8000px' }}>
          {shuffle(channelImages2).map((data, index) => (
            <ImageSheet
              key={`${data.title}${index}`}
              titleText={data.title}
              descText={data.desc}
              imageSrc={data.imageSrc}
              href={data.href}
              imageMode={data.imageMode}
            />
          ))}
        </Marquee>
      </S_MobileMarquee>
    </S_ContentBox>
  );
}

const S_ContentBox = styled.div`
  width: 100%;
`;

const S_Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_j};
  margin-left: ${({ theme }) => theme.landing_spacing.spacing_e};
  margin-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  text-align: center;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

const S_DesktopMarquee = styled.div`
  overflow: hidden;

  /* mobile */
  @media (max-width: 767px) {
    display: none;
  }
`;

const S_MobileMarquee = styled.div`
  overflow: hidden;

  /* tablet + desktop */
  @media (min-width: 768px) {
    display: none;
  }
`;

export default Section8;
