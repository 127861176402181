import styled from 'styled-components';

type GridProps = {
  children: React.ReactNode;
  rows?: number;
  columns?: number;
  rowGap?: number;
  columnGap?: number;
};

function Grid({ children, rows, columns, rowGap, columnGap }: GridProps) {
  return (
    <S_Grid
      x-pds-name="Grid"
      x-pds-element-type="component"
      x-pds-device-type="desktop"
      rows={rows}
      columns={columns}
      rowGap={rowGap}
      columnGap={columnGap}
    >
      {children}
    </S_Grid>
  );
}

const S_Grid = styled.div<GridProps>`
  display: grid;
  ${({ rows }) => rows && `grid-template-rows: repeat(${rows}, 1fr);`}
  ${({ columns }) => columns && `grid-template-columns: repeat(${columns}, 1fr);`}
  ${({ rowGap }) => rowGap && `row-gap: ${rowGap}px;`}
  ${({ columnGap }) => columnGap && `column-gap: ${columnGap}px;`}
`;

export default Grid;
