import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

function Section2() {
  return (
    <S_ContentWrapper>
      <S_Title>파트너와 함께, 더 쉽게 시작해보세요.</S_Title>
      <S_Desc>{`온라인 사업을 어떻게 시작해야할 지 막막하시다면 파트너와 함께 상담해보세요.\npubl 서비스와 파트너사의 역량을 활용하여 사업을 성공적으로 수행할 수 있게 돕겠습니다.`}</S_Desc>
      <S_ContentBox>
        <S_GridItem>
          <S_Card>
            <ImageView
              src="https://static.publ.site/landing_page/partners/section2/img_landing_publ_solution_partner.png"
              width="responsive"
              shapeType="rectangle"
            />
          </S_Card>
          <S_GridDesc>
            SOLUTION PARTNER는 사업자의 채널 구축 뿐만 아니라 콘텐츠 제작, 요금상품 설계, 마케팅
            전략 및 실행 등 사업 전반에 걸쳐 도움을 드리고 있습니다. 또한 파트너와 계약 시에는 더
            저렴한 pApp 요금제가 적용됩니다.
          </S_GridDesc>
        </S_GridItem>
        <S_GridItem>
          <S_Card>
            <ImageView
              src="https://static.publ.site/landing_page/partners/section2/img_landing_publ_technical_partner.png"
              width="responsive"
              shapeType="rectangle"
            />
          </S_Card>
          <S_GridDesc>
            TECHNICAL PARTNER는 publ의 시스템을 잘 이해하고 있는 기술 전문가 입니다. publ 서비스를
            바탕으로 사업자의 수요에 맞게 커스터마이징 웹 및 앱 개발을 수행할 수 있는 기술
            파트너입니다.
          </S_GridDesc>
        </S_GridItem>
      </S_ContentBox>
    </S_ContentWrapper>
  );
}

const S_ContentWrapper = styled.div`
  color: ${({ theme }) => theme.landing_color.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const S_Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_f};
  text-align: center;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl6};
  }
`;

const S_Desc = styled.p`
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  text-align: center;
`;

const S_GridDesc = styled.p`
  font-weight: ${({ theme }) => theme.landing_font_weight.medium};
  text-align: left;
  width: 40%;

  /* mobile */
  @media (max-width: 767px) {
    width: 90%;
  }
`;

const S_ContentBox = styled.div`
  column-gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: ${({ theme }) => theme.landing_spacing.spacing_f};
  width: 100%;

  /* mobile */
  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

const S_GridItem = styled.div`
  align-items: center;
  display: flex;
  grid-column-end: span 12;
  justify-content: center;

  /* mobile */
  @media (max-width: 767px) {
    grid-column-end: span 4;
    flex-direction: column;
  }
`;

const S_Card = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.landing_color.white};
  border-radius: ${({ theme }) => theme.landing_border_radius.xl4};
  display: flex;
  justify-content: center;
  margin-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding: ${({ theme }) => `${theme.landing_spacing.spacing_f}`};
  width: 380px;

  /* mobile */
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

export default Section2;
