import type IconType from '../IconType';

function Check({ size, color, ...rest }: IconType) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" {...rest}>
      <g id="그리드" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Pricing_v3" transform="translate(-99.000000, -3302.000000)">
          <g id="ic_landing_price_plan_check-copy-4" transform="translate(99.000000, 3302.000000)">
            <polyline
              id="Path-2"
              stroke={color}
              strokeWidth="3.5"
              points="5.63411583 12.1641805 12.430169 20.0476012 22.3658842 8.38093458"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Check;
