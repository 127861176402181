import { ImageView } from 'pds-dev-kit-web';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

function C00015() {
  return (
    <S_Wrapper>
      <S_Desktop>
        <S_ImageWrapper1>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_main_01.png"
            width={196}
          />
        </S_ImageWrapper1>
        <S_ImageWrapper2>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_main_02.png"
            width={196}
          />
        </S_ImageWrapper2>
        <S_ImageWrapper3>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_main_03.png"
            width={196}
          />
        </S_ImageWrapper3>
        <S_ImageWrapper4>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_main_04.png"
            width={196}
          />
        </S_ImageWrapper4>
        <S_ImageWrapper5>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_main_05.png"
            width={196}
          />
        </S_ImageWrapper5>
        <S_ImageWrapper6>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_sub_01.png"
            width={183}
          />
        </S_ImageWrapper6>
        <S_ImageWrapper7>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_sub_02.png"
            width={158}
          />
        </S_ImageWrapper7>
      </S_Desktop>
      <S_Mobile>
        <S_ImageWrapper4>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_main_04.png"
            width={156}
          />
        </S_ImageWrapper4>
        <S_ImageWrapper5>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_main_05.png"
            width={156}
          />
        </S_ImageWrapper5>
        <S_ImageWrapper6>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_sub_01.png"
            width={130}
          />
        </S_ImageWrapper6>
        <S_ImageWrapper7>
          <ImageView
            src="https://static.publ.site/landing_page/service/section5/img_landing_papp_shorts_sub_02.png"
            width={130}
          />
        </S_ImageWrapper7>
      </S_Mobile>
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::after {
    background: linear-gradient(
      36deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(255 255 255 / 10%) 80%,
      rgb(255 255 255 / 20%) 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease-in-out;
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const S_Desktop = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

const S_Mobile = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    display: none;
  }
`;

const S_ImageWrapper1 = styled.div`
  bottom: 200px;
  position: absolute;
  right: 200px;
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    opacity: 0;
  }
`;

const S_ImageWrapper2 = styled.div`
  bottom: 365px;
  position: absolute;
  right: 28px;
  transition: all 0.35s ease-in-out;

  ${S_Wrapper}:hover & {
    opacity: 0;
  }
`;

const S_ImageWrapper3 = styled.div`
  bottom: 0;
  position: absolute;
  right: 28px;
  transition: all 0.35s ease-in-out;
`;

const S_ImageWrapper4 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 200px;
    opacity: 0;
    position: absolute;
    right: 200px;
    transition: all 0.35s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
    }
  }

  @media ${device.mobile} {
    bottom: 0;
    left: 20px;
    position: absolute;
  }
`;

const S_ImageWrapper5 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 365px;
    opacity: 0;
    position: absolute;
    right: 28px;
    transition: all 0.35s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
    }
  }

  @media ${device.mobile} {
    bottom: -50px;
    left: 160px;
    position: absolute;
  }
`;

const S_ImageWrapper6 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 550px;
    left: 14px;
    opacity: 0;
    position: absolute;
    transition: all 0.3s 0.05s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: translateY(-10px);
    }
  }

  @media ${device.mobile} {
    bottom: 240px;
    left: 200px;
    position: absolute;
  }
`;

const S_ImageWrapper7 = styled.div`
  /* tablet + desktop */
  @media (min-width: 768px) {
    bottom: 515px;
    left: 92px;
    opacity: 0;
    position: absolute;
    transition: all 0.2s 0.15s ease-in-out;

    ${S_Wrapper}:hover & {
      opacity: 1;
      transform: translateY(-10px);
    }
  }

  @media ${device.mobile} {
    bottom: 200px;
    left: 220px;
    position: absolute;
  }
`;

export default C00015;
