import styled from 'styled-components';

import { PartnerCard } from './components';
import { partners } from './data/partners';

function Section3() {
  return (
    <S_ContentWrapper>
      <S_Title>공식 파트너</S_Title>
      <S_ContentBox>
        {partners.map((data, index) => (
          <S_GridItem key={`${data.title}${index}`}>
            <PartnerCard
              captionText={data.caption}
              titleText={data.title}
              descText={data.desc}
              email={data.email}
              href={data.href}
              logoSrc={data.logoSrc}
              logoWidth={data.logoWidth}
            />
          </S_GridItem>
        ))}
      </S_ContentBox>
    </S_ContentWrapper>
  );
}

const S_ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const S_Title = styled.h2`
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  text-align: center;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

const S_ContentBox = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.landing_spacing.spacing_e};
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  /* NOTE: logo와 겹쳐지지 않도록 기본 너비보다 넓게 설정 */

  /* mobile + tablet +custom */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const S_GridItem = styled.div<{ columnEnd?: number }>`
  align-items: stretch;
  display: flex;
  grid-column-end: span ${({ columnEnd }) => (columnEnd ? columnEnd : 6)};
  justify-content: center;

  /* mobile */
  @media (max-width: 767px) {
    grid-column-end: span 4;
  }
`;

export default Section3;
