import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import VideoPlayer from '@common/ui/components/VideoPlayer/VideoPlayer';

import { VIDEO_INFO } from './constants';

function Section1() {
  const { t } = useTranslation('translation');

  return (
    <S_ContentWrapper>
      <S_Title>
        {t('str_23')}
        <S_TypingText typingText="서비스">{t('str_24')}</S_TypingText>
        {t('str_25')}
      </S_Title>
      <S_Card>
        <VideoPlayer videoSrc={VIDEO_INFO.videoSrc} thumbnailSrc={VIDEO_INFO.thumbnailSrc} />
      </S_Card>
    </S_ContentWrapper>
  );
}

const S_ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const S_Title = styled.h1`
  color: ${({ theme }) => theme.landing_color.white};
  line-height: ${({ theme }) => theme.landing_line_height.normal};
  text-align: center;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl9};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl7};
    margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_g};
  }
`;

const S_Card = styled.div`
  border-radius: ${({ theme }) => theme.landing_border_radius.lg};
  bottom: -${({ theme }) => theme.landing_spacing.spacing_j};
  box-shadow: ${({ theme }) => theme.landing_box_shadow.elevation3};
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 10;

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    bottom: -${({ theme }) => theme.landing_spacing.spacing_i};
  }

  /* mobile */
  @media (max-width: 767px) {
    bottom: 0;
  }
`;

const S_TypingText = styled.strong<{ typingText: string }>`
  background-color: ${({ theme }) => theme.landing_color.white};
  color: #004cef;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  padding: ${({ theme }) => theme.landing_spacing.spacing_e};
  position: relative;
  white-space: nowrap;

  /* mobile */
  @media (max-width: 767px) {
    padding: ${({ theme }) => theme.landing_spacing.spacing_b};
  }
`;

export default Section1;
