import styled from 'styled-components';

import { Icon } from '@common/ui/components';

type TableBodyRowItemPropTypes = {
  title: string;
  description: string;
  tip: string;
  superscript?: number;
  columns: (string | boolean)[];
};

function TableBodyRowItem({
  title,
  description,
  tip,
  superscript,
  columns
}: TableBodyRowItemPropTypes) {
  const planTypes = ['Starter', 'Essential', 'Business'];

  return (
    <TableBodyRowItemBox>
      <FeatureTitleCol>
        <FeatureTitleBox>
          <FeatureTitle>
            <span>{title}</span>
            {superscript && <sup>{superscript}</sup>}
          </FeatureTitle>
        </FeatureTitleBox>
        {tip && <Description>{tip}</Description>}
        {description && <Description>{description}</Description>}
      </FeatureTitleCol>
      <FeatureContentsCol>
        {planTypes.map((plan, index) => (
          <FeatureContentBox key={index}>
            <ContentTagline>{plan}</ContentTagline>
            <Content>
              {typeof columns[index] === 'string' ? (
                columns[index]
              ) : (
                <Icon iconName="ic_circle_check" size={40} />
              )}
            </Content>
          </FeatureContentBox>
        ))}
      </FeatureContentsCol>
    </TableBodyRowItemBox>
  );
}

const TableBodyRowItemBox = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.landing_color.grey100};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_e};
`;

const FeatureTitleCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
`;

const FeatureContentsCol = styled.div`
  align-items: center;
  display: flex;
`;

const FeatureContentBox = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const FeatureTitleBox = styled.div`
  align-items: center;
  display: flex;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.landing_color.grey500};
  font-size: ${({ theme }) => theme.landing_font_size.lg};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  line-height: ${({ theme }) => theme.landing_line_height.snug};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_b};
  white-space: pre-wrap;
  word-break: keep-all;
`;

const FeatureTitle = styled.p`
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_b};
  white-space: pre-wrap;
  word-break: keep-all;

  span {
    font-size: ${({ theme }) => theme.landing_font_size.xl2};
    font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  }

  sup {
    font-size: ${({ theme }) => theme.landing_font_size.sm};
    font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  }
`;

const ContentTagline = styled.div`
  color: #c7c7c9;
  font-size: ${({ theme }) => theme.landing_font_size.base};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_a};
`;

const Content = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.landing_color.grey500};
  display: flex;
  font-size: ${({ theme }) => theme.landing_font_size.xl};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  justify-content: center;
  line-height: ${({ theme }) => theme.landing_line_height.snug};
  min-height: 32px;
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;
`;

export default TableBodyRowItem;
