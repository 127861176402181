import styled from 'styled-components';

import TableBody from './TableBody';
import TableHeader from './TableHeader';
import TableNavigationSideBar from './TableNavigationSIdeBar';

function GridTable() {
  return (
    <>
      <TableHeader />
      <GridTableContainer>
        <TableNavigationSideBar />
        <TableBody />
      </GridTableContainer>
    </>
  );
}

const GridTableContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);

  .active-scroll-spy {
    color: ${({ theme }) => theme.landing_color.grey900};
  }
`;

export default GridTable;
