import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { features } from '../../features';

function TableHeader() {
  const { t } = useTranslation('translation');

  const anchors = Object.entries(features).map(([key, value]) => ({ id: key, title: value.title }));

  const handleAnchorClick = (id: string) => {
    const target = window.document.getElementById(id);

    if (target) {
      const headerOffset = 90;
      const elementPosition = target.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }

    const anchorElement = document.querySelector(`#${id}_anchor`);

    if (anchorElement) {
      const navigationBox = document.querySelector('#navigation_box_mobile');

      const elementRect = anchorElement.getBoundingClientRect();
      const containerRect = navigationBox?.getBoundingClientRect();

      if (containerRect && navigationBox) {
        const scrollLeft = elementRect.left - containerRect.left + navigationBox.scrollLeft;

        navigationBox.scrollTo({ left: scrollLeft, behavior: 'smooth' });
      }
    }
  };

  return (
    <TableHeaderBox id="navigation_box_mobile">
      <AnchorList>
        {anchors.map((anchor) => (
          <Anchor
            key={anchor.id}
            id={`${anchor.id}_anchor`}
            onClick={() => handleAnchorClick(anchor.id)}
            data-to-scrollspy-id={anchor.id}
          >
            {t(anchor.title)}
          </Anchor>
        ))}
      </AnchorList>
    </TableHeaderBox>
  );
}

const TableHeaderBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.landing_color.grey30};
  display: flex;
  overflow-x: auto;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const AnchorList = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  width: fit-content;
`;

const Anchor = styled.div`
  color: ${({ theme }) => theme.landing_color.grey400};
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_e};
  white-space: nowrap;
  width: fit-content;

  &:not(:last-child) {
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

export default TableHeader;
