import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { device } from '@common/ui/styles/media';

import { FlexTable } from './FlexTable';
import { GridTable } from './GridTable';

type PlanFeatureTableSectionPropTypes = {
  isMobileView: boolean;
};

function PlanFeatureTableSection({ isMobileView }: PlanFeatureTableSectionPropTypes) {
  const { t } = useTranslation('translation');

  return (
    <PlanFeatureTableSectionLayout id="feature-table">
      <SectionTitle>{t('str_219')}</SectionTitle>
      {isMobileView ? <FlexTable /> : <GridTable />}
    </PlanFeatureTableSectionLayout>
  );
}

const PlanFeatureTableSectionLayout = styled.div`
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  padding-left: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-right: ${({ theme }) => theme.landing_spacing.spacing_g};
  padding-top: ${({ theme }) => theme.landing_spacing.spacing_k};
  width: 100%;

  @media ${device.mobile} {
    padding-left: ${({ theme }) => theme.landing_spacing.spacing_e};
    padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
    padding-top: ${({ theme }) => theme.landing_spacing.spacing_j};
    padding-right: ${({ theme }) => theme.landing_spacing.spacing_e};
  }
`;

const SectionTitle = styled.div`
  box-sizing: border-box;
  font-size: 3.5rem;
  font-weight: ${({ theme }) => theme.landing_font_weight.black};
  padding-bottom: 40px;
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
  word-break: keep-all;

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.landing_font_size.xl6};
  }
`;

export default PlanFeatureTableSection;
