import styled from 'styled-components';

function Section1() {
  return (
    <S_ContentWrapper>
      <S_Title>Partners</S_Title>
      <S_Desc>{`채널 구축부터 콘텐츠 제작, 마케팅, 사업운영까지.
publ 파트너가 사업자의 성공을 위해 같이 도와드립니다.`}</S_Desc>
      <S_Line />
    </S_ContentWrapper>
  );
}

const S_ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const S_Title = styled.h1`
  color: ${({ theme }) => theme.landing_color.white};
  padding-bottom: ${({ theme }) => theme.landing_spacing.spacing_e};

  /* tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl9};
  }

  /* mobile */
  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.landing_font_size.xl9};
  }
`;

const S_Desc = styled.p`
  color: ${({ theme }) => theme.landing_color.grey400};
  font-size: ${({ theme }) => theme.landing_font_size.xl2};
  font-weight: ${({ theme }) => theme.landing_font_weight.bold};
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_h};
`;

const S_Line = styled.div`
  background-color: #757575;
  height: 6px;
  margin-bottom: ${({ theme }) => theme.landing_spacing.spacing_i};
  width: 64px;
`;

export default Section1;
