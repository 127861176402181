import { ChannelImage } from './types';

export const channelImages1: ChannelImage[] = [
  {
    title: 'str_94',
    desc: 'str_95',
    href: 'https://www.leeseunggi.co.kr/',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/01_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_96',
    desc: 'str_97',
    href: '',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/02_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_98',
    desc: 'str_99',
    href: '',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/03_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_98',
    desc: 'str_99',
    href: '',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/04_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_100',
    desc: 'str_101',
    href: '',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/05_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_102',
    desc: 'str_103',
    href: 'https://kr.taewon.com/channels/L2NoYW5uZWxzLzE3Ng/C00002/home',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/06_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_104',
    desc: '',
    href: 'https://app.publr.co/channels/L2NoYW5uZWxzLzEw/pages/home',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/07_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_106',
    desc: 'str_107',
    href: 'https://kr.irris.world/',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/08_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_106',
    desc: 'str_107',
    href: 'https://kr.irris.world/',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/09_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_108',
    desc: '',
    href: 'https://app.publr.co/channels/L2NoYW5uZWxzLzU/pages/yoga',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/10_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_109',
    desc: '',
    href: 'https://app.publr.co/channels/L2NoYW5uZWxzLzE0OA/pages/sfc-home',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/11_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_109',
    desc: '',
    href: 'https://app.publr.co/channels/L2NoYW5uZWxzLzE0OA/pages/sfc-home',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/12_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_109',
    desc: '',
    href: 'https://app.publr.co/channels/L2NoYW5uZWxzLzE0OA/pages/sfc-home',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/13_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_110',
    desc: 'str_111',
    href: 'https://play.kookdaelab.com/',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/14_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_110',
    desc: 'str_111',
    href: 'https://play.kookdaelab.com/',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/15_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_112',
    desc: 'str_113',
    href: 'https://www.hocancepick.co.kr/channels/L2NoYW5uZWxzLzE5NQ/B00006/post/PostDetail',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/16_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_112',
    desc: 'str_113',
    href: 'https://www.hocancepick.co.kr/channels/L2NoYW5uZWxzLzE5NQ/B00006/post/PostDetail',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/17_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_114',
    desc: '',
    href: 'https://app.publr.co/channels/L2NoYW5uZWxzLzE0NA/B00006/post/PostDetail',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/18_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_114',
    desc: '',
    href: 'https://app.publr.co/channels/L2NoYW5uZWxzLzE0NA/B00006/post/PostDetail',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/19_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_114',
    desc: '',
    href: 'https://app.publr.co/channels/L2NoYW5uZWxzLzE0NA/B00006/post/PostDetail',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/20_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_115',
    desc: 'str_116',
    href: '',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/21_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_117',
    desc: 'str_118',
    href: 'https://community.witu.co.kr/',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/22_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_119',
    desc: 'str_120',
    href: '',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/23_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_121',
    desc: 'str_122',
    href: 'https://main.kyca.or.kr',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/24_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_123',
    desc: '',
    href: 'https://play.sming.com',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/25_s1_d.png',
    imageMode: 'desktop'
  },
  {
    title: 'str_124',
    desc: 'str_125',
    href: '',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/26_s1_m.png',
    imageMode: 'mobile'
  },
  {
    title: 'str_465',
    desc: 'str_466',
    href: 'https://online.johnleeschool.com/',
    imageSrc: 'https://static.publ.site/landing_page/service/section8/27_s1_d.png',
    imageMode: 'desktop'
  }
];
